export function shuffleArray(array, fixedIndices = []) {
    const newArray = array.slice(); // Create a new array with the same elements as the original array
    for (let i = newArray.length - 1; i > 0; i--) {
        if (fixedIndices.includes(i)) continue; // Skip shuffling this item if it's in the fixedIndices array

        // Find a random index that's not in the fixedIndices array
        let j;
        do {
            j = Math.floor(Math.random() * (i + 1));
        } while (fixedIndices.includes(j));

        // Swap elements i and j
        [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
}