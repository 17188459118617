const phrases = [
    'Just a sec, thinking',
    'Hmm, give me a second',
    'Interesting, let me ponder that',
    'Hmm... Let\'s see',
    'Just a moment while I think',
    'Let me just put my thinking cap on',
    'Hold on, turning my gears',
    'Ah, let me chew on that',
    'Let me sift through my thoughts',
    'Okay, processing your point',
    'Hang on, I\'m shuffling my thoughts',
    'Ruminating on your words',
    'Let me digest that for a moment',
    'Your input has got my wheels spinning'
];

let nextButtonPhrases = [
    "Great response. 👍 When you're ready, click 'Next' for our next topic.",
    "Thanks for your input. ✅ Please press 'Next' to proceed.",
    "Interesting. 🧐 When you're ready, hit 'Next' to explore another question.",
    "Good job! ✅ Feel free to click on 'Next' when you're ready to move on.",
    "That was insightful! 💡 Please press 'Next' for the following question.",
    "Thanks for sharing! 😊 Let's proceed when you hit the 'Next' button.",
    "I appreciate your response. 👏 Click 'Next' when you're ready for another question.",
    "Thanks! 👌 When you're ready, you can proceed by pressing 'Next'."
];

let submitAnswerPhrases = [
    "Great, thanks for your input. ✅ Feel free to click 'Submit Answer' to finalize your response.",
    "Thanks for your detailed answer. 😊 When you're ready, hit 'Submit Answer' to complete the form.",
    "Excellent, we've covered everything. 🎯 Please press 'Submit Answer' to finalize your input.",
    "Thanks for your cooperation. 👍 You can now click 'Submit Answer' to complete the process.",
    "You've been very helpful. 🌟 Click 'Submit Answer' when you're ready to submit your response.",
    "Perfect, that's all we needed. 👌 Please hit 'Submit Answer' to finalize your input."
];

let finishButtonPhrases = [
    "Excellent, we've covered everything. 🎯 Feel free to click 'Finish' to complete the form.",
    "Thanks for your detailed responses. 😊 When you're ready, hit 'Finish' to submit.",
    "Great job answering all the questions. ✅ Please press 'Finish' to finalize the form.",
    "Thanks for your cooperation. 👍 You can now click 'Finish' to complete the process.",
    "You've been very helpful. 🌟 Click 'Finish' when you're ready to submit the form.",
    "Perfect, that's all we needed. 👌 Please hit 'Finish' to complete your input."
];

export default {
    en: {
        phrases: phrases,
        nextButtonPhrases: nextButtonPhrases,
        submitAnswerPhrases: submitAnswerPhrases,
        finishButtonPhrases: finishButtonPhrases,
        fallbackMessage: "Thinking..."
    },
    es: { // Spanish
        phrases: [
            'Un segundo, estoy pensando.',
            'Hmm, dame un momento.',
            'Interesante, déjame reflexionar.',
            'Hmm... Veamos.',
            'Solo un momento mientras pienso.',
            'Déjame pensar un poco.',
            'Espera, estoy pensando.',
            'Ah, déjame considerar eso.',
            'Déjame ordenar mis pensamientos.',
            'Está bien, procesando tu idea.',
            'Espera, estoy organizando mis pensamientos.',
            'Reflexionando sobre tus palabras.',
            'Déjame digerir eso un momento.',
            'Tu comentario me ha hecho pensar.'
        ],
        nextButtonPhrases: [
            "Buena respuesta. 👍 Cuando estés listo, haz clic en 'Siguiente'.",
            "Gracias por tu aporte. ✅ Presiona 'Siguiente' para continuar.",
            "Interesante. 🧐 Cuando estés listo, pulsa 'Siguiente' para explorar otra pregunta.",
            "¡Buen trabajo! ✅ Haz clic en 'Siguiente' cuando estés listo.",
            "¡Eso fue perspicaz! 💡 Presiona 'Siguiente' para la siguiente pregunta.",
            "¡Gracias por compartir! 😊 Haz clic en 'Siguiente' para continuar.",
            "Aprecio tu respuesta. 👏 Haz clic en 'Siguiente' cuando estés listo.",
            "¡Gracias! 👌 Cuando estés listo, presiona 'Siguiente' para continuar."
        ],
        submitAnswerPhrases: [
            "Genial, gracias por tu aporte. ✅ Haz clic en 'Enviar respuesta' para finalizar.",
            "Gracias por tu respuesta detallada. 😊 Pulsa 'Enviar respuesta' para completar.",
            "Excelente, hemos cubierto todo. 🎯 Presiona 'Enviar respuesta' para finalizar.",
            "Gracias por tu cooperación. 👍 Haz clic en 'Enviar respuesta' para completar el proceso.",
            "Has sido muy útil. 🌟 Haz clic en 'Enviar respuesta' cuando estés listo.",
            "Perfecto, eso es todo lo que necesitábamos. 👌 Presiona 'Enviar respuesta' para finalizar."
        ],
        finishButtonPhrases: [
            "Excelente, hemos cubierto todo. 🎯 Haz clic en 'Finalizar' para completar.",
            "Gracias por tus respuestas. 😊 Pulsa 'Finalizar' para enviar.",
            "Buen trabajo respondiendo todas las preguntas. ✅ Presiona 'Finalizar' para completar.",
            "Gracias por tu cooperación. 👍 Haz clic en 'Finalizar' para terminar.",
            "Has sido muy útil. 🌟 Haz clic en 'Finalizar' cuando estés listo.",
            "Perfecto, eso es todo lo que necesitábamos. 👌 Pulsa 'Finalizar' para completar."
        ],
        fallbackMessage: "Pensando..."
    },   
    fr: { // French
        phrases: [
            'Un instant, je réfléchis.',
            'Hmm, donnez-moi une seconde.',
            'Intéressant, laissez-moi y réfléchir.',
            'Hmm... Voyons voir.',
            'Un moment, je réfléchis.',
            'Laissez-moi réfléchir un instant.',
            'Attendez, je pense à ça.',
            'Ah, laissez-moi réfléchir là-dessus.',
            'Laissez-moi organiser mes pensées.',
            "D'accord, je traite votre point.",
            'Attendez, je regroupe mes idées.',
            'Je réfléchis à vos paroles.',
            'Laissez-moi digérer ça un instant.',
            'Votre remarque me fait réfléchir.'
        ],
        nextButtonPhrases: [
            "Bonne réponse. 👍 Quand vous êtes prêt, cliquez sur 'Suivant'.",
            "Merci pour votre contribution. ✅ Cliquez sur 'Suivant' pour continuer.",
            "Intéressant. 🧐 Quand vous êtes prêt, cliquez sur 'Suivant' pour explorer une autre question.",
            "Bon travail ! ✅ Cliquez sur 'Suivant' quand vous êtes prêt à continuer.",
            "C'était perspicace ! 💡 Cliquez sur 'Suivant' pour la question suivante.",
            "Merci de partager ! 😊 Cliquez sur 'Suivant' pour continuer.",
            "J'apprécie votre réponse. 👏 Cliquez sur 'Suivant' quand vous êtes prêt pour une autre question.",
            "Merci ! 👌 Quand vous êtes prêt, cliquez sur 'Suivant'."
        ],
        submitAnswerPhrases: [
            "Super, merci pour votre contribution. ✅ Cliquez sur 'Soumettre la réponse' pour finaliser.",
            "Merci pour votre réponse détaillée. 😊 Cliquez sur 'Soumettre la réponse' pour terminer.",
            "Excellent, nous avons tout couvert. 🎯 Cliquez sur 'Soumettre la réponse' pour finaliser.",
            "Merci pour votre coopération. 👍 Cliquez sur 'Soumettre la réponse' pour terminer.",
            "Vous avez été très utile. 🌟 Cliquez sur 'Soumettre la réponse' quand vous êtes prêt.",
            "Parfait, c'est tout ce dont nous avions besoin. 👌 Cliquez sur 'Soumettre la réponse' pour finaliser."
        ],
        finishButtonPhrases: [
            "Excellent, nous avons tout couvert. 🎯 Cliquez sur 'Terminer' pour finaliser.",
            "Merci pour vos réponses détaillées. 😊 Cliquez sur 'Terminer' pour soumettre.",
            "Bravo pour avoir répondu à toutes les questions. ✅ Cliquez sur 'Terminer' pour finaliser.",
            "Merci pour votre coopération. 👍 Cliquez sur 'Terminer' pour terminer.",
            "Vous avez été très utile. 🌟 Cliquez sur 'Terminer' quand vous êtes prêt.",
            "Parfait, c'est tout ce dont nous avions besoin. 👌 Cliquez sur 'Terminer' pour finaliser."
        ],
        fallbackMessage: "Réflexion en cours..."
    },
    zh: { // Chinese
        phrases: [
            '稍等，我在思考。',
            '嗯，给我一点时间。',
            '有趣，让我想一想。',
            '嗯... 让我看看。',
            '请稍等，我在思考。',
            '让我思考一下。',
            '稍等，我在想。',
            '啊，让我想一下。',
            '让我梳理一下思绪。',
            '好的，我在处理你的观点。',
            '稍等，我在整理思绪。',
            '在回味你的话。',
            '让我消化一下。',
            '你的输入让我思考了。'
        ],
        nextButtonPhrases: [
            "很棒的回答。👍 准备好后，请点击“下一步”。",
            "感谢您的输入。✅ 请点击“下一步”继续。",
            "有趣。🧐 准备好后，点击“下一步”探索下一个问题。",
            "干得好！✅ 准备好后，请点击“下一步”。",
            "这真是深刻的见解！💡 请点击“下一步”进入下一个问题。",
            "谢谢您的分享！😊 准备好后，请点击“下一步”继续。",
            "感谢您的回答。👏 准备好后，请点击“下一步”。",
            "谢谢！👌 准备好后，请点击“下一步”继续。"
        ],
        submitAnswerPhrases: [
            "非常好，感谢您的输入。✅ 准备好后，请点击“提交答案”以完成。",
            "感谢您的详细回答。😊 准备好后，请点击“提交答案”以完成表单。",
            "很好，所有内容都已覆盖。🎯 请点击“提交答案”以完成您的输入。",
            "感谢您的合作。👍 现在您可以点击“提交答案”以完成整个过程。",
            "您非常有帮助。🌟 准备好后，请点击“提交答案”提交您的回答。",
            "完美，这就是我们需要的。👌 请点击“提交答案”以完成。"
        ],
        finishButtonPhrases: [
            "很好，所有内容都已覆盖。🎯 准备好后，请点击“完成”以完成表单。",
            "感谢您的详细回答。😊 准备好后，请点击“完成”以提交。",
            "出色地回答了所有问题。✅ 请点击“完成”以完成表单。",
            "感谢您的合作。👍 现在您可以点击“完成”以完成整个过程。",
            "您非常有帮助。🌟 准备好后，请点击“完成”提交表单。",
            "完美，这就是我们需要的。👌 请点击“完成”以完成。"
        ],
        fallbackMessage: "思考中..."
    },
    tl: { // Tagalog
        phrases: [
            'Saglit lang, nag-iisip ako.',
            'Hmm, bigyan mo ako ng sandali.',
            'Kawili-wili, pag-isipan ko muna ito.',
            'Hmm... Tignan natin.',
            'Saglit lang habang iniisip ko ito.',
            'Hayaan mo akong mag-isip sandali.',
            'Sandali lang, nag-iisip ako.',
            'Ah, pag-isipan ko muna ito.',
            'Hayaan mo akong salain ang mga naiisip ko.',
            'Okay, iniisip ko ang sinabi mo.',
            'Sandali lang, inaayos ko ang aking mga naiisip.',
            'Pinag-iisipan ko ang mga sinabi mo.',
            'Hayaan mo akong pagnilayan iyon.',
            'Ang input mo ay nagpapagana ng aking isipan.'
        ],
        nextButtonPhrases: [
            "Magandang sagot. 👍 Kapag handa ka na, i-click ang 'Next'.",
            "Salamat sa iyong input. ✅ I-click ang 'Next' upang magpatuloy.",
            "Kawili-wili. 🧐 Kapag handa ka na, pindutin ang 'Next'.",
            "Magaling! ✅ I-click ang 'Next' kapag handa ka na.",
            "Napakalalim! 💡 I-click ang 'Next' para sa susunod na tanong.",
            "Salamat sa pagbabahagi! 😊 I-click ang 'Next' upang magpatuloy.",
            "Pinahahalagahan ko ang iyong sagot. 👏 I-click ang 'Next' kapag handa ka na.",
            "Salamat! 👌 Kapag handa ka na, pindutin ang 'Next'."
        ],
        submitAnswerPhrases: [
            "Magaling, salamat sa iyong input. ✅ I-click ang 'Submit Answer' upang tapusin.",
            "Salamat sa iyong detalyadong sagot. 😊 Pindutin ang 'Submit Answer' upang kumpletuhin.",
            "Napakahusay, natakpan na natin ang lahat. 🎯 I-click ang 'Submit Answer' upang tapusin.",
            "Salamat sa iyong kooperasyon. 👍 I-click ang 'Submit Answer' upang tapusin ang proseso.",
            "Malaki ang naitulong mo. 🌟 I-click ang 'Submit Answer' kapag handa ka na.",
            "Perpekto, iyon lang ang kailangan namin. 👌 I-click ang 'Submit Answer' upang tapusin."
        ],
        finishButtonPhrases: [
            "Napakahusay, tapos na tayo. 🎯 I-click ang 'Finish' upang kumpletuhin.",
            "Salamat sa iyong mga sagot. 😊 I-click ang 'Finish' upang isumite.",
            "Magaling na trabaho! ✅ I-click ang 'Finish' upang kumpletuhin.",
            "Salamat sa iyong kooperasyon. 👍 I-click ang 'Finish' upang tapusin.",
            "Malaki ang naitulong mo. 🌟 I-click ang 'Finish' kapag handa ka na.",
            "Perpekto, iyon lang ang kailangan namin. 👌 I-click ang 'Finish' upang tapusin."
        ],
        fallbackMessage: "Nag-iisip..."
    },
    ar: { // Arabic
        phrases: [
            'لحظة فقط، أفكر.',
            'همم، امنحني ثانية.',
            'مثير، دعني أفكر فيه.',
            'همم... دعني أرى.',
            'لحظة فقط بينما أفكر.',
            'دعني أفكر قليلاً.',
            'انتظر، أفكر في الأمر.',
            'آه، دعني أراجع ذلك.',
            'دعني أرتب أفكاري.',
            'حسنًا، أفكر في وجهة نظرك.',
            'انتظر، أرتب أفكاري.',
            'أتأمل في كلماتك.',
            'دعني أستوعب ذلك للحظة.',
            'مدخلاتك جعلتني أفكر.'
        ],
        nextButtonPhrases: [
            "رد رائع. 👍 عندما تكون جاهزًا، انقر على 'التالي'.",
            "شكرًا على مساهمتك. ✅ اضغط على 'التالي' للمتابعة.",
            "مثير. 🧐 عندما تكون جاهزًا، اضغط على 'التالي'.",
            "عمل جيد! ✅ انقر على 'التالي' عندما تكون جاهزًا.",
            "كان ذلك مثيرًا للإعجاب! 💡 اضغط على 'التالي' للسؤال التالي.",
            "شكرًا لمشاركتك! 😊 اضغط على 'التالي' للاستمرار.",
            "أقدر ردك. 👏 انقر على 'التالي' عندما تكون جاهزًا.",
            "شكرًا! 👌 اضغط على 'التالي' للمتابعة."
        ],
        submitAnswerPhrases: [
            "رائع، شكرًا لك. ✅ انقر على 'إرسال الإجابة' لإكمال الرد.",
            "شكرًا على إجابتك. 😊 اضغط على 'إرسال الإجابة' لإكمال النموذج.",
            "ممتاز، لقد غطينا كل شيء. 🎯 اضغط على 'إرسال الإجابة' لإكمال المدخل.",
            "شكرًا على تعاونك. 👍 انقر على 'إرسال الإجابة' لإكمال العملية.",
            "لقد كنت مفيدًا جدًا. 🌟 اضغط على 'إرسال الإجابة' لإرسال ردك.",
            "مثالي، هذا كل ما نحتاجه. 👌 اضغط على 'إرسال الإجابة' لإكمال المدخل."
        ],
        finishButtonPhrases: [
            "ممتاز، لقد انتهينا. 🎯 انقر على 'إنهاء' لإكمال النموذج.",
            "شكرًا على إجاباتك. 😊 اضغط على 'إنهاء' لإرسال النموذج.",
            "عمل رائع! ✅ اضغط على 'إنهاء' لإكمال النموذج.",
            "شكرًا على تعاونك. 👍 انقر على 'إنهاء' لإكمال العملية.",
            "لقد كنت مفيدًا جدًا. 🌟 اضغط على 'إنهاء' لإرسال النموذج.",
            "مثالي، هذا كل ما نحتاجه. 👌 اضغط على 'إنهاء' لإكمال المدخل."
        ],
        fallbackMessage: "أفكر..."
    },
    vi: { // Vietnamese
        phrases: [
            'Chờ một chút, tôi đang suy nghĩ.',
            'Hmm, cho tôi một giây.',
            'Thú vị đấy, để tôi suy nghĩ về điều này.',
            'Hmm... Hãy xem nào.',
            'Chờ một lát trong khi tôi suy nghĩ.',
            'Để tôi suy nghĩ thêm chút.',
            'Chờ đã, tôi đang suy nghĩ.',
            'À, để tôi nghĩ kỹ về điều này.',
            'Để tôi sắp xếp lại suy nghĩ.',
            'Được rồi, tôi đang xử lý điều bạn nói.',
            'Chờ đã, tôi đang sắp xếp lại suy nghĩ.',
            'Tôi đang suy ngẫm về lời của bạn.',
            'Để tôi tiêu hóa điều này một chút.',
            'Ý kiến của bạn khiến tôi suy nghĩ.'
        ],
        nextButtonPhrases: [
            "Câu trả lời tuyệt vời. 👍 Khi sẵn sàng, hãy nhấn 'Tiếp theo'.",
            "Cảm ơn bạn đã đóng góp. ✅ Nhấn 'Tiếp theo' để tiếp tục.",
            "Thú vị đấy. 🧐 Khi sẵn sàng, nhấn 'Tiếp theo' để khám phá câu hỏi khác.",
            "Làm tốt lắm! ✅ Nhấn 'Tiếp theo' khi sẵn sàng.",
            "Sự thấu hiểu tuyệt vời! 💡 Nhấn 'Tiếp theo' để tiếp tục.",
            "Cảm ơn bạn đã chia sẻ! 😊 Nhấn 'Tiếp theo' để tiếp tục.",
            "Tôi đánh giá cao câu trả lời của bạn. 👏 Nhấn 'Tiếp theo' khi sẵn sàng.",
            "Cảm ơn! 👌 Nhấn 'Tiếp theo' để tiếp tục."
        ],
        submitAnswerPhrases: [
            "Tuyệt vời, cảm ơn bạn. ✅ Nhấn 'Gửi câu trả lời' để hoàn tất.",
            "Cảm ơn câu trả lời chi tiết. 😊 Nhấn 'Gửi câu trả lời' để hoàn thành.",
            "Xuất sắc, chúng ta đã bao quát hết mọi thứ. 🎯 Nhấn 'Gửi câu trả lời' để hoàn tất.",
            "Cảm ơn sự hợp tác của bạn. 👍 Nhấn 'Gửi câu trả lời' để kết thúc.",
            "Bạn đã rất hữu ích. 🌟 Nhấn 'Gửi câu trả lời' khi sẵn sàng.",
            "Hoàn hảo, đó là tất cả. 👌 Nhấn 'Gửi câu trả lời' để hoàn tất."
        ],
        finishButtonPhrases: [
            "Xuất sắc, chúng ta đã hoàn thành. 🎯 Nhấn 'Hoàn tất' để kết thúc.",
            "Cảm ơn câu trả lời chi tiết. 😊 Nhấn 'Hoàn tất' để gửi đi.",
            "Làm tốt lắm! ✅ Nhấn 'Hoàn tất' để hoàn thành.",
            "Cảm ơn sự hợp tác của bạn. 👍 Nhấn 'Hoàn tất' để kết thúc.",
            "Bạn đã rất hữu ích. 🌟 Nhấn 'Hoàn tất' khi sẵn sàng.",
            "Hoàn hảo, đó là tất cả. 👌 Nhấn 'Hoàn tất' để kết thúc."
        ],
        fallbackMessage: "Đang suy nghĩ..."
    },
    pt: { // Portuguese
        phrases: [
            'Um segundo, estou pensando.',
            'Hmm, me dê um momento.',
            'Interessante, deixe-me pensar nisso.',
            'Hmm... Vamos ver.',
            'Só um momento enquanto penso.',
            'Deixe-me refletir um pouco.',
            'Aguarde, estou pensando.',
            'Ah, deixe-me considerar isso.',
            'Deixe-me organizar meus pensamentos.',
            'Ok, processando sua ideia.',
            'Aguarde, estou ordenando meus pensamentos.',
            'Pensando sobre suas palavras.',
            'Deixe-me digerir isso por um momento.',
            'Seu comentário me fez pensar.'
        ],
        nextButtonPhrases: [
            "Ótima resposta. 👍 Quando estiver pronto, clique em 'Próximo'.",
            "Obrigado pela contribuição. ✅ Clique em 'Próximo' para continuar.",
            "Interessante. 🧐 Quando estiver pronto, clique em 'Próximo'.",
            "Bom trabalho! ✅ Clique em 'Próximo' quando estiver pronto.",
            "Isso foi perspicaz! 💡 Clique em 'Próximo' para a próxima pergunta.",
            "Obrigado por compartilhar! 😊 Clique em 'Próximo' para continuar.",
            "Agradeço sua resposta. 👏 Clique em 'Próximo' para outra pergunta.",
            "Obrigado! 👌 Quando estiver pronto, clique em 'Próximo'."
        ],
        submitAnswerPhrases: [
            "Ótimo, obrigado pela resposta. ✅ Clique em 'Enviar Resposta' para finalizar.",
            "Obrigado pela resposta detalhada. 😊 Clique em 'Enviar Resposta' para concluir.",
            "Excelente, cobrimos tudo. 🎯 Clique em 'Enviar Resposta' para finalizar.",
            "Obrigado pela cooperação. 👍 Clique em 'Enviar Resposta' para concluir.",
            "Você foi muito útil. 🌟 Clique em 'Enviar Resposta' para enviar.",
            "Perfeito, é tudo o que precisávamos. 👌 Clique em 'Enviar Resposta' para finalizar."
        ],
        finishButtonPhrases: [
            "Excelente, terminamos. 🎯 Clique em 'Finalizar' para concluir.",
            "Obrigado pelas respostas. 😊 Clique em 'Finalizar' para enviar.",
            "Bom trabalho! ✅ Clique em 'Finalizar' para concluir.",
            "Obrigado pela cooperação. 👍 Clique em 'Finalizar' para terminar.",
            "Você foi muito útil. 🌟 Clique em 'Finalizar' para enviar.",
            "Perfeito, é tudo o que precisávamos. 👌 Clique em 'Finalizar' para concluir."
        ],
        fallbackMessage: "Pensando..."
    },
    de: { // German
        phrases: [
            'Einen Moment, ich denke nach.',
            'Hmm, gib mir eine Sekunde.',
            'Interessant, lass mich darüber nachdenken.',
            'Hmm... Mal sehen.',
            'Einen Moment, ich überlege.',
            'Lass mich kurz nachdenken.',
            'Warte kurz, ich denke nach.',
            'Ah, lass mich das kurz bedenken.',
            'Ich sortiere meine Gedanken.',
            'Okay, ich verarbeite das.',
            'Warte, ich ordne meine Gedanken.',
            'Ich denke über deine Worte nach.',
            'Lass mich das kurz verarbeiten.',
            'Dein Input bringt mich zum Nachdenken.'
        ],
        nextButtonPhrases: [
            "Tolle Antwort. 👍 Wenn du bereit bist, klicke auf 'Weiter'.",
            "Danke für deinen Beitrag. ✅ Drücke 'Weiter', um fortzufahren.",
            "Interessant. 🧐 Wenn du bereit bist, klicke auf 'Weiter'.",
            "Gut gemacht! ✅ Klicke auf 'Weiter', um weiterzumachen.",
            "Das war aufschlussreich! 💡 Drücke 'Weiter' für die nächste Frage.",
            "Danke fürs Teilen! 😊 Klicke auf 'Weiter', um weiterzugehen.",
            "Ich schätze deine Antwort. 👏 Klicke auf 'Weiter', wenn du bereit bist.",
            "Danke! 👌 Wenn du bereit bist, drücke 'Weiter'."
        ],
        submitAnswerPhrases: [
            "Super, danke für deinen Beitrag. ✅ Klicke auf 'Antwort absenden'.",
            "Danke für deine Antwort. 😊 Drücke 'Antwort absenden', um abzuschließen.",
            "Ausgezeichnet, wir sind fertig. 🎯 Drücke 'Antwort absenden', um abzuschließen.",
            "Danke für deine Mitarbeit. 👍 Klicke auf 'Antwort absenden'.",
            "Sehr hilfreich. 🌟 Klicke auf 'Antwort absenden', um einzureichen.",
            "Perfekt, das ist alles. 👌 Drücke 'Antwort absenden', um abzuschließen."
        ],
        finishButtonPhrases: [
            "Ausgezeichnet, wir sind fertig. 🎯 Klicke auf 'Fertigstellen'.",
            "Danke für deine Antworten. 😊 Drücke 'Fertigstellen', um einzureichen.",
            "Gut gemacht! ✅ Drücke 'Fertigstellen', um abzuschließen.",
            "Danke für deine Mitarbeit. 👍 Klicke auf 'Fertigstellen'.",
            "Sehr hilfreich. 🌟 Drücke 'Fertigstellen', um abzuschließen.",
            "Perfekt, das ist alles. 👌 Klicke auf 'Fertigstellen'."
        ],
        fallbackMessage: "Ich denke nach..."
    },
    ko: { // Korean
        phrases: [
            '잠시만요, 생각 중이에요',
            '흠, 잠깐만요',
            '흥미로운데요, 잠시 생각해 볼게요',
            '음... 한번 볼까요',
            '잠시만요, 생각하고 있어요',
            '잠시만요, 생각 모드로 들어갈게요',
            '잠깐만요, 생각하고 있어요',
            '아, 그걸 좀 더 생각해 볼게요',
            '제 생각을 정리해 볼게요',
            '좋아요, 의견을 처리 중이에요',
            '잠시만요, 생각을 정리 중이에요',
            '말씀하신 내용을 곱씹어 보고 있어요',
            '그걸 잠시 소화해 볼게요',
            '의견이 제 생각을 자극했어요'
        ],
        nextButtonPhrases: [
            "좋은 답변이에요. 👍 준비되시면 '다음'을 클릭해 주세요.",
            "의견을 주셔서 감사합니다. ✅ 계속하시려면 '다음'을 눌러 주세요.",
            "흥미롭네요. 🧐 준비되시면 '다음'을 눌러 다른 질문으로 넘어가 주세요.",
            "잘하셨어요! ✅ 준비되시면 '다음'을 클릭해 주세요.",
            "통찰력 있는 답변이네요! 💡 '다음'을 눌러 다음 질문으로 넘어가 주세요.",
            "공유해 주셔서 감사합니다! 😊 '다음' 버튼을 누르면 계속 진행할 수 있어요.",
            "답변 감사드려요. 👏 준비되시면 '다음'을 클릭해 주세요.",
            "감사합니다! 👌 준비되시면 '다음'을 눌러 주세요."
        ],
        submitAnswerPhrases: [
            "좋아요, 의견 감사합니다. ✅ 준비되시면 '답변 제출'을 눌러 응답을 마무리해 주세요.",
            "자세한 답변 감사드려요. 😊 준비되시면 '답변 제출'을 눌러 양식을 완료해 주세요.",
            "완벽해요, 모든 내용을 다 다뤘어요. 🎯 '답변 제출'을 눌러 의견을 마무리해 주세요.",
            "협조해 주셔서 감사합니다. 👍 이제 '답변 제출'을 눌러 절차를 마무리해 주세요.",
            "도움이 많이 됐어요. 🌟 준비되시면 '답변 제출'을 눌러 응답을 제출해 주세요.",
            "완벽해요, 필요한 건 다 받았어요. 👌 '답변 제출'을 눌러 의견을 마무리해 주세요."
        ],
        finishButtonPhrases: [
            "완벽해요, 모든 내용을 다 다뤘어요. 🎯 양식을 마무리하려면 '완료'를 클릭해 주세요.",
            "자세한 답변 감사드려요. 😊 준비되시면 '완료'를 눌러 제출해 주세요.",
            "모든 질문에 잘 답해주셨어요. ✅ '완료'를 눌러 양식을 마무리해 주세요.",
            "협조해 주셔서 감사합니다. 👍 이제 '완료'를 눌러 절차를 마무리해 주세요.",
            "도움이 많이 됐어요. 🌟 준비되시면 '완료'를 눌러 양식을 제출해 주세요.",
            "완벽해요, 필요한 건 다 받았어요. 👌 '완료'를 눌러 의견을 마무리해 주세요."
        ],
        fallbackMessage: "생각 중이에요..."
    },
    ru: { // Russian
        phrases: [
            'Секундочку, размышляю',
            'Хм, дайте мне минутку',
            'Интересно, надо подумать',
            'Хм... Давайте посмотрим',
            'Минуточку, думаю...',
            'Дайте мне немного времени обдумать это',
            'Подождите, обрабатываю вашу мысль',
            'Минутку, думаю...',
            'Нужно немного времени...',
            'Спасибо, дайте мне минуту'
        ],
        nextButtonPhrases: [
            "Отличный ответ. 👍 Когда будете готовы, нажмите 'Далее'.",
            "Спасибо за ваш ответ. ✅ Нажмите 'Далее', чтобы продолжить.",
            "Интересно. 🧐 Когда будете готовы, нажмите 'Далее', чтобы перейти к следующему вопросу.",
            "Отличная работа! ✅ Нажмите 'Далее', когда будете готовы двигаться дальше.",
            "Очень содержательно! 💡 Пожалуйста, нажмите 'Далее' для следующего вопроса.",
            "Спасибо за ваше мнение! 😊 Нажмите 'Далее', чтобы продолжить.",
            "Отлично! Нажмите 'Далее', когда будете готовы к следующему вопросу.",
            "Спасибо! 👌 Когда будете готовы, нажмите 'Далее'."
        ],
        submitAnswerPhrases: [
            "Отлично, спасибо за ваш ответ. ✅ Нажмите 'Отправить', чтобы завершить.",
            "Спасибо за подробный ответ. 😊 Нажмите 'Отправить', чтобы завершить форму.",
            "Отлично, все вопросы охвачены. 🎯 Нажмите 'Отправить', чтобы завершить.",
            "Спасибо за сотрудничество. 👍 Нажмите 'Отправить', чтобы завершить процесс.",
            "Вы очень помогли. 🌟 Нажмите 'Отправить', чтобы отправить свой ответ.",
            "Прекрасно, это всё, что нужно. 👌 Нажмите 'Отправить', чтобы завершить."
        ],
        finishButtonPhrases: [
            "Отлично, все вопросы охвачены. 🎯 Нажмите 'Завершить', чтобы закончить.",
            "Спасибо за подробные ответы. 😊 Нажмите 'Завершить', чтобы отправить форму.",
            "Отличная работа с ответами на все вопросы. ✅ Нажмите 'Завершить', чтобы завершить заполнение формы.",
            "Спасибо за сотрудничество. 👍 Нажмите 'Завершить', чтобы завершить процесс.",
            "Вы очень помогли. 🌟 Нажмите 'Завершить', когда будете готовы отправить форму.",
            "Прекрасно, это всё, что нужно. 👌 Нажмите 'Завершить', чтобы закончить."
        ],
        fallbackMessage: "Думаю..."
    },
    hi: { // Hindi
        phrases: [
            'एक पल रुकें, सोच रहा हूँ।',
            'हम्म, मुझे एक सेकंड दें।',
            'दिलचस्प है, इसे सोचने दें।',
            'हम्म... देखते हैं।',
            'एक पल रुकें, सोच रहा हूँ।',
            'मुझे थोड़ा सोचने दें।',
            'रुकिए, मैं सोच रहा हूँ।',
            'अच्छा, इसे सोचने दें।',
            'मुझे अपने विचारों को व्यवस्थित करने दें।',
            'ठीक है, मैं आपकी बात पर विचार कर रहा हूँ।',
            'रुकिए, मैं अपने विचारों को व्यवस्थित कर रहा हूँ।',
            'मैं आपकी बातों पर विचार कर रहा हूँ।',
            'इसे समझने के लिए मुझे एक पल दें।',
            'आपकी बातों ने मुझे सोचने पर मजबूर कर दिया।'
        ],
        nextButtonPhrases: [
            "बहुत अच्छा जवाब। 👍 जब आप तैयार हों, 'अगला' पर क्लिक करें।",
            "आपके योगदान के लिए धन्यवाद। ✅ कृपया 'अगला' पर क्लिक करें।",
            "दिलचस्प। 🧐 जब आप तैयार हों, 'अगला' पर क्लिक करें।",
            "शानदार! ✅ जब आप तैयार हों, 'अगला' पर क्लिक करें।",
            "यह बहुत विचारशील था! 💡 कृपया 'अगला' पर क्लिक करें।",
            "साझा करने के लिए धन्यवाद! 😊 'अगला' पर क्लिक करें।",
            "आपके उत्तर के लिए धन्यवाद। 👏 'अगला' पर क्लिक करें।",
            "धन्यवाद! 👌 जब आप तैयार हों, 'अगला' पर क्लिक करें।"
        ],
        submitAnswerPhrases: [
            "बहुत अच्छा, आपके योगदान के लिए धन्यवाद। ✅ 'उत्तर जमा करें' पर क्लिक करें।",
            "आपके विस्तृत उत्तर के लिए धन्यवाद। 😊 'उत्तर जमा करें' पर क्लिक करें।",
            "उत्तम, सब कुछ कवर हो गया है। 🎯 'उत्तर जमा करें' पर क्लिक करें।",
            "आपके सहयोग के लिए धन्यवाद। 👍 'उत्तर जमा करें' पर क्लिक करें।",
            "आपने बहुत मदद की है। 🌟 'उत्तर जमा करें' पर क्लिक करें।",
            "संपूर्ण, यही हमें चाहिए था। 👌 'उत्तर जमा करें' पर क्लिक करें।"
        ],
        finishButtonPhrases: [
            "उत्तम, सब कुछ कवर हो गया है। 🎯 'समाप्त करें' पर क्लिक करें।",
            "आपके विस्तृत उत्तर के लिए धन्यवाद। 😊 'समाप्त करें' पर क्लिक करें।",
            "सभी सवालों के जवाब देने के लिए धन्यवाद। ✅ 'समाप्त करें' पर क्लिक करें।",
            "आपके सहयोग के लिए धन्यवाद। 👍 'समाप्त करें' पर क्लिक करें।",
            "आपने बहुत मदद की है। 🌟 'समाप्त करें' पर क्लिक करें।",
            "संपूर्ण, यही हमें चाहिए था। 👌 'समाप्त करें' पर क्लिक करें।"
        ],
        fallbackMessage: "सोच रहा हूँ..."
    },
    it: { // Italian
        phrases: [
            'Un attimo, sto pensando.',
            'Hmm, dammi un secondo.',
            'Interessante, lasciami riflettere.',
            'Hmm... Vediamo.',
            'Un momento mentre rifletto.',
            'Lasciami pensare un attimo.',
            'Aspetta, sto pensando.',
            'Ah, lasciami riflettere su questo.',
            'Lasciami ordinare i miei pensieri.',
            'Ok, sto elaborando il tuo punto.',
            'Aspetta, sto organizzando le idee.',
            'Sto riflettendo sulle tue parole.',
            'Lasciami assimilare questo un momento.',
            'Il tuo input mi ha fatto riflettere.'
        ],
        nextButtonPhrases: [
            "Ottima risposta. 👍 Quando sei pronto, clicca su 'Avanti'.",
            "Grazie per il tuo contributo. ✅ Clicca su 'Avanti' per continuare.",
            "Interessante. 🧐 Quando sei pronto, clicca su 'Avanti' per esplorare un'altra domanda.",
            "Ben fatto! ✅ Clicca su 'Avanti' quando sei pronto.",
            "È stato molto perspicace! 💡 Clicca su 'Avanti' per la prossima domanda.",
            "Grazie per aver condiviso! 😊 Clicca su 'Avanti' per proseguire.",
            "Apprezzo la tua risposta. 👏 Clicca su 'Avanti' quando sei pronto per un'altra domanda.",
            "Grazie! 👌 Quando sei pronto, clicca su 'Avanti'."
        ],
        submitAnswerPhrases: [
            "Ottimo, grazie per il tuo contributo. ✅ Clicca su 'Invia risposta' per finalizzare.",
            "Grazie per la tua risposta dettagliata. 😊 Clicca su 'Invia risposta' per completare.",
            "Perfetto, abbiamo coperto tutto. 🎯 Clicca su 'Invia risposta' per finalizzare.",
            "Grazie per la tua collaborazione. 👍 Clicca su 'Invia risposta' per completare.",
            "Sei stato di grande aiuto. 🌟 Clicca su 'Invia risposta' quando sei pronto.",
            "Perfetto, è tutto ciò di cui avevamo bisogno. 👌 Clicca su 'Invia risposta' per finalizzare."
        ],
        finishButtonPhrases: [
            "Perfetto, abbiamo coperto tutto. 🎯 Clicca su 'Fine' per completare.",
            "Grazie per le tue risposte dettagliate. 😊 Clicca su 'Fine' per inviare.",
            "Ottimo lavoro nel rispondere a tutte le domande. ✅ Clicca su 'Fine' per completare.",
            "Grazie per la tua collaborazione. 👍 Clicca su 'Fine' per completare.",
            "Sei stato di grande aiuto. 🌟 Clicca su 'Fine' quando sei pronto.",
            "Perfetto, è tutto ciò di cui avevamo bisogno. 👌 Clicca su 'Fine' per completare."
        ],
        fallbackMessage: "Sto pensando..."
    },
    ur: { // Urdu
        phrases: [
            'ایک لمحہ دیں، سوچ رہا ہوں۔',
            'ہمم، مجھے ایک سیکنڈ دیں۔',
            'دلچسپ، اسے سوچنے دیں۔',
            'ہمم... دیکھتے ہیں۔',
            'براہ کرم انتظار کریں، میں سوچ رہا ہوں۔',
            'مجھے تھوڑی دیر سوچنے دیں۔',
            'ذرا رُکیے، میں سوچ رہا ہوں۔',
            'آہ، اسے سوچنے دیں۔',
            'مجھے اپنے خیالات ترتیب دینے دیں۔',
            'ٹھیک ہے، میں آپ کے نقطہ نظر پر غور کر رہا ہوں۔',
            'رُکیے، میں اپنے خیالات کو ترتیب دے رہا ہوں۔',
            'میں آپ کی باتوں پر غور کر رہا ہوں۔',
            'اسے سمجھنے کے لیے مجھے ایک لمحہ دیں۔',
            'آپ کی رائے نے مجھے سوچنے پر مجبور کیا۔'
        ],
        nextButtonPhrases: [
            "بہترین جواب۔ 👍 جب آپ تیار ہوں، 'اگلا' پر کلک کریں۔",
            "آپ کے تعاون کا شکریہ۔ ✅ براہ کرم 'اگلا' پر کلک کریں۔",
            "دلچسپ۔ 🧐 جب آپ تیار ہوں، 'اگلا' پر کلک کریں۔",
            "شاندار! ✅ جب آپ تیار ہوں، 'اگلا' پر کلک کریں۔",
            "یہ بہت عمدہ بات تھی! 💡 براہ کرم 'اگلا' پر کلک کریں۔",
            "شکریہ! 😊 'اگلا' پر کلک کریں۔",
            "آپ کے جواب کا شکریہ۔ 👏 'اگلا' پر کلک کریں۔",
            "شکریہ! 👌 جب آپ تیار ہوں، 'اگلا' پر کلک کریں۔"
        ],
        submitAnswerPhrases: [
            "بہترین، آپ کے تعاون کا شکریہ۔ ✅ 'جواب جمع کروائیں' پر کلک کریں۔",
            "آپ کے تفصیلی جواب کا شکریہ۔ 😊 'جواب جمع کروائیں' پر کلک کریں۔",
            "شاندار، ہم نے سب کچھ کور کر لیا ہے۔ 🎯 'جواب جمع کروائیں' پر کلک کریں۔",
            "آپ کے تعاون کا شکریہ۔ 👍 'جواب جمع کروائیں' پر کلک کریں۔",
            "آپ نے بہت مدد کی ہے۔ 🌟 'جواب جمع کروائیں' پر کلک کریں۔",
            "بہترین، یہی ہمیں چاہیے تھا۔ 👌 'جواب جمع کروائیں' پر کلک کریں۔"
        ],
        finishButtonPhrases: [
            "شاندار، ہم نے سب کچھ کور کر لیا ہے۔ 🎯 'مکمل کریں' پر کلک کریں۔",
            "آپ کے تفصیلی جوابات کا شکریہ۔ 😊 'مکمل کریں' پر کلک کریں۔",
            "تمام سوالات کے جوابات دینے پر شکریہ۔ ✅ 'مکمل کریں' پر کلک کریں۔",
            "آپ کے تعاون کا شکریہ۔ 👍 'مکمل کریں' پر کلک کریں۔",
            "آپ نے بہت مدد کی ہے۔ 🌟 'مکمل کریں' پر کلک کریں۔",
            "بہترین، یہی ہمیں چاہیے تھا۔ 👌 'مکمل کریں' پر کلک کریں۔"
        ],
        fallbackMessage: "سوچ رہا ہوں..."
    },
    fa: { // Persian
        phrases: [
            'یک لحظه صبر کنید، در حال فکر کردن هستم.',
            'همم، یک لحظه به من وقت بدهید.',
            'جالب است، بگذارید در موردش فکر کنم.',
            'همم... ببینیم.',
            'لطفاً یک لحظه صبر کنید، در حال فکر کردن هستم.',
            'بگذارید کمی فکر کنم.',
            'یک لحظه صبر کنید، در حال فکر کردن هستم.',
            'آه، بگذارید کمی به آن فکر کنم.',
            'بگذارید افکارم را مرتب کنم.',
            'خُب، در حال بررسی نظرتان هستم.',
            'یک لحظه صبر کنید، دارم افکارم را مرتب می‌کنم.',
            'در حال فکر کردن به حرف‌های شما هستم.',
            'بگذارید یک لحظه این را درک کنم.',
            'نظر شما مرا به فکر انداخت.'
        ],
        nextButtonPhrases: [
            "پاسخ عالی بود. 👍 وقتی آماده بودید، روی 'بعدی' کلیک کنید.",
            "از مشارکت شما متشکرم. ✅ لطفاً روی 'بعدی' کلیک کنید.",
            "جالب است. 🧐 وقتی آماده بودید، روی 'بعدی' کلیک کنید.",
            "خوب کار کردید! ✅ وقتی آماده بودید، روی 'بعدی' کلیک کنید.",
            "این نظر بسیار عمیق بود! 💡 لطفاً روی 'بعدی' کلیک کنید.",
            "از به اشتراک‌گذاری شما متشکرم! 😊 روی 'بعدی' کلیک کنید.",
            "پاسختان را تحسین می‌کنم. 👏 وقتی آماده بودید، روی 'بعدی' کلیک کنید.",
            "ممنون! 👌 وقتی آماده بودید، روی 'بعدی' کلیک کنید."
        ],
        submitAnswerPhrases: [
            "عالی، از مشارکت شما متشکرم. ✅ روی 'ارسال پاسخ' کلیک کنید.",
            "از پاسخ دقیق شما متشکرم. 😊 روی 'ارسال پاسخ' کلیک کنید.",
            "عالی، همه چیز را پوشش دادیم. 🎯 روی 'ارسال پاسخ' کلیک کنید.",
            "از همکاری شما متشکرم. 👍 روی 'ارسال پاسخ' کلیک کنید.",
            "شما بسیار کمک‌کننده بودید. 🌟 روی 'ارسال پاسخ' کلیک کنید.",
            "عالی، همین چیزی بود که نیاز داشتیم. 👌 روی 'ارسال پاسخ' کلیک کنید."
        ],
        finishButtonPhrases: [
            "عالی، همه چیز را پوشش دادیم. 🎯 روی 'پایان' کلیک کنید.",
            "از پاسخ‌های دقیق شما متشکرم. 😊 روی 'پایان' کلیک کنید.",
            "خوب به همه سؤالات پاسخ دادید. ✅ روی 'پایان' کلیک کنید.",
            "از همکاری شما متشکرم. 👍 روی 'پایان' کلیک کنید.",
            "شما بسیار کمک‌کننده بودید. 🌟 روی 'پایان' کلیک کنید.",
            "عالی، همین چیزی بود که نیاز داشتیم. 👌 روی 'پایان' کلیک کنید."
        ],
        fallbackMessage: "در حال فکر کردن..."
    },
    pl: { // Polish
        phrases: [
            'Chwileczkę, zastanawiam się.',
            'Hmm, daj mi sekundę.',
            'Ciekawe, pozwól mi to przemyśleć.',
            'Hmm... Zobaczmy.',
            'Proszę chwilę poczekać, myślę nad tym.',
            'Pozwól mi chwilę pomyśleć.',
            'Poczekaj, myślę nad tym.',
            'Ach, pozwól mi to przemyśleć.',
            'Pozwól mi uporządkować myśli.',
            'Dobrze, analizuję to, co powiedziałeś.',
            'Poczekaj, porządkuję myśli.',
            'Zastanawiam się nad twoimi słowami.',
            'Pozwól mi to przetrawić.',
            'Twoja opinia daje mi do myślenia.'
        ],
        nextButtonPhrases: [
            "Świetna odpowiedź. 👍 Gdy będziesz gotowy, kliknij 'Dalej'.",
            "Dziękuję za odpowiedź. ✅ Kliknij 'Dalej', aby kontynuować.",
            "Ciekawe. 🧐 Gdy będziesz gotowy, kliknij 'Dalej'.",
            "Dobra robota! ✅ Kliknij 'Dalej', aby przejść dalej.",
            "To było wnikliwe! 💡 Kliknij 'Dalej' na kolejne pytanie.",
            "Dzięki za podzielenie się! 😊 Kliknij 'Dalej', aby kontynuować.",
            "Doceniam twoją odpowiedź. 👏 Kliknij 'Dalej', gdy będziesz gotowy na kolejne pytanie.",
            "Dziękuję! 👌 Gdy będziesz gotowy, kliknij 'Dalej'."
        ],
        submitAnswerPhrases: [
            "Świetnie, dziękuję za odpowiedź. ✅ Kliknij 'Zatwierdź odpowiedź', aby zakończyć.",
            "Dziękuję za szczegółową odpowiedź. 😊 Kliknij 'Zatwierdź odpowiedź', aby zakończyć.",
            "Wspaniale, wszystko mamy. 🎯 Kliknij 'Zatwierdź odpowiedź', aby zakończyć.",
            "Dziękuję za współpracę. 👍 Kliknij 'Zatwierdź odpowiedź', aby zakończyć.",
            "Byłeś bardzo pomocny. 🌟 Kliknij 'Zatwierdź odpowiedź', gdy będziesz gotowy.",
            "Idealnie, to wszystko, czego potrzebowaliśmy. 👌 Kliknij 'Zatwierdź odpowiedź', aby zakończyć."
        ],
        finishButtonPhrases: [
            "Wspaniale, wszystko mamy. 🎯 Kliknij 'Zakończ', aby zakończyć.",
            "Dziękuję za szczegółowe odpowiedzi. 😊 Kliknij 'Zakończ', aby zakończyć.",
            "Dobra robota, odpowiedziałeś na wszystkie pytania. ✅ Kliknij 'Zakończ', aby zakończyć.",
            "Dziękuję za współpracę. 👍 Kliknij 'Zakończ', aby zakończyć.",
            "Byłeś bardzo pomocny. 🌟 Kliknij 'Zakończ', gdy będziesz gotowy.",
            "Idealnie, to wszystko, czego potrzebowaliśmy. 👌 Kliknij 'Zakończ', aby zakończyć."
        ],
        fallbackMessage: "Myślę..."
    },
    el: { // Greek
        phrases: [
            'Μια στιγμή, σκέφτομαι.',
            'Χμμ, δώσε μου ένα δευτερόλεπτο.',
            'Ενδιαφέρον, άσε με να το σκεφτώ.',
            'Χμμ... Για να δούμε.',
            'Περίμενε λίγο, το σκέφτομαι.',
            'Άσε με να σκεφτώ για λίγο.',
            'Περίμενε, σκέφτομαι.',
            'Ας το σκεφτώ λίγο.',
            'Άσε με να οργανώσω τις σκέψεις μου.',
            'Εντάξει, επεξεργάζομαι την άποψή σου.',
            'Περίμενε, βάζω σε τάξη τις σκέψεις μου.',
            'Σκέφτομαι αυτά που είπες.',
            'Άσε με να το επεξεργαστώ λίγο.',
            'Η σκέψη σου με βάζει σε σκέψεις.'
        ],
        nextButtonPhrases: [
            "Καλή απάντηση. 👍 Όταν είσαι έτοιμος, κάνε κλικ στο 'Επόμενο'.",
            "Ευχαριστώ για την απάντησή σου. ✅ Πάτα 'Επόμενο' για να συνεχίσεις.",
            "Ενδιαφέρον. 🧐 Όταν είσαι έτοιμος, κάνε κλικ στο 'Επόμενο' για να προχωρήσεις στην επόμενη ερώτηση.",
            "Καλή δουλειά! ✅ Κάνε κλικ στο 'Επόμενο' όταν είσαι έτοιμος.",
            "Ήταν πολύ διορατικό! 💡 Πάτα 'Επόμενο' για την επόμενη ερώτηση.",
            "Ευχαριστώ που μοιράστηκες! 😊 Κάνε κλικ στο 'Επόμενο' για να συνεχίσεις.",
            "Εκτιμώ την απάντησή σου. 👏 Κάνε κλικ στο 'Επόμενο' όταν είσαι έτοιμος για την επόμενη ερώτηση.",
            "Ευχαριστώ! 👌 Όταν είσαι έτοιμος, πάτα 'Επόμενο'."
        ],
        submitAnswerPhrases: [
            "Εξαιρετικό, ευχαριστώ για την απάντησή σου. ✅ Πάτα 'Υποβολή Απάντησης' για να ολοκληρώσεις.",
            "Ευχαριστώ για την αναλυτική απάντησή σου. 😊 Πάτα 'Υποβολή Απάντησης' για να ολοκληρώσεις.",
            "Τέλεια, καλύψαμε τα πάντα. 🎯 Πάτα 'Υποβολή Απάντησης' για να ολοκληρώσεις.",
            "Ευχαριστώ για τη συνεργασία σου. 👍 Πάτα 'Υποβολή Απάντησης' για να ολοκληρώσεις.",
            "Ήσουν πολύ βοηθητικός. 🌟 Πάτα 'Υποβολή Απάντησης' όταν είσαι έτοιμος.",
            "Ιδανικό, αυτό ήταν ό,τι χρειαζόμασταν. 👌 Πάτα 'Υποβολή Απάντησης' για να ολοκληρώσεις."
        ],
        finishButtonPhrases: [
            "Τέλεια, καλύψαμε τα πάντα. 🎯 Πάτα 'Τέλος' για να ολοκληρώσεις.",
            "Ευχαριστώ για τις αναλυτικές απαντήσεις σου. 😊 Πάτα 'Τέλος' για να υποβάλεις.",
            "Καλή δουλειά, απάντησες σε όλες τις ερωτήσεις. ✅ Πάτα 'Τέλος' για να ολοκληρώσεις.",
            "Ευχαριστώ για τη συνεργασία σου. 👍 Πάτα 'Τέλος' για να ολοκληρώσεις.",
            "Ήσουν πολύ βοηθητικός. 🌟 Πάτα 'Τέλος' όταν είσαι έτοιμος.",
            "Ιδανικό, αυτό ήταν ό,τι χρειαζόμασταν. 👌 Πάτα 'Τέλος' για να ολοκληρώσεις."
        ],
        fallbackMessage: "Σκέφτομαι..."
    },
    ja: { // Japanese
        phrases: [
            '少々お待ちください、考えています。',
            'うーん、少し時間をください。',
            '面白いですね、ちょっと考えさせてください。',
            'うーん... 見てみましょう。',
            '少しお待ちください、考え中です。',
            '少し考えさせてください。',
            '少々お待ちを、考えています。',
            'ああ、それについて考えさせてください。',
            '思考を整理させてください。',
            'はい、あなたの意見を処理しています。',
            '少々お待ちください、考えをまとめています。',
            'あなたの言葉を考えています。',
            '少し時間をいただいて、それを整理します。',
            'あなたの意見は考えさせられます。'
        ],
        nextButtonPhrases: [
            "素晴らしい回答です。👍 準備ができたら「次へ」をクリックしてください。",
            "ご回答ありがとうございます。✅ 「次へ」をクリックして進んでください。",
            "面白いですね。🧐 準備ができたら「次へ」をクリックしてください。",
            "よくできました！✅ 準備ができたら「次へ」をクリックしてください。",
            "とても洞察力がありますね！💡 次の質問に進むには「次へ」をクリックしてください。",
            "共有していただきありがとうございます！😊 「次へ」をクリックして続行してください。",
            "ご回答に感謝します。👏 次の質問に進むには「次へ」をクリックしてください。",
            "ありがとうございます！👌 準備ができたら「次へ」をクリックしてください。"
        ],
        submitAnswerPhrases: [
            "素晴らしいです、ご回答ありがとうございます。✅ 「回答を送信」をクリックして完了してください。",
            "詳しいご回答ありがとうございます。😊 「回答を送信」をクリックして完了してください。",
            "素晴らしい、すべてカバーしました。🎯 「回答を送信」をクリックして完了してください。",
            "ご協力ありがとうございます。👍 「回答を送信」をクリックして完了してください。",
            "非常に助かりました。🌟 準備ができたら「回答を送信」をクリックしてください。",
            "完璧です、これが必要な全てです。👌 「回答を送信」をクリックして完了してください。"
        ],
        finishButtonPhrases: [
            "素晴らしい、すべてカバーしました。🎯 「完了」をクリックして完了してください。",
            "詳しいご回答ありがとうございます。😊 準備ができたら「完了」をクリックして送信してください。",
            "すべての質問に回答いただきありがとうございました。✅ 「完了」をクリックして完了してください。",
            "ご協力ありがとうございます。👍 「完了」をクリックして完了してください。",
            "非常に助かりました。🌟 準備ができたら「完了」をクリックしてください。",
            "完璧です、これが必要な全てです。👌 「完了」をクリックして完了してください。"
        ],
        fallbackMessage: "考えています..."
    },
    pa: { // Punjabi
        phrases: [
            'ਇੱਕ ਪਲ ਰੁਕੋ, ਸੋਚ ਰਿਹਾ ਹਾਂ।',
            'ਹੰਮ, ਮੈਨੂੰ ਇਕ ਸੈਕਿੰਡ ਦਿਓ।',
            'ਦਿਲਚਸਪ, ਇਸ ਬਾਰੇ ਸੋਚਣ ਦਿਓ।',
            'ਹੰਮ... ਵੇਖਦੇ ਹਾਂ।',
            'ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਪਲ ਰੁਕੋ, ਮੈਂ ਸੋਚ ਰਿਹਾ ਹਾਂ।',
            'ਮੈਨੂੰ ਥੋੜ੍ਹਾ ਸਮਾਂ ਸੋਚਣ ਦਿਓ।',
            'ਇੱਕ ਪਲ ਰੁਕੋ, ਮੈਂ ਸੋਚ ਰਿਹਾ ਹਾਂ।',
            'ਅੱਜ, ਇਸ ਬਾਰੇ ਸੋਚਣ ਦਿਓ।',
            'ਮੈਨੂੰ ਆਪਣੇ ਵਿਚਾਰ ਸਜਾਉਣ ਦਿਓ।',
            'ਠੀਕ ਹੈ, ਮੈਂ ਤੁਹਾਡੀ ਰਾਏ ਤੇ ਵਿਚਾਰ ਕਰ ਰਿਹਾ ਹਾਂ।',
            'ਇੱਕ ਪਲ ਰੁਕੋ, ਮੈਂ ਆਪਣੇ ਵਿਚਾਰ ਸਜਾ ਰਿਹਾ ਹਾਂ।',
            'ਮੈਂ ਤੁਹਾਡੇ ਕਹੇ ਤੇ ਵਿਚਾਰ ਕਰ ਰਿਹਾ ਹਾਂ।',
            'ਇਸ ਨੂੰ ਸਮਝਣ ਲਈ ਮੈਨੂੰ ਇਕ ਪਲ ਦਿਓ।',
            'ਤੁਹਾਡੀ ਰਾਏ ਨੇ ਮੈਨੂੰ ਸੋਚਣ ਤੇ ਮਜਬੂਰ ਕੀਤਾ।'
        ],
        nextButtonPhrases: [
            "ਬਹੁਤ ਵਧੀਆ ਜਵਾਬ। 👍 ਜਦੋਂ ਤੁਸੀਂ ਤਿਆਰ ਹੋਵੋ, 'ਅਗਲਾ' ਤੇ ਕਲਿਕ ਕਰੋ।",
            "ਤੁਹਾਡੇ ਯੋਗਦਾਨ ਲਈ ਧੰਨਵਾਦ। ✅ ਕਿਰਪਾ ਕਰਕੇ 'ਅਗਲਾ' ਤੇ ਕਲਿਕ ਕਰੋ।",
            "ਦਿਲਚਸਪ। 🧐 ਜਦੋਂ ਤੁਸੀਂ ਤਿਆਰ ਹੋਵੋ, 'ਅਗਲਾ' ਤੇ ਕਲਿਕ ਕਰੋ।",
            "ਚੰਗਾ ਕੀਤਾ! ✅ ਜਦੋਂ ਤੁਸੀਂ ਤਿਆਰ ਹੋਵੋ, 'ਅਗਲਾ' ਤੇ ਕਲਿਕ ਕਰੋ।",
            "ਇਹ ਬਹੁਤ ਵਧੀਆ ਸੀ! 💡 ਕਿਰਪਾ ਕਰਕੇ 'ਅਗਲਾ' ਤੇ ਕਲਿਕ ਕਰੋ।",
            "ਸ਼ੇਅਰ ਕਰਨ ਲਈ ਧੰਨਵਾਦ! 😊 'ਅਗਲਾ' ਤੇ ਕਲਿਕ ਕਰੋ।",
            "ਤੁਹਾਡੇ ਜਵਾਬ ਲਈ ਧੰਨਵਾਦ। 👏 ਜਦੋਂ ਤੁਸੀਂ ਤਿਆਰ ਹੋਵੋ, 'ਅਗਲਾ' ਤੇ ਕਲਿਕ ਕਰੋ।",
            "ਧੰਨਵਾਦ! 👌 ਜਦੋਂ ਤੁਸੀਂ ਤਿਆਰ ਹੋਵੋ, 'ਅਗਲਾ' ਤੇ ਕਲਿਕ ਕਰੋ।"
        ],
        submitAnswerPhrases: [
            "ਬਹੁਤ ਵਧੀਆ, ਤੁਹਾਡੇ ਯੋਗਦਾਨ ਲਈ ਧੰਨਵਾਦ। ✅ 'ਜਵਾਬ ਭੇਜੋ' ਤੇ ਕਲਿਕ ਕਰੋ।",
            "ਤੁਹਾਡੇ ਵਿਸਥਾਰਪੂਰਨ ਜਵਾਬ ਲਈ ਧੰਨਵਾਦ। 😊 'ਜਵਾਬ ਭੇਜੋ' ਤੇ ਕਲਿਕ ਕਰੋ।",
            "ਬਹੁਤ ਵਧੀਆ, ਅਸੀਂ ਸਭ ਕੁਝ ਕਵਰ ਕਰ ਲਿਆ ਹੈ। 🎯 'ਜਵਾਬ ਭੇਜੋ' ਤੇ ਕਲਿਕ ਕਰੋ।",
            "ਤੁਹਾਡੇ ਸਹਿਯੋਗ ਲਈ ਧੰਨਵਾਦ। 👍 'ਜਵਾਬ ਭੇਜੋ' ਤੇ ਕਲਿਕ ਕਰੋ।",
            "ਤੁਸੀਂ ਬਹੁਤ ਮਦਦਗਾਰ ਹੋ। 🌟 ਜਦੋਂ ਤੁਸੀਂ ਤਿਆਰ ਹੋਵੋ, 'ਜਵਾਬ ਭੇਜੋ' ਤੇ ਕਲਿਕ ਕਰੋ।",
            "ਇਹ ਬਿਲਕੁਲ ਵਧੀਆ ਹੈ, ਜੋ ਕੁਝ ਸਾਨੂੰ ਚਾਹੀਦਾ ਸੀ। 👌 'ਜਵਾਬ ਭੇਜੋ' ਤੇ ਕਲਿਕ ਕਰੋ।"
        ],
        finishButtonPhrases: [
            "ਬਹੁਤ ਵਧੀਆ, ਅਸੀਂ ਸਭ ਕੁਝ ਕਵਰ ਕਰ ਲਿਆ ਹੈ। 🎯 'ਮੁਕੰਮਲ ਕਰੋ' ਤੇ ਕਲਿਕ ਕਰੋ।",
            "ਤੁਹਾਡੇ ਵਿਸਥਾਰਪੂਰਨ ਜਵਾਬ ਲਈ ਧੰਨਵਾਦ। 😊 'ਮੁਕੰਮਲ ਕਰੋ' ਤੇ ਕਲਿਕ ਕਰੋ।",
            "ਸਾਰੇ ਸਵਾਲਾਂ ਦੇ ਜਵਾਬ ਦੇਣ ਲਈ ਧੰਨਵਾਦ। ✅ 'ਮੁਕੰਮਲ ਕਰੋ' ਤੇ ਕਲਿਕ ਕਰੋ।",
            "ਤੁਹਾਡੇ ਸਹਿਯੋਗ ਲਈ ਧੰਨਵਾਦ। 👍 'ਮੁਕੰਮਲ ਕਰੋ' ਤੇ ਕਲਿਕ ਕਰੋ।",
            "ਤੁਸੀਂ ਬਹੁਤ ਮਦਦਗਾਰ ਹੋ। 🌟 ਜਦੋਂ ਤੁਸੀਂ ਤਿਆਰ ਹੋਵੋ, 'ਮੁਕੰਮਲ ਕਰੋ' ਤੇ ਕਲਿਕ ਕਰੋ।",
            "ਇਹ ਬਿਲਕੁਲ ਵਧੀਆ ਹੈ, ਜੋ ਕੁਝ ਸਾਨੂੰ ਚਾਹੀਦਾ ਸੀ। 👌 'ਮੁਕੰਮਲ ਕਰੋ' ਤੇ ਕਲਿਕ ਕਰੋ।"
        ],
        fallbackMessage: "ਸੋਚ ਰਿਹਾ ਹਾਂ..."
    },
    uk: { // Ukrainian
        phrases: [
            'Зачекайте, я думаю.',
            'Хмм, дайте мені секунду.',
            'Цікаво, дайте подумати.',
            'Хмм... Давайте подивимось.',
            'Зачекайте трохи, я думаю.',
            'Дайте мені трохи подумати.',
            'Зачекайте, я обдумую.',
            'Ах, дайте мені це обдумати.',
            'Дайте мені впорядкувати думки.',
            'Гаразд, я обробляю вашу думку.',
            'Зачекайте, я впорядковую думки.',
            'Я думаю над вашими словами.',
            'Дайте мені трохи часу, щоб це обдумати.',
            'Ваші слова змушують мене думати.'
        ],
        nextButtonPhrases: [
            "Чудова відповідь. 👍 Коли будете готові, натисніть «Далі».",
            "Дякую за ваш внесок. ✅ Натисніть «Далі», щоб продовжити.",
            "Цікаво. 🧐 Коли будете готові, натисніть «Далі», щоб продовжити.",
            "Гарна робота! ✅ Натисніть «Далі», коли будете готові.",
            "Це було дуже цікаво! 💡 Натисніть «Далі», щоб перейти до наступного питання.",
            "Дякую, що поділились! 😊 Натисніть «Далі», щоб продовжити.",
            "Дякую за вашу відповідь. 👏 Натисніть «Далі», коли будете готові до наступного питання.",
            "Дякую! 👌 Коли будете готові, натисніть «Далі»."
        ],
        submitAnswerPhrases: [
            "Чудово, дякую за ваш внесок. ✅ Натисніть «Надіслати відповідь», щоб завершити.",
            "Дякую за вашу детальну відповідь. 😊 Натисніть «Надіслати відповідь», щоб завершити.",
            "Відмінно, ми все охопили. 🎯 Натисніть «Надіслати відповідь», щоб завершити.",
            "Дякую за вашу співпрацю. 👍 Натисніть «Надіслати відповідь», щоб завершити.",
            "Ви дуже допомогли. 🌟 Натисніть «Надіслати відповідь», коли будете готові.",
            "Чудово, це все, що нам потрібно. 👌 Натисніть «Надіслати відповідь», щоб завершити."
        ],
        finishButtonPhrases: [
            "Відмінно, ми все охопили. 🎯 Натисніть «Завершити», щоб завершити.",
            "Дякую за ваші детальні відповіді. 😊 Натисніть «Завершити», щоб надіслати.",
            "Дякую за відповіді на всі питання. ✅ Натисніть «Завершити», щоб завершити.",
            "Дякую за вашу співпрацю. 👍 Натисніть «Завершити», щоб завершити.",
            "Ви дуже допомогли. 🌟 Натисніть «Завершити», коли будете готові.",
            "Чудово, це все, що нам потрібно. 👌 Натисніть «Завершити», щоб завершити."
        ],
        fallbackMessage: "Думаю..."
    },
    gu: { // Gujarati
        phrases: [
            'થોડું રુકો, હું વિચારું છું.',
            'હુંમ્, મને એક સેકંડ આપો.',
            'રસપ્રદ,让我对此考虑一下。',
            'હુંમ્... ચાલો જોઈએ.',
            'થોડું રાહ જુઓ, હું વિચારું છું.',
            'મને થોડું વિચારવા દો.',
            'જરા રાહ જુઓ, હું વિચારું છું.',
            'આહ,让我考虑一下。',
            'મને મારા વિચારો ગોઠવવા દો.',
            'સારો છે, હું તમારા વિચારને પરખું છું.',
            'જરા રાહ જુઓ, હું મારા વિચારો ગોઠવી રહ્યો છું.',
            'હું તમારા શબ્દો પર વિચાર કરી રહ્યો છું.',
            'મને આને સમાવવા માટે થોડીવાર દો.',
            'તમારી વાત મને વિચારવામાં મૂકે છે.'
        ],
        nextButtonPhrases: [
            "મોટો જવાબ. 👍 તમે તૈયાર થાઓ, 'આગળ' પર ક્લિક કરો.",
            "તમારા યોગદાન માટે આભાર. ✅ કૃપા કરીને 'આગળ' પર ક્લિક કરો.",
            "રસપ્રદ. 🧐 તમે તૈયાર થાઓ, 'આગળ' પર ક્લિક કરો.",
            "સારા કામ! ✅ 'આગળ' પર ક્લિક કરો.",
            "આ એક સારી સમજણ હતી! 💡 'આગળ' પર ક્લિક કરો.",
            "સાંભળવા માટે આભાર! 😊 'આગળ' પર ક્લિક કરો.",
            "તમારા જવાબ માટે આભાર. 👏 'આગળ' પર ક્લિક કરો.",
            "આભાર! 👌 તમે તૈયાર થાઓ, 'આગળ' પર ક્લિક કરો."
        ],
        submitAnswerPhrases: [
            "મોટો જવાબ, આભાર. ✅ 'જવાબ મોકલો' પર ક્લિક કરો.",
            "તમારા વિસ્તૃત જવાબ માટે આભાર. 😊 'જવાબ મોકલો' પર ક્લિક કરો.",
            "તમારા યોગદાન માટે આભાર. 🎯 'જવાબ મોકલો' પર ક્લિક કરો.",
            "તમારા સહકાર માટે આભાર. 👍 'જવાબ મોકલો' પર ક્લિક કરો.",
            "તમે ખૂબ મદદરૂપ છો. 🌟 'જવાબ મોકલો' પર ક્લિક કરો.",
            "આદર્શ, આ જ જોઈએ હતું. 👌 'જવાબ મોકલો' પર ક્લિક કરો."
        ],
        finishButtonPhrases: [
            "સારો જવાબ, અમે બધું કવર કર્યું છે. 🎯 'સમાપ્ત કરો' પર ક્લિક કરો.",
            "તમારા વિસ્તૃત જવાબ માટે આભાર. 😊 'સમાપ્ત કરો' પર ક્લિક કરો.",
            "બધા સવાલોના જવાબ માટે આભાર. ✅ 'સમાપ્ત કરો' પર ક્લિક કરો.",
            "તમારા સહકાર માટે આભાર. 👍 'સમાપ્ત કરો' પર ક્લિક કરો.",
            "તમે ખૂબ મદદરૂપ છો. 🌟 'સમાપ્ત કરો' પર ક્લિક કરો.",
            "આદર્શ, આ જ જોઈએ હતું. 👌 'સમાપ્ત કરો' પર ક્લિક કરો."
        ],
        fallbackMessage: "વિચાર કરું છું..."
    },
    he: { // Hebrew
        phrases: [
            'רגע, אני חושב.',
            'הממ, תן לי שנייה.',
            'מעניין, תן לי לחשוב על זה.',
            'הממ... בוא נראה.',
            'רק רגע, אני חושב על זה.',
            'תן לי לחשוב רגע.',
            'רגע, אני חושב על זה.',
            'אה, תן לי לחשוב על זה.',
            'תן לי לסדר את המחשבות שלי.',
            'אוקיי, אני מעבד את הרעיון שלך.',
            'רגע, אני מארגן את המחשבות שלי.',
            'אני מהרהר בדבריך.',
            'תן לי רגע לעכל את זה.',
            'הדבר שאמרת גורם לי לחשוב.'
        ],
        nextButtonPhrases: [
            "תשובה מצוינת. 👍 כשאתה מוכן, לחץ על 'הבא'.",
            "תודה על התשובה שלך. ✅ לחץ על 'הבא' כדי להמשיך.",
            "מעניין. 🧐 כשאתה מוכן, לחץ על 'הבא'.",
            "עבודה טובה! ✅ לחץ על 'הבא' כשאתה מוכן.",
            "זו הייתה תובנה מצוינת! 💡 לחץ על 'הבא' לשאלה הבאה.",
            "תודה ששיתפת! 😊 לחץ על 'הבא' כדי להמשיך.",
            "אני מעריך את התשובה שלך. 👏 לחץ על 'הבא' כשאתה מוכן לשאלה הבאה.",
            "תודה! 👌 כשאתה מוכן, לחץ על 'הבא'."
        ],
        submitAnswerPhrases: [
            "מצוין, תודה על התשובה שלך. ✅ לחץ על 'שלח תשובה' כדי לסיים.",
            "תודה על התשובה המפורטת שלך. 😊 לחץ על 'שלח תשובה' כדי להשלים.",
            "נהדר, כיסינו הכל. 🎯 לחץ על 'שלח תשובה' כדי לסיים.",
            "תודה על שיתוף הפעולה שלך. 👍 לחץ על 'שלח תשובה' כדי לסיים.",
            "היית מאוד עוזר. 🌟 לחץ על 'שלח תשובה' כשאתה מוכן.",
            "מעולה, זה בדיוק מה שהיינו צריכים. 👌 לחץ על 'שלח תשובה' כדי לסיים."
        ],
        finishButtonPhrases: [
            "נהדר, כיסינו הכל. 🎯 לחץ על 'סיום' כדי לסיים.",
            "תודה על התשובות המפורטות שלך. 😊 לחץ על 'סיום' כדי לשלוח.",
            "עבודה מצוינת במענה על כל השאלות. ✅ לחץ על 'סיום' כדי לסיים.",
            "תודה על שיתוף הפעולה שלך. 👍 לחץ על 'סיום' כדי לסיים.",
            "היית מאוד עוזר. 🌟 לחץ על 'סיום' כשאתה מוכן.",
            "מעולה, זה בדיוק מה שהיינו צריכים. 👌 לחץ על 'סיום' כדי לסיים."
        ],
        fallbackMessage: "חושב..."
    },
    bn: { // Bengali
        phrases: [
            'একটু অপেক্ষা করুন, ভাবছি।',
            'হুম, আমাকে একটু সময় দিন।',
            'মজার ব্যাপার, এটা নিয়ে ভাবতে দিন।',
            'হুম... দেখা যাক।',
            'একটু অপেক্ষা করুন, আমি ভাবছি।',
            'আমাকে একটু ভাবতে দিন।',
            'একটু অপেক্ষা করুন, আমি চিন্তা করছি।',
            'আচ্ছা, এটা নিয়ে ভাবতে দিন।',
            'আমাকে আমার ভাবনাগুলো সাজাতে দিন।',
            'ঠিক আছে, আমি আপনার কথাটি ভাবছি।',
            'একটু অপেক্ষা করুন, আমি আমার ভাবনাগুলো গুছিয়ে নিচ্ছি।',
            'আমি আপনার কথাগুলি নিয়ে ভাবছি।',
            'আমাকে একটু সময় দিন, এটি হজম করতে।',
            'আপনার কথা আমাকে ভাবাচ্ছে।'
        ],
        nextButtonPhrases: [
            "অসাধারণ উত্তর। 👍 প্রস্তুত হলে 'পরবর্তী' ক্লিক করুন।",
            "আপনার অবদানের জন্য ধন্যবাদ। ✅ দয়া করে 'পরবর্তী' ক্লিক করুন।",
            "মজার ব্যাপার। 🧐 প্রস্তুত হলে 'পরবর্তী' ক্লিক করুন।",
            "ভালো কাজ! ✅ প্রস্তুত হলে 'পরবর্তী' ক্লিক করুন।",
            "এটা ছিল চমৎকার অন্তর্দৃষ্টি! 💡 দয়া করে 'পরবর্তী' ক্লিক করুন।",
            "শেয়ার করার জন্য ধন্যবাদ! 😊 'পরবর্তী' ক্লিক করুন।",
            "আপনার উত্তরটির প্রশংসা করি। 👏 পরবর্তী প্রশ্নের জন্য প্রস্তুত হলে 'পরবর্তী' ক্লিক করুন।",
            "ধন্যবাদ! 👌 প্রস্তুত হলে 'পরবর্তী' ক্লিক করুন।"
        ],
        submitAnswerPhrases: [
            "অসাধারণ, আপনার অবদানের জন্য ধন্যবাদ। ✅ 'উত্তর জমা দিন' ক্লিক করুন।",
            "আপনার বিস্তারিত উত্তরের জন্য ধন্যবাদ। 😊 'উত্তর জমা দিন' ক্লিক করুন।",
            "দারুণ, আমরা সবকিছু কাভার করেছি। 🎯 'উত্তর জমা দিন' ক্লিক করুন।",
            "আপনার সহযোগিতার জন্য ধন্যবাদ। 👍 'উত্তর জমা দিন' ক্লিক করুন।",
            "আপনি খুব সহায়ক ছিলেন। 🌟 প্রস্তুত হলে 'উত্তর জমা দিন' ক্লিক করুন।",
            "পারফেক্ট, এটাই যা আমাদের প্রয়োজন ছিল। 👌 'উত্তর জমা দিন' ক্লিক করুন।"
        ],
        finishButtonPhrases: [
            "দারুণ, আমরা সবকিছু কাভার করেছি। 🎯 'শেষ করুন' ক্লিক করুন।",
            "আপনার বিস্তারিত উত্তরের জন্য ধন্যবাদ। 😊 'শেষ করুন' ক্লিক করুন।",
            "সব প্রশ্নের উত্তর দেওয়ার জন্য ধন্যবাদ। ✅ 'শেষ করুন' ক্লিক করুন।",
            "আপনার সহযোগিতার জন্য ধন্যবাদ। 👍 'শেষ করুন' ক্লিক করুন।",
            "আপনি খুব সহায়ক ছিলেন। 🌟 'শেষ করুন' ক্লিক করুন।",
            "পারফেক্ট, এটাই যা আমাদের প্রয়োজন ছিল। 👌 'শেষ করুন' ক্লিক করুন।"
        ],
        fallbackMessage: "ভাবছি..."
    },
    ta: { // Tamil
        phrases: [
            'ஒரு நிமிடம், யோசித்து பார்க்கிறேன்.',
            'ஹும், எனக்கு ஒரு நொடி கொடுக்கவும்.',
            'சுவாரஸ்யமாக இருக்கிறது, இதை யோசிக்கிறேன்.',
            'ஹும்... பார்க்கலாம்.',
            'ஒரு நிமிடம் காத்திருங்கள், யோசிக்கிறேன்.',
            'சிறிது யோசிக்க விடுங்கள்.',
            'ஒரு நிமிடம், யோசிக்கிறேன்.',
            'அசத்தல், இதை யோசிக்க விடுங்கள்.',
            'என் சிந்தனைகளை ஒழுங்குபடுத்த வைக்கவும்.',
            'சரி, உங்கள் கருத்தை பரிசீலிக்கிறேன்.',
            'ஒரு நிமிடம், என் சிந்தனைகளை ஒழுங்குபடுத்துகிறேன்.',
            'உங்கள் வார்த்தைகளை யோசிக்கிறேன்.',
            'இதை ஜீரணிக்க ஒரு நொடி கொடுங்கள்.',
            'உங்கள் கருத்து என்னை யோசிக்க வைத்தது.'
        ],
        nextButtonPhrases: [
            "அருமையான பதில். 👍 நீங்கள் தயார் ஆனால், 'அடுத்தது' கிளிக் செய்யவும்.",
            "உங்கள் கருத்துக்கு நன்றி. ✅ 'அடுத்தது' என்பதைத் தட்டவும்.",
            "சுவாரஸ்யம். 🧐 நீங்கள் தயார் ஆனால், 'அடுத்தது' கிளிக் செய்யவும்.",
            "நன்றாகச் செய்தீர்கள்! ✅ 'அடுத்தது' கிளிக் செய்யவும்.",
            "இது சிறந்த பார்வையாக இருந்தது! 💡 'அடுத்தது' கிளிக் செய்யவும்.",
            "பகிர்ந்ததற்கு நன்றி! 😊 'அடுத்தது' கிளிக் செய்யவும்.",
            "உங்கள் பதிலை நான் பாராட்டுகிறேன். 👏 நீங்கள் தயார் ஆனால், 'அடுத்தது' கிளிக் செய்யவும்.",
            "நன்றி! 👌 நீங்கள் தயார் ஆனால், 'அடுத்தது' கிளிக் செய்யவும்."
        ],
        submitAnswerPhrases: [
            "மிக அருமை, உங்கள் கருத்துக்கு நன்றி. ✅ 'பதிலளிக்கவும்' என்பதைக் கிளிக் செய்யவும்.",
            "உங்கள் விரிவான பதிலுக்கு நன்றி. 😊 'பதிலளிக்கவும்' என்பதைக் கிளிக் செய்யவும்.",
            "மிகச் சிறப்பு, நாங்கள் எல்லாவற்றையும் கையாண்டுவிட்டோம். 🎯 'பதிலளிக்கவும்' என்பதைக் கிளிக் செய்யவும்.",
            "உங்கள் ஒத்துழைப்புக்கு நன்றி. 👍 'பதிலளிக்கவும்' என்பதைக் கிளிக் செய்யவும்.",
            "நீங்கள் மிகவும் உதவிகரமாக இருந்தீர்கள். 🌟 தயார் என்றால், 'பதிலளிக்கவும்' என்பதைக் கிளிக் செய்யவும்.",
            "சரியாகச் செய்யப்பட்டுள்ளது, இதை மட்டுமே எங்களுக்குத் தேவை. 👌 'பதிலளிக்கவும்' என்பதைக் கிளிக் செய்யவும்."
        ],
        finishButtonPhrases: [
            "மிகச் சிறப்பு, நாங்கள் எல்லாவற்றையும் கையாண்டுவிட்டோம். 🎯 'முடிக்கவும்' என்பதைக் கிளிக் செய்யவும்.",
            "உங்கள் விரிவான பதிலுக்கு நன்றி. 😊 'முடிக்கவும்' என்பதைக் கிளிக் செய்யவும்.",
            "எல்லா கேள்விகளுக்கும் பதில் அளித்ததற்காக நன்றி. ✅ 'முடிக்கவும்' என்பதைக் கிளிக் செய்யவும்.",
            "உங்கள் ஒத்துழைப்புக்கு நன்றி. 👍 'முடிக்கவும்' என்பதைக் கிளிக் செய்யவும்.",
            "நீங்கள் மிகவும் உதவிகரமாக இருந்தீர்கள். 🌟 தயார் என்றால், 'முடிக்கவும்' என்பதைக் கிளிக் செய்யவும்.",
            "சரியாகச் செய்யப்பட்டுள்ளது, இதை மட்டுமே எங்களுக்குத் தேவை. 👌 'முடிக்கவும்' என்பதைக் கிளிக் செய்யவும்."
        ],
        fallbackMessage: "யோசிக்கிறேன்..."
    },
    tr: { // Turkish
        phrases: [
            'Bir saniye, düşünüyorum.',
            'Hmm, bana bir saniye ver.',
            'İlginç, bunu bir düşüneyim.',
            'Hmm... Bir bakalım.',
            'Bir dakika bekleyin, düşünüyorum.',
            'Bana biraz düşünme süresi ver.',
            'Bir saniye, düşünüyorum.',
            'Ah, bunu bir düşüneyim.',
            'Düşüncelerimi toparlamama izin ver.',
            'Tamam, söylediklerinizi değerlendiriyorum.',
            'Bir saniye, düşüncelerimi düzenliyorum.',
            'Sözlerinizi düşünüyorum.',
            'Bunu biraz sindirmeme izin ver.',
            'Sözleriniz beni düşündürdü.'
        ],
        nextButtonPhrases: [
            "Harika bir cevap. 👍 Hazır olduğunuzda 'İleri'ye tıklayın.",
            "Katkınız için teşekkürler. ✅ Devam etmek için 'İleri'ye tıklayın.",
            "İlginç. 🧐 Hazır olduğunuzda, başka bir soruyu keşfetmek için 'İleri'ye tıklayın.",
            "İyi iş! ✅ Devam etmek için hazır olduğunuzda 'İleri'ye tıklayın.",
            "Bu çok anlamlıydı! 💡 Bir sonraki soruya geçmek için 'İleri'ye tıklayın.",
            "Paylaştığınız için teşekkürler! 😊 Devam etmek için 'İleri'ye tıklayın.",
            "Cevabınızı takdir ediyorum. 👏 Hazır olduğunuzda 'İleri'ye tıklayın.",
            "Teşekkürler! 👌 Hazır olduğunuzda 'İleri'ye tıklayın."
        ],
        submitAnswerPhrases: [
            "Harika, katkınız için teşekkürler. ✅ Cevabınızı tamamlamak için 'Cevabı Gönder'e tıklayın.",
            "Detaylı cevabınız için teşekkürler. 😊 Formu tamamlamak için 'Cevabı Gönder'e tıklayın.",
            "Mükemmel, her şeyi ele aldık. 🎯 Katkınızı tamamlamak için 'Cevabı Gönder'e tıklayın.",
            "İş birliğiniz için teşekkürler. 👍 Süreci tamamlamak için 'Cevabı Gönder'e tıklayın.",
            "Çok yardımcı oldunuz. 🌟 Hazır olduğunuzda 'Cevabı Gönder'e tıklayın.",
            "Mükemmel, ihtiyacımız olan her şey bu. 👌 Katkınızı tamamlamak için 'Cevabı Gönder'e tıklayın."
        ],
        finishButtonPhrases: [
            "Mükemmel, her şeyi ele aldık. 🎯 Formu tamamlamak için 'Bitir'e tıklayın.",
            "Detaylı cevaplarınız için teşekkürler. 😊 Göndermek için 'Bitir'e tıklayın.",
            "Tüm soruları cevapladığınız için teşekkürler. ✅ Formu tamamlamak için 'Bitir'e tıklayın.",
            "İş birliğiniz için teşekkürler. 👍 Süreci tamamlamak için 'Bitir'e tıklayın.",
            "Çok yardımcı oldunuz. 🌟 Hazır olduğunuzda 'Bitir'e tıklayın.",
            "Mükemmel, ihtiyacımız olan her şey bu. 👌 Katkınızı tamamlamak için 'Bitir'e tıklayın."
        ],
        fallbackMessage: "Düşünüyorum..."
    },
    sw: { // Swahili
        phrases: [
            'Tafadhali subiri, nadhani.',
            'Hmm, nipe sekunde moja.',
            'Inafurahisha, wacha nifikirie kidogo.',
            'Hmm... Tuone.',
            'Tafadhali subiri, nadhani juu ya hili.',
            'Nipe muda kidogo wa kufikiria.',
            'Subiri kidogo, nadhani juu ya hili.',
            'Ah, wacha nifikirie kuhusu hili.',
            'Niruhusu nipange mawazo yangu.',
            'Sawa, ninachakata maoni yako.',
            'Subiri kidogo, ninapanga mawazo yangu.',
            'Natafakari juu ya maneno yako.',
            'Nipe muda kidogo wa kulielewa hili.',
            'Maoni yako yamenifanya nifikirie.'
        ],
        nextButtonPhrases: [
            "Jibu zuri. 👍 Ukiwa tayari, bonyeza 'Ifuatayo'.",
            "Asante kwa maoni yako. ✅ Bonyeza 'Ifuatayo' kuendelea.",
            "Inafurahisha. 🧐 Ukiwa tayari, bonyeza 'Ifuatayo' kuchunguza swali lingine.",
            "Kazi nzuri! ✅ Bonyeza 'Ifuatayo' ukiwa tayari kuendelea.",
            "Hii ilikuwa ya kupendeza! 💡 Bonyeza 'Ifuatayo' kwa swali linalofuata.",
            "Asante kwa kushiriki! 😊 Bonyeza 'Ifuatayo' kuendelea.",
            "Ninathamini jibu lako. 👏 Bonyeza 'Ifuatayo' ukiwa tayari kwa swali lingine.",
            "Asante! 👌 Ukiwa tayari, bonyeza 'Ifuatayo'."
        ],
        submitAnswerPhrases: [
            "Vizuri sana, asante kwa maoni yako. ✅ Bonyeza 'Tuma Jibu' kumaliza.",
            "Asante kwa jibu lako la kina. 😊 Bonyeza 'Tuma Jibu' kumaliza fomu.",
            "Kikamilifu, tumeshughulikia kila kitu. 🎯 Bonyeza 'Tuma Jibu' kumaliza maoni yako.",
            "Asante kwa ushirikiano wako. 👍 Bonyeza 'Tuma Jibu' kumaliza mchakato.",
            "Ulikuwa msaada mkubwa. 🌟 Ukiwa tayari, bonyeza 'Tuma Jibu'.",
            "Kamili, hicho ndicho tulichohitaji. 👌 Bonyeza 'Tuma Jibu' kumaliza maoni yako."
        ],
        finishButtonPhrases: [
            "Kikamilifu, tumeshughulikia kila kitu. 🎯 Bonyeza 'Maliza' kumaliza fomu.",
            "Asante kwa majibu yako ya kina. 😊 Bonyeza 'Maliza' kutuma.",
            "Kazi nzuri kujibu maswali yote. ✅ Bonyeza 'Maliza' kumaliza fomu.",
            "Asante kwa ushirikiano wako. 👍 Bonyeza 'Maliza' kumaliza mchakato.",
            "Ulikuwa msaada mkubwa. 🌟 Ukiwa tayari, bonyeza 'Maliza'.",
            "Kamili, hicho ndicho tulichohitaji. 👌 Bonyeza 'Maliza' kumaliza maoni yako."
        ],
        fallbackMessage: "Nadhani..."
    },
    nl: { // Dutch
        phrases: [
            'Een moment, ik denk na.',
            'Hmm, geef me een seconde.',
            'Interessant, laat me hierover nadenken.',
            'Hmm... Laten we eens kijken.',
            'Een momentje terwijl ik nadenk.',
            'Laat me even nadenken.',
            'Wacht even, ik ben aan het nadenken.',
            'Ah, laat me dit overwegen.',
            'Laat me mijn gedachten ordenen.',
            'Oké, ik verwerk je punt.',
            'Wacht even, ik orden mijn gedachten.',
            'Ik overdenk je woorden.',
            'Geef me even de tijd om dit te verwerken.',
            'Je opmerking zet me aan het denken.'
        ],
        nextButtonPhrases: [
            "Geweldig antwoord. 👍 Klik op 'Volgende' wanneer je klaar bent.",
            "Bedankt voor je bijdrage. ✅ Klik op 'Volgende' om door te gaan.",
            "Interessant. 🧐 Klik op 'Volgende' om door te gaan naar de volgende vraag.",
            "Goed gedaan! ✅ Klik op 'Volgende' wanneer je verder wilt gaan.",
            "Dat was heel inzichtelijk! 💡 Klik op 'Volgende' voor de volgende vraag.",
            "Bedankt voor het delen! 😊 Klik op 'Volgende' om verder te gaan.",
            "Ik waardeer je antwoord. 👏 Klik op 'Volgende' voor de volgende vraag.",
            "Bedankt! 👌 Klik op 'Volgende' wanneer je verder wilt gaan."
        ],
        submitAnswerPhrases: [
            "Geweldig, bedankt voor je bijdrage. ✅ Klik op 'Antwoord verzenden' om te voltooien.",
            "Bedankt voor je gedetailleerde antwoord. 😊 Klik op 'Antwoord verzenden' om het formulier af te ronden.",
            "Uitstekend, we hebben alles behandeld. 🎯 Klik op 'Antwoord verzenden' om af te ronden.",
            "Bedankt voor je samenwerking. 👍 Klik op 'Antwoord verzenden' om het proces af te ronden.",
            "Je was erg behulpzaam. 🌟 Klik op 'Antwoord verzenden' wanneer je klaar bent.",
            "Perfect, dat is alles wat we nodig hadden. 👌 Klik op 'Antwoord verzenden' om af te ronden."
        ],
        finishButtonPhrases: [
            "Uitstekend, we hebben alles behandeld. 🎯 Klik op 'Voltooien' om het formulier af te ronden.",
            "Bedankt voor je gedetailleerde antwoorden. 😊 Klik op 'Voltooien' om in te dienen.",
            "Goed gedaan met het beantwoorden van alle vragen. ✅ Klik op 'Voltooien' om het formulier af te ronden.",
            "Bedankt voor je samenwerking. 👍 Klik op 'Voltooien' om het proces af te ronden.",
            "Je was erg behulpzaam. 🌟 Klik op 'Voltooien' wanneer je klaar bent.",
            "Perfect, dat is alles wat we nodig hadden. 👌 Klik op 'Voltooien' om af te ronden."
        ],
        fallbackMessage: "Denken..."
    },
    bs: { // Bosnian
        phrases: [
            'Samo trenutak, razmišljam.',
            'Hmm, daj mi sekundu.',
            'Zanimljivo, pusti me da razmislim o tome.',
            'Hmm... Da vidimo.',
            'Samo trenutak dok razmišljam.',
            'Pusti me da malo razmislim.',
            'Samo trenutak, razmišljam.',
            'Ah, pusti me da razmislim o tome.',
            'Pusti me da organizujem misli.',
            'U redu, obrađujem tvoju misao.',
            'Samo trenutak, sređujem svoje misli.',
            'Razmišljam o tvojim riječima.',
            'Daj mi trenutak da to razmislim.',
            'Tvoje riječi su me natjerale da razmislim.'
        ],
        nextButtonPhrases: [
            "Odličan odgovor. 👍 Kada budeš spreman, klikni na 'Dalje'.",
            "Hvala na tvom doprinosu. ✅ Klikni na 'Dalje' za nastavak.",
            "Zanimljivo. 🧐 Kada budeš spreman, klikni na 'Dalje' za sljedeće pitanje.",
            "Dobar posao! ✅ Klikni na 'Dalje' kada budeš spreman.",
            "To je bilo vrlo uvidno! 💡 Klikni na 'Dalje' za sljedeće pitanje.",
            "Hvala što si podijelio! 😊 Klikni na 'Dalje' za nastavak.",
            "Cijenim tvoj odgovor. 👏 Klikni na 'Dalje' kada budeš spreman za sljedeće pitanje.",
            "Hvala! 👌 Kada budeš spreman, klikni na 'Dalje'."
        ],
        submitAnswerPhrases: [
            "Odlično, hvala na tvom doprinosu. ✅ Klikni na 'Pošalji odgovor' za završetak.",
            "Hvala na detaljnom odgovoru. 😊 Klikni na 'Pošalji odgovor' za dovršetak.",
            "Izvrsno, pokrili smo sve. 🎯 Klikni na 'Pošalji odgovor' za završetak.",
            "Hvala na saradnji. 👍 Klikni na 'Pošalji odgovor' za završetak.",
            "Bio si od velike pomoći. 🌟 Klikni na 'Pošalji odgovor' kada budeš spreman.",
            "Savršeno, to je sve što nam treba. 👌 Klikni na 'Pošalji odgovor' za završetak."
        ],
        finishButtonPhrases: [
            "Izvrsno, pokrili smo sve. 🎯 Klikni na 'Završi' za dovršetak.",
            "Hvala na tvojim detaljnim odgovorima. 😊 Klikni na 'Završi' za slanje.",
            "Dobar posao u odgovaranju na sva pitanja. ✅ Klikni na 'Završi' za dovršetak.",
            "Hvala na saradnji. 👍 Klikni na 'Završi' za dovršetak.",
            "Bio si od velike pomoći. 🌟 Klikni na 'Završi' kada budeš spreman.",
            "Savršeno, to je sve što nam treba. 👌 Klikni na 'Završi' za dovršetak."
        ],
        fallbackMessage: "Razmišljam..."
    },
    hr: { // Croatian
        phrases: [
            'Samo trenutak, razmišljam.',
            'Hmm, daj mi sekundu.',
            'Zanimljivo, dopusti mi da razmislim o tome.',
            'Hmm... Da vidimo.',
            'Samo trenutak dok razmišljam.',
            'Daj mi malo vremena za razmišljanje.',
            'Samo trenutak, razmišljam.',
            'Ah, dopusti mi da to razmislim.',
            'Dopusti mi da organiziram svoje misli.',
            'U redu, obrađujem tvoju misao.',
            'Samo trenutak, organiziram misli.',
            'Razmišljam o tvojim riječima.',
            'Daj mi trenutak da to procesuiram.',
            'Tvoja misao me potaknula na razmišljanje.'
        ],
        nextButtonPhrases: [
            "Odličan odgovor. 👍 Kad budeš spreman, klikni na 'Dalje'.",
            "Hvala na tvom doprinosu. ✅ Klikni na 'Dalje' za nastavak.",
            "Zanimljivo. 🧐 Kad budeš spreman, klikni na 'Dalje' za sljedeće pitanje.",
            "Dobar posao! ✅ Klikni na 'Dalje' kad budeš spreman.",
            "To je bilo vrlo uvidno! 💡 Klikni na 'Dalje' za sljedeće pitanje.",
            "Hvala što si podijelio! 😊 Klikni na 'Dalje' za nastavak.",
            "Cijenim tvoj odgovor. 👏 Klikni na 'Dalje' kad budeš spreman za sljedeće pitanje.",
            "Hvala! 👌 Kad budeš spreman, klikni na 'Dalje'."
        ],
        submitAnswerPhrases: [
            "Odlično, hvala na tvom doprinosu. ✅ Klikni na 'Pošalji odgovor' za završetak.",
            "Hvala na detaljnom odgovoru. 😊 Klikni na 'Pošalji odgovor' za dovršetak.",
            "Izvrsno, sve smo pokrili. 🎯 Klikni na 'Pošalji odgovor' za završetak.",
            "Hvala na suradnji. 👍 Klikni na 'Pošalji odgovor' za završetak.",
            "Bio si od velike pomoći. 🌟 Klikni na 'Pošalji odgovor' kad budeš spreman.",
            "Savršeno, to je sve što nam treba. 👌 Klikni na 'Pošalji odgovor' za završetak."
        ],
        finishButtonPhrases: [
            "Izvrsno, sve smo pokrili. 🎯 Klikni na 'Završi' za dovršetak.",
            "Hvala na tvojim detaljnim odgovorima. 😊 Klikni na 'Završi' za slanje.",
            "Dobar posao u odgovaranju na sva pitanja. ✅ Klikni na 'Završi' za dovršetak.",
            "Hvala na suradnji. 👍 Klikni na 'Završi' za završetak.",
            "Bio si od velike pomoći. 🌟 Klikni na 'Završi' kad budeš spreman.",
            "Savršeno, to je sve što nam treba. 👌 Klikni na 'Završi' za dovršetak."
        ],
        fallbackMessage: "Razmišljam..."
    },
    hy: { // Armenian
        phrases: [
            'Մի պահ, մտածում եմ։',
            'Հմմ, տվեք ինձ մի վայրկյան։',
            'Հետաքրքիր է, թող մտածեմ դրա մասին։',
            'Հմմ... Տեսնենք։',
            'Մի պահ սպասեք, մտածում եմ։',
            'Թողեք, որ մի քիչ մտածեմ։',
            'Մի պահ, մտածում եմ։',
            'Ա՜հ, թող մտածեմ դրա մասին։',
            'Թողեք, որ կարգավորեմ իմ մտքերը։',
            'Լավ, մշակում եմ ձեր միտքը։',
            'Մի պահ, կարգավորում եմ մտքերս։',
            'Մտածում եմ ձեր ասածի մասին։',
            'Տվեք ինձ մի պահ, որպեսզի հասկանալ դա։',
            'Ձեր ասածը ստիպեց ինձ մտածել։'
        ],
        nextButtonPhrases: [
            "Հիանալի պատասխան։ 👍 Երբ պատրաստ լինեք, սեղմեք 'Հաջորդը'։",
            "Շնորհակալություն ձեր մասնակցության համար։ ✅ Շարունակելու համար սեղմեք 'Հաջորդը'։",
            "Հետաքրքիր է։ 🧐 Երբ պատրաստ լինեք, սեղմեք 'Հաջորդը'։",
            "Լավ արեցիք։ ✅ Սեղմեք 'Հաջորդը', երբ պատրաստ լինեք։",
            "Դա շատ խորաթափանց էր։ 💡 Սեղմեք 'Հաջորդը' հաջորդ հարցի համար։",
            "Շնորհակալություն, որ կիսվեցիք։ 😊 Շարունակելու համար սեղմեք 'Հաջորդը'։",
            "Գնահատում եմ ձեր պատասխանը։ 👏 Սեղմեք 'Հաջորդը', երբ պատրաստ լինեք։",
            "Շնորհակալություն։ 👌 Երբ պատրաստ լինեք, սեղմեք 'Հաջորդը'։"
        ],
        submitAnswerPhrases: [
            "Հիանալի է, շնորհակալ եմ ձեր մասնակցության համար։ ✅ Պատասխանը վերջնականացնելու համար սեղմեք 'Ներկայացնել'։",
            "Շնորհակալ եմ ձեր մանրամասն պատասխանի համար։ 😊 Սեղմեք 'Ներկայացնել' ձևաթուղթը լրացնելու համար։",
            "Հիանալի է, մենք ամեն ինչի մասին խոսեցինք։ 🎯 Սեղմեք 'Ներկայացնել' ձեր պատասխանը վերջնականացնելու համար։",
            "Շնորհակալ եմ ձեր համագործակցության համար։ 👍 Հիմա կարող եք սեղմել 'Ներկայացնել' գործընթացը ավարտելու համար։",
            "Դուք շատ օգտակար եք եղել։ 🌟 Պատրաստ լինելու դեպքում սեղմեք 'Ներկայացնել'։",
            "Հիանալի է, սա հենց այն էր, ինչ մեզ պետք էր։ 👌 Սեղմեք 'Ներկայացնել' ձեր մասնակցությունը վերջնականացնելու համար։"
        ],
        finishButtonPhrases: [
            "Հիանալի է, ամեն ինչի մասին խոսեցինք։ 🎯 Սեղմեք 'Ավարտել' ձևաթուղթը լրացնելու համար։",
            "Շնորհակալ եմ ձեր մանրամասն պատասխանների համար։ 😊 Պատրաստ լինելու դեպքում սեղմեք 'Ավարտել'։",
            "Շնորհակալություն բոլոր հարցերին պատասխանելու համար։ ✅ Սեղմեք 'Ավարտել' ձևաթուղթը լրացնելու համար։",
            "Շնորհակալ եմ ձեր համագործակցության համար։ 👍 Հիմա կարող եք սեղմել 'Ավարտել' գործընթացը ավարտելու համար։",
            "Դուք շատ օգտակար եք եղել։ 🌟 Պատրաստ լինելու դեպքում սեղմեք 'Ավարտել'։",
            "Հիանալի է, սա հենց այն էր, ինչ մեզ պետք էր։ 👌 Սեղմեք 'Ավարտել' ձեր մասնակցությունը վերջնականացնելու համար։"
        ],
        fallbackMessage: "Մտածում եմ..."
    },
    ro: { // Romanian
        phrases: [
            'Un moment, mă gândesc.',
            'Hmm, dă-mi o secundă.',
            'Interesant, lasă-mă să mă gândesc la asta.',
            'Hmm... Să vedem.',
            'Un moment, mă gândesc.',
            'Lasă-mă să reflectez puțin.',
            'Așteaptă puțin, mă gândesc.',
            'Ah, lasă-mă să mă gândesc la asta.',
            'Lasă-mă să-mi ordonez gândurile.',
            'Ok, procesez punctul tău de vedere.',
            'Așteaptă, îmi organizez gândurile.',
            'Reflectez la cuvintele tale.',
            'Lasă-mă să diger asta un moment.',
            'Gândurile tale m-au pus pe gânduri.'
        ],
        nextButtonPhrases: [
            "Răspuns excelent. 👍 Când ești gata, apasă pe 'Următorul'.",
            "Mulțumesc pentru contribuția ta. ✅ Apasă pe 'Următorul' pentru a continua.",
            "Interesant. 🧐 Când ești gata, apasă pe 'Următorul' pentru a explora o altă întrebare.",
            "Bine făcut! ✅ Apasă pe 'Următorul' când ești gata să continui.",
            "A fost o observație perspicace! 💡 Apasă pe 'Următorul' pentru a trece la următoarea întrebare.",
            "Mulțumesc pentru că ai împărtășit! 😊 Apasă pe 'Următorul' pentru a continua.",
            "Apreciez răspunsul tău. 👏 Apasă pe 'Următorul' când ești gata pentru o altă întrebare.",
            "Mulțumesc! 👌 Când ești gata, apasă pe 'Următorul'."
        ],
        submitAnswerPhrases: [
            "Excelent, mulțumesc pentru contribuția ta. ✅ Apasă pe 'Trimite răspunsul' pentru a finaliza.",
            "Mulțumesc pentru răspunsul detaliat. 😊 Apasă pe 'Trimite răspunsul' pentru a finaliza formularul.",
            "Perfect, am acoperit totul. 🎯 Apasă pe 'Trimite răspunsul' pentru a finaliza.",
            "Mulțumesc pentru cooperarea ta. 👍 Apasă pe 'Trimite răspunsul' pentru a finaliza procesul.",
            "Ai fost foarte de ajutor. 🌟 Apasă pe 'Trimite răspunsul' când ești gata.",
            "Perfect, asta era tot ce aveam nevoie. 👌 Apasă pe 'Trimite răspunsul' pentru a finaliza."
        ],
        finishButtonPhrases: [
            "Perfect, am acoperit totul. 🎯 Apasă pe 'Finalizează' pentru a încheia formularul.",
            "Mulțumesc pentru răspunsurile tale detaliate. 😊 Apasă pe 'Finalizează' pentru a trimite.",
            "Bine făcut pentru a răspunde la toate întrebările. ✅ Apasă pe 'Finalizează' pentru a încheia formularul.",
            "Mulțumesc pentru cooperarea ta. 👍 Apasă pe 'Finalizează' pentru a încheia procesul.",
            "Ai fost foarte de ajutor. 🌟 Apasă pe 'Finalizează' când ești gata.",
            "Perfect, asta era tot ce aveam nevoie. 👌 Apasă pe 'Finalizează' pentru a încheia."
        ],
        fallbackMessage: "Mă gândesc..."
    },
    sr: { // Serbian
        phrases: [
            'Samo trenutak, razmišljam.',
            'Hmm, daj mi sekundu.',
            'Zanimljivo, dozvoli mi da razmislim o tome.',
            'Hmm... Da vidimo.',
            'Samo trenutak dok razmišljam.',
            'Pusti me da malo razmislim.',
            'Samo trenutak, razmišljam.',
            'Ah, dozvoli mi da to razmislim.',
            'Dozvoli mi da organizujem misli.',
            'U redu, obrađujem tvoju misao.',
            'Samo trenutak, organizujem misli.',
            'Razmišljam o tvojim rečima.',
            'Daj mi trenutak da to razmislim.',
            'Tvoja misao me podstakla na razmišljanje.'
        ],
        nextButtonPhrases: [
            "Odličan odgovor. 👍 Kada budeš spreman, klikni na 'Dalje'.",
            "Hvala na tvom doprinosu. ✅ Klikni na 'Dalje' za nastavak.",
            "Zanimljivo. 🧐 Kada budeš spreman, klikni na 'Dalje' za sledeće pitanje.",
            "Dobar posao! ✅ Klikni na 'Dalje' kada budeš spreman.",
            "To je bilo vrlo uvidno! 💡 Klikni na 'Dalje' za sledeće pitanje.",
            "Hvala što si podelio! 😊 Klikni na 'Dalje' za nastavak.",
            "Cenim tvoj odgovor. 👏 Klikni na 'Dalje' kada budeš spreman za sledeće pitanje.",
            "Hvala! 👌 Kada budeš spreman, klikni na 'Dalje'."
        ],
        submitAnswerPhrases: [
            "Odlično, hvala na tvom doprinosu. ✅ Klikni na 'Pošalji odgovor' za završetak.",
            "Hvala na detaljnom odgovoru. 😊 Klikni na 'Pošalji odgovor' za završetak.",
            "Izvrsno, sve smo pokrili. 🎯 Klikni na 'Pošalji odgovor' za završetak.",
            "Hvala na saradnji. 👍 Klikni na 'Pošalji odgovor' za završetak.",
            "Bio si od velike pomoći. 🌟 Klikni na 'Pošalji odgovor' kada budeš spreman.",
            "Savršeno, to je sve što nam treba. 👌 Klikni na 'Pošalji odgovor' za završetak."
        ],
        finishButtonPhrases: [
            "Izvrsno, sve smo pokrili. 🎯 Klikni na 'Završi' za završetak.",
            "Hvala na tvojim detaljnim odgovorima. 😊 Klikni na 'Završi' za slanje.",
            "Dobar posao u odgovaranju na sva pitanja. ✅ Klikni na 'Završi' za završetak.",
            "Hvala na saradnji. 👍 Klikni na 'Završi' za završetak.",
            "Bio si od velike pomoći. 🌟 Klikni na 'Završi' kada budeš spreman.",
            "Savršeno, to je sve što nam treba. 👌 Klikni na 'Završi' za završetak."
        ],
        fallbackMessage: "Razmišljam..."
    },
    cs: { // Czech
        phrases: [
            'Moment, přemýšlím.',
            'Hmm, dej mi vteřinku.',
            'Zajímavé, nech mě o tom přemýšlet.',
            'Hmm... Podívejme se.',
            'Chvilku počkej, přemýšlím.',
            'Nech mě chvíli přemýšlet.',
            'Moment, přemýšlím.',
            'Ah, nech mě o tom popřemýšlet.',
            'Nech mě si srovnat myšlenky.',
            'Dobře, zpracovávám tvůj názor.',
            'Chvilku počkej, rovnám si myšlenky.',
            'Přemýšlím o tvých slovech.',
            'Dej mi chvíli na zpracování.',
            'Tvoje slova mě přiměla k přemýšlení.'
        ],
        nextButtonPhrases: [
            "Skvělá odpověď. 👍 Až budeš připraven, klikni na 'Další'.",
            "Děkuji za tvůj příspěvek. ✅ Klikni na 'Další' pro pokračování.",
            "Zajímavé. 🧐 Až budeš připraven, klikni na 'Další' pro další otázku.",
            "Dobrá práce! ✅ Až budeš připraven, klikni na 'Další' pro pokračování.",
            "To bylo velmi výstižné! 💡 Klikni na 'Další' pro další otázku.",
            "Díky za sdílení! 😊 Klikni na 'Další' pro pokračování.",
            "Oceňuji tvoji odpověď. 👏 Klikni na 'Další' pro další otázku.",
            "Děkuji! 👌 Až budeš připraven, klikni na 'Další'."
        ],
        submitAnswerPhrases: [
            "Skvělé, děkuji za tvůj příspěvek. ✅ Klikni na 'Odeslat odpověď' pro dokončení.",
            "Děkuji za tvou podrobnou odpověď. 😊 Klikni na 'Odeslat odpověď' pro dokončení formuláře.",
            "Perfektní, pokryli jsme všechno. 🎯 Klikni na 'Odeslat odpověď' pro dokončení.",
            "Děkuji za spolupráci. 👍 Klikni na 'Odeslat odpověď' pro dokončení procesu.",
            "Byl jsi velmi nápomocný. 🌟 Klikni na 'Odeslat odpověď', až budeš připraven.",
            "Perfektní, to je vše, co jsme potřebovali. 👌 Klikni na 'Odeslat odpověď' pro dokončení."
        ],
        finishButtonPhrases: [
            "Perfektní, pokryli jsme všechno. 🎯 Klikni na 'Dokončit' pro uzavření formuláře.",
            "Děkuji za tvé podrobné odpovědi. 😊 Klikni na 'Dokončit' pro odeslání.",
            "Dobrá práce, odpověděl jsi na všechny otázky. ✅ Klikni na 'Dokončit' pro uzavření formuláře.",
            "Děkuji za spolupráci. 👍 Klikni na 'Dokončit' pro uzavření procesu.",
            "Byl jsi velmi nápomocný. 🌟 Klikni na 'Dokončit', až budeš připraven.",
            "Perfektní, to je vše, co jsme potřebovali. 👌 Klikni na 'Dokončit' pro uzavření."
        ],
        fallbackMessage: "Přemýšlím..."
    },
    sv: { // Swedish
        phrases: [
            'Ett ögonblick, jag tänker.',
            'Hmm, ge mig en sekund.',
            'Intressant, låt mig fundera på det.',
            'Hmm... Låt oss se.',
            'Ett ögonblick medan jag tänker.',
            'Låt mig sätta på mig tänkarmössan.',
            'Vänta lite, jag tänker.',
            'Ah, låt mig fundera på det.',
            'Låt mig sortera mina tankar.',
            'Okej, jag bearbetar din tanke.',
            'Vänta, jag organiserar mina tankar.',
            'Jag funderar på dina ord.',
            'Låt mig smälta det här en stund.',
            'Dina ord får mig att tänka.'
        ],
        nextButtonPhrases: [
            "Bra svar. 👍 När du är redo, klicka på 'Nästa'.",
            "Tack för ditt bidrag. ✅ Klicka på 'Nästa' för att fortsätta.",
            "Intressant. 🧐 När du är redo, klicka på 'Nästa' för att utforska en annan fråga.",
            "Bra jobbat! ✅ Klicka på 'Nästa' när du är redo att gå vidare.",
            "Det var insiktsfullt! 💡 Klicka på 'Nästa' för nästa fråga.",
            "Tack för att du delade! 😊 Klicka på 'Nästa' för att fortsätta.",
            "Jag uppskattar ditt svar. 👏 Klicka på 'Nästa' när du är redo för en annan fråga.",
            "Tack! 👌 När du är redo, klicka på 'Nästa'."
        ],
        submitAnswerPhrases: [
            "Bra, tack för ditt bidrag. ✅ Klicka på 'Skicka svar' för att slutföra.",
            "Tack för ditt detaljerade svar. 😊 Klicka på 'Skicka svar' för att avsluta formuläret.",
            "Perfekt, vi har täckt allt. 🎯 Klicka på 'Skicka svar' för att slutföra.",
            "Tack för ditt samarbete. 👍 Klicka på 'Skicka svar' för att avsluta processen.",
            "Du har varit mycket hjälpsam. 🌟 Klicka på 'Skicka svar' när du är redo.",
            "Perfekt, det är allt vi behövde. 👌 Klicka på 'Skicka svar' för att slutföra."
        ],
        finishButtonPhrases: [
            "Perfekt, vi har täckt allt. 🎯 Klicka på 'Slutför' för att avsluta formuläret.",
            "Tack för dina detaljerade svar. 😊 Klicka på 'Slutför' för att skicka.",
            "Bra jobbat med att svara på alla frågor. ✅ Klicka på 'Slutför' för att avsluta formuläret.",
            "Tack för ditt samarbete. 👍 Klicka på 'Slutför' för att avsluta processen.",
            "Du har varit mycket hjälpsam. 🌟 Klicka på 'Slutför' när du är redo.",
            "Perfekt, det är allt vi behövde. 👌 Klicka på 'Slutför' för att avsluta."
        ],
        fallbackMessage: "Tänker..."
    },
    
    no: { // Norwegian
        phrases: [
            'Et øyeblikk, jeg tenker.',
            'Hmm, gi meg et sekund.',
            'Interessant, la meg tenke på det.',
            'Hmm... La oss se.',
            'Et øyeblikk mens jeg tenker.',
            'La meg sette på tenkeluen.',
            'Vent litt, jeg tenker.',
            'Ah, la meg tenke på det.',
            'La meg sortere tankene mine.',
            'Ok, jeg behandler tanken din.',
            'Vent, jeg organiserer tankene mine.',
            'Jeg grubler over det du sa.',
            'La meg fordøye dette et øyeblikk.',
            'Dine ord får meg til å tenke.'
        ],
        nextButtonPhrases: [
            "Flott svar. 👍 Når du er klar, klikk på 'Neste'.",
            "Takk for ditt bidrag. ✅ Klikk på 'Neste' for å fortsette.",
            "Interessant. 🧐 Når du er klar, klikk på 'Neste' for å utforske et annet spørsmål.",
            "Bra jobbet! ✅ Klikk på 'Neste' når du er klar til å fortsette.",
            "Det var innsiktsfullt! 💡 Klikk på 'Neste' for neste spørsmål.",
            "Takk for at du delte! 😊 Klikk på 'Neste' for å fortsette.",
            "Jeg setter pris på svaret ditt. 👏 Klikk på 'Neste' når du er klar for et annet spørsmål.",
            "Takk! 👌 Når du er klar, klikk på 'Neste'."
        ],
        submitAnswerPhrases: [
            "Flott, takk for ditt bidrag. ✅ Klikk på 'Send svar' for å fullføre.",
            "Takk for ditt detaljerte svar. 😊 Klikk på 'Send svar' for å fullføre skjemaet.",
            "Perfekt, vi har dekket alt. 🎯 Klikk på 'Send svar' for å fullføre.",
            "Takk for samarbeidet ditt. 👍 Klikk på 'Send svar' for å fullføre prosessen.",
            "Du har vært veldig hjelpsom. 🌟 Klikk på 'Send svar' når du er klar.",
            "Perfekt, det er alt vi trengte. 👌 Klikk på 'Send svar' for å fullføre."
        ],
        finishButtonPhrases: [
            "Perfekt, vi har dekket alt. 🎯 Klikk på 'Fullfør' for å fullføre skjemaet.",
            "Takk for dine detaljerte svar. 😊 Klikk på 'Fullfør' for å sende.",
            "Bra jobbet med å svare på alle spørsmålene. ✅ Klikk på 'Fullfør' for å fullføre skjemaet.",
            "Takk for samarbeidet ditt. 👍 Klikk på 'Fullfør' for å fullføre prosessen.",
            "Du har vært veldig hjelpsom. 🌟 Klikk på 'Fullfør' når du er klar.",
            "Perfekt, det er alt vi trengte. 👌 Klikk på 'Fullfør' for å fullføre."
        ],
        fallbackMessage: "Tenker..."
    },
    
    da: { // Danish
        phrases: [
            'Et øjeblik, jeg tænker.',
            'Hmm, giv mig et sekund.',
            'Interessant, lad mig tænke over det.',
            'Hmm... Lad os se.',
            'Et øjeblik mens jeg tænker.',
            'Lad mig lige tænke over det.',
            'Vent lidt, jeg tænker.',
            'Ah, lad mig tænke over det.',
            'Lad mig organisere mine tanker.',
            'Okay, jeg bearbejder din tanke.',
            'Vent, jeg organiserer mine tanker.',
            'Jeg tænker over dine ord.',
            'Lad mig fordøje dette et øjeblik.',
            'Dine ord får mig til at tænke.'
        ],
        nextButtonPhrases: [
            "Godt svar. 👍 Når du er klar, klik på 'Næste'.",
            "Tak for dit bidrag. ✅ Klik på 'Næste' for at fortsætte.",
            "Interessant. 🧐 Når du er klar, klik på 'Næste' for at udforske et andet spørgsmål.",
            "Godt gået! ✅ Klik på 'Næste', når du er klar til at fortsætte.",
            "Det var indsigtsfuldt! 💡 Klik på 'Næste' for næste spørgsmål.",
            "Tak for at dele! 😊 Klik på 'Næste' for at fortsætte.",
            "Jeg sætter pris på dit svar. 👏 Klik på 'Næste', når du er klar til et andet spørgsmål.",
            "Tak! 👌 Når du er klar, klik på 'Næste'."
        ],
        submitAnswerPhrases: [
            "Fantastisk, tak for dit bidrag. ✅ Klik på 'Indsend svar' for at afslutte.",
            "Tak for dit detaljerede svar. 😊 Klik på 'Indsend svar' for at afslutte formularen.",
            "Perfekt, vi har dækket alt. 🎯 Klik på 'Indsend svar' for at afslutte.",
            "Tak for dit samarbejde. 👍 Klik på 'Indsend svar' for at afslutte processen.",
            "Du har været meget hjælpsom. 🌟 Klik på 'Indsend svar', når du er klar.",
            "Perfekt, det er alt, hvad vi havde brug for. 👌 Klik på 'Indsend svar' for at afslutte."
        ],
        finishButtonPhrases: [
            "Perfekt, vi har dækket alt. 🎯 Klik på 'Afslut' for at afslutte formularen.",
            "Tak for dine detaljerede svar. 😊 Klik på 'Afslut' for at sende.",
            "Godt gået med at besvare alle spørgsmål. ✅ Klik på 'Afslut' for at afslutte formularen.",
            "Tak for dit samarbejde. 👍 Klik på 'Afslut' for at afslutte processen.",
            "Du har været meget hjælpsom. 🌟 Klik på 'Afslut', når du er klar.",
            "Perfekt, det er alt, hvad vi havde brug for. 👌 Klik på 'Afslut' for at afslutte."
        ],
        fallbackMessage: "Tænker..."
    },
    
    hu: { // Hungarian
        phrases: [
            'Egy pillanat, gondolkodom.',
            'Hmm, adj egy másodpercet.',
            'Érdekes, hadd gondolkodjam rajta.',
            'Hmm... Nézzük meg.',
            'Egy pillanat, amíg gondolkodom.',
            'Hadd gondolkodjak egy kicsit.',
            'Várj egy pillanatot, gondolkodom.',
            'Ah, hadd gondolkodjam rajta.',
            'Hadd rendezzem a gondolataimat.',
            'Rendben, feldolgozom a gondolatodat.',
            'Várj, rendezem a gondolataimat.',
            'Gondolkodom a szavaidon.',
            'Hadd emésszem meg ezt egy pillanatra.',
            'A szavaid gondolkodóba ejtettek.'
        ],
        nextButtonPhrases: [
            "Remek válasz. 👍 Amikor készen állsz, kattints a 'Következő' gombra.",
            "Köszönöm a hozzájárulásodat. ✅ Kattints a 'Következő' gombra a folytatáshoz.",
            "Érdekes. 🧐 Amikor készen állsz, kattints a 'Következő' gombra egy másik kérdés felfedezéséhez.",
            "Jól sikerült! ✅ Kattints a 'Következő' gombra, amikor készen állsz a folytatásra.",
            "Ez nagyon betekintő volt! 💡 Kattints a 'Következő' gombra a következő kérdéshez.",
            "Köszönöm, hogy megosztottad! 😊 Kattints a 'Következő' gombra a folytatáshoz.",
            "Értékelem a válaszodat. 👏 Kattints a 'Következő' gombra, amikor készen állsz egy másik kérdésre.",
            "Köszönöm! 👌 Amikor készen állsz, kattints a 'Következő' gombra."
        ],
        submitAnswerPhrases: [
            "Nagyszerű, köszönöm a hozzájárulásodat. ✅ Kattints a 'Válasz elküldése' gombra a befejezéshez.",
            "Köszönöm a részletes válaszodat. 😊 Kattints a 'Válasz elküldése' gombra a kérdőív lezárásához.",
            "Tökéletes, mindent lefedtünk. 🎯 Kattints a 'Válasz elküldése' gombra a befejezéshez.",
            "Köszönöm az együttműködésedet. 👍 Kattints a 'Válasz elküldése' gombra a folyamat lezárásához.",
            "Nagyon hasznos voltál. 🌟 Kattints a 'Válasz elküldése' gombra, amikor készen állsz.",
            "Tökéletes, pont erre volt szükségünk. 👌 Kattints a 'Válasz elküldése' gombra a befejezéshez."
        ],
        finishButtonPhrases: [
            "Tökéletes, mindent lefedtünk. 🎯 Kattints a 'Befejezés' gombra a kérdőív lezárásához.",
            "Köszönöm a részletes válaszaidat. 😊 Kattints a 'Befejezés' gombra az elküldéshez.",
            "Jól sikerült válaszolni minden kérdésre. ✅ Kattints a 'Befejezés' gombra a kérdőív lezárásához.",
            "Köszönöm az együttműködésedet. 👍 Kattints a 'Befejezés' gombra a folyamat lezárásához.",
            "Nagyon hasznos voltál. 🌟 Kattints a 'Befejezés' gombra, amikor készen állsz.",
            "Tökéletes, pont erre volt szükségünk. 👌 Kattints a 'Befejezés' gombra a befejezéshez."
        ],
        fallbackMessage: "Gondolkodom..."
    },
    sk: { // Slovak
        phrases: [
            'Chvíľu počkaj, premýšľam.',
            'Hmm, daj mi sekundu.',
            'Zaujímavé, nechaj ma nad tým popremýšľať.',
            'Hmm... Pozrime sa.',
            'Chvíľu počkaj, premýšľam.',
            'Nechaj ma chvíľu premýšľať.',
            'Počkaj chvíľu, premýšľam.',
            'Ah, nechaj ma nad tým premýšľať.',
            'Nechaj ma usporiadať si myšlienky.',
            'Dobre, spracovávam tvoju myšlienku.',
            'Počkaj chvíľu, organizujem si myšlienky.',
            'Premýšľam o tvojich slovách.',
            'Daj mi chvíľu na strávenie.',
            'Tvoje slová ma nútia premýšľať.'
        ],
        nextButtonPhrases: [
            "Skvelá odpoveď. 👍 Keď budeš pripravený, klikni na 'Ďalej'.",
            "Ďakujem za tvoj príspevok. ✅ Klikni na 'Ďalej' pre pokračovanie.",
            "Zaujímavé. 🧐 Keď budeš pripravený, klikni na 'Ďalej' pre ďalšiu otázku.",
            "Dobrý výkon! ✅ Klikni na 'Ďalej', keď budeš pripravený pokračovať.",
            "To bolo veľmi výstižné! 💡 Klikni na 'Ďalej' pre ďalšiu otázku.",
            "Ďakujem, že si sa podelil! 😊 Klikni na 'Ďalej' pre pokračovanie.",
            "Oceňujem tvoju odpoveď. 👏 Klikni na 'Ďalej', keď budeš pripravený na ďalšiu otázku.",
            "Ďakujem! 👌 Keď budeš pripravený, klikni na 'Ďalej'."
        ],
        submitAnswerPhrases: [
            "Skvelé, ďakujem za tvoj príspevok. ✅ Klikni na 'Odoslať odpoveď' pre dokončenie.",
            "Ďakujem za tvoju podrobnú odpoveď. 😊 Klikni na 'Odoslať odpoveď' pre dokončenie formulára.",
            "Perfektné, všetko sme pokryli. 🎯 Klikni na 'Odoslať odpoveď' pre dokončenie.",
            "Ďakujem za spoluprácu. 👍 Klikni na 'Odoslať odpoveď' pre dokončenie procesu.",
            "Bol si veľmi nápomocný. 🌟 Klikni na 'Odoslať odpoveď', keď budeš pripravený.",
            "Perfektné, to je všetko, čo sme potrebovali. 👌 Klikni na 'Odoslať odpoveď' pre dokončenie."
        ],
        finishButtonPhrases: [
            "Perfektné, všetko sme pokryli. 🎯 Klikni na 'Dokončiť' pre uzavretie formulára.",
            "Ďakujem za tvoje podrobné odpovede. 😊 Klikni na 'Dokončiť' pre odoslanie.",
            "Dobrý výkon v odpovedaní na všetky otázky. ✅ Klikni na 'Dokončiť' pre uzavretie formulára.",
            "Ďakujem za spoluprácu. 👍 Klikni na 'Dokončiť' pre uzavretie procesu.",
            "Bol si veľmi nápomocný. 🌟 Klikni na 'Dokončiť', keď budeš pripravený.",
            "Perfektné, to je všetko, čo sme potrebovali. 👌 Klikni na 'Dokončiť' pre uzavretie."
        ],
        fallbackMessage: "Premýšľam..."
    },
    
    sl: { // Slovenian
        phrases: [
            'Samo trenutek, razmišljam.',
            'Hmm, daj mi sekundo.',
            'Zanimivo, naj razmislim o tem.',
            'Hmm... Poglejmo.',
            'Samo trenutek, da razmislim.',
            'Naj malo premislim.',
            'Samo trenutek, razmišljam.',
            'Ah, naj o tem razmislim.',
            'Naj uredim svoje misli.',
            'V redu, obdelujem tvojo misel.',
            'Samo trenutek, urejam svoje misli.',
            'Razmišljam o tvojih besedah.',
            'Daj mi trenutek, da to premislim.',
            'Tvoje besede so me spravile v razmišljanje.'
        ],
        nextButtonPhrases: [
            "Odličen odgovor. 👍 Ko boš pripravljen, klikni na 'Naprej'.",
            "Hvala za tvoj prispevek. ✅ Klikni na 'Naprej' za nadaljevanje.",
            "Zanimivo. 🧐 Ko boš pripravljen, klikni na 'Naprej' za naslednje vprašanje.",
            "Dobro opravljeno! ✅ Klikni na 'Naprej', ko boš pripravljen nadaljevati.",
            "To je bilo zelo pronicljivo! 💡 Klikni na 'Naprej' za naslednje vprašanje.",
            "Hvala, da si delil! 😊 Klikni na 'Naprej' za nadaljevanje.",
            "Cenim tvoj odgovor. 👏 Klikni na 'Naprej', ko boš pripravljen na naslednje vprašanje.",
            "Hvala! 👌 Ko boš pripravljen, klikni na 'Naprej'."
        ],
        submitAnswerPhrases: [
            "Odlično, hvala za tvoj prispevek. ✅ Klikni na 'Pošlji odgovor' za dokončanje.",
            "Hvala za tvoj podroben odgovor. 😊 Klikni na 'Pošlji odgovor' za dokončanje obrazca.",
            "Popolno, pokrili smo vse. 🎯 Klikni na 'Pošlji odgovor' za dokončanje.",
            "Hvala za tvoje sodelovanje. 👍 Klikni na 'Pošlji odgovor' za dokončanje postopka.",
            "Bil si zelo koristen. 🌟 Klikni na 'Pošlji odgovor', ko boš pripravljen.",
            "Popolno, to je vse, kar smo potrebovali. 👌 Klikni na 'Pošlji odgovor' za dokončanje."
        ],
        finishButtonPhrases: [
            "Popolno, pokrili smo vse. 🎯 Klikni na 'Dokončaj' za zaključek obrazca.",
            "Hvala za tvoje podrobne odgovore. 😊 Klikni na 'Dokončaj' za pošiljanje.",
            "Dobro opravljeno pri odgovorih na vsa vprašanja. ✅ Klikni na 'Dokončaj' za zaključek obrazca.",
            "Hvala za tvoje sodelovanje. 👍 Klikni na 'Dokončaj' za zaključek postopka.",
            "Bil si zelo koristen. 🌟 Klikni na 'Dokončaj', ko boš pripravljen.",
            "Popolno, to je vse, kar smo potrebovali. 👌 Klikni na 'Dokončaj' za zaključek."
        ],
        fallbackMessage: "Razmišljam..."
    },
    
    mk: { // Macedonian
        phrases: [
            'Само момент, размислувам.',
            'Хм, дај ми секунда.',
            'Интересно, нека размислам за тоа.',
            'Хм... Да видиме.',
            'Само момент додека размислувам.',
            'Нека размислам малку.',
            'Само момент, размислувам.',
            'Ах, нека размислам за тоа.',
            'Нека ги средам мислите.',
            'Добро, го обработувам твојот став.',
            'Само момент, ги организирам мислите.',
            'Размислувам за твоите зборови.',
            'Дозволи ми момент да го процесирам.',
            'Твоите зборови ме тераат да размислувам.'
        ],
        nextButtonPhrases: [
            "Одличен одговор. 👍 Кога ќе бидеш подготвен, кликни на 'Следно'.",
            "Ти благодарам за твојот придонес. ✅ Кликни на 'Следно' за продолжување.",
            "Интересно. 🧐 Кога ќе бидеш подготвен, кликни на 'Следно' за следното прашање.",
            "Добра работа! ✅ Кликни на 'Следно' кога ќе бидеш подготвен да продолжиш.",
            "Тоа беше многу длабоко! 💡 Кликни на 'Следно' за следното прашање.",
            "Благодарам што сподели! 😊 Кликни на 'Следно' за продолжување.",
            "Ја ценам твојата одговорност. 👏 Кликни на 'Следно' кога ќе бидеш подготвен за следното прашање.",
            "Благодарам! 👌 Кога ќе бидеш подготвен, кликни на 'Следно'."
        ],
        submitAnswerPhrases: [
            "Одлично, благодарам за твојот придонес. ✅ Кликни на 'Поднеси одговор' за завршување.",
            "Благодарам за твојот детален одговор. 😊 Кликни на 'Поднеси одговор' за завршување на формуларот.",
            "Совршено, покривме сè. 🎯 Кликни на 'Поднеси одговор' за завршување.",
            "Благодарам за твојата соработка. 👍 Кликни на 'Поднеси одговор' за завршување на процесот.",
            "Многу ми помогна. 🌟 Кликни на 'Поднеси одговор' кога ќе бидеш подготвен.",
            "Совршено, тоа е сè што ни требаше. 👌 Кликни на 'Поднеси одговор' за завршување."
        ],
        finishButtonPhrases: [
            "Совршено, покривме сè. 🎯 Кликни на 'Заврши' за завршување на формуларот.",
            "Благодарам за твоите детални одговори. 😊 Кликни на 'Заврши' за испраќање.",
            "Добра работа што одговори на сите прашања. ✅ Кликни на 'Заврши' за завршување на формуларот.",
            "Благодарам за твојата соработка. 👍 Кликни на 'Заврши' за завршување на процесот.",
            "Многу ми помогна. 🌟 Кликни на 'Заврши' кога ќе бидеш подготвен.",
            "Совршено, тоа е сè што ни требаше. 👌 Кликни на 'Заврши' за завршување."
        ],
        fallbackMessage: "Размислувам..."
    },
    
    lv: { // Latvian
        phrases: [
            'Uzgaidi, es domāju.',
            'Hmm, dod man sekundi.',
            'Interesanti, ļauj man par to padomāt.',
            'Hmm... Paskatīsimies.',
            'Uzgaidi mazliet, kamēr es domāju.',
            'Ļauj man mazliet padomāt.',
            'Uzgaidi, es domāju.',
            'Ah, ļauj man par to padomāt.',
            'Ļauj man sakārtot savas domas.',
            'Labi, es apstrādāju tavu ideju.',
            'Uzgaidi, es sakārtoju savas domas.',
            'Es domāju par taviem vārdiem.',
            'Ļauj man to apdomāt brīdi.',
            'Tavi vārdi liek man domāt.'
        ],
        nextButtonPhrases: [
            "Lieliska atbilde. 👍 Kad būsi gatavs, noklikšķini uz 'Tālāk'.",
            "Paldies par tavu ieguldījumu. ✅ Noklikšķini uz 'Tālāk', lai turpinātu.",
            "Interesanti. 🧐 Kad būsi gatavs, noklikšķini uz 'Tālāk', lai izpētītu citu jautājumu.",
            "Labi padarīts! ✅ Noklikšķini uz 'Tālāk', kad būsi gatavs turpināt.",
            "Tas bija ļoti pārdomāti! 💡 Noklikšķini uz 'Tālāk', lai pārietu uz nākamo jautājumu.",
            "Paldies, ka dalījies! 😊 Noklikšķini uz 'Tālāk', lai turpinātu.",
            "Es novērtēju tavu atbildi. 👏 Noklikšķini uz 'Tālāk', kad būsi gatavs uz citu jautājumu.",
            "Paldies! 👌 Kad būsi gatavs, noklikšķini uz 'Tālāk'."
        ],
        submitAnswerPhrases: [
            "Lieliski, paldies par tavu ieguldījumu. ✅ Noklikšķini uz 'Iesniegt atbildi', lai pabeigtu.",
            "Paldies par detalizēto atbildi. 😊 Noklikšķini uz 'Iesniegt atbildi', lai pabeigtu veidlapu.",
            "Perfekti, esam aptvēruši visu. 🎯 Noklikšķini uz 'Iesniegt atbildi', lai pabeigtu.",
            "Paldies par sadarbību. 👍 Noklikšķini uz 'Iesniegt atbildi', lai pabeigtu procesu.",
            "Tu biji ļoti noderīgs. 🌟 Noklikšķini uz 'Iesniegt atbildi', kad būsi gatavs.",
            "Perfekti, tas ir viss, kas mums bija nepieciešams. 👌 Noklikšķini uz 'Iesniegt atbildi', lai pabeigtu."
        ],
        finishButtonPhrases: [
            "Perfekti, esam aptvēruši visu. 🎯 Noklikšķini uz 'Pabeigt', lai pabeigtu veidlapu.",
            "Paldies par detalizētajām atbildēm. 😊 Noklikšķini uz 'Pabeigt', lai iesniegtu.",
            "Labi padarīts, atbildot uz visiem jautājumiem. ✅ Noklikšķini uz 'Pabeigt', lai pabeigtu veidlapu.",
            "Paldies par sadarbību. 👍 Noklikšķini uz 'Pabeigt', lai pabeigtu procesu.",
            "Tu biji ļoti noderīgs. 🌟 Noklikšķini uz 'Pabeigt', kad būsi gatavs.",
            "Perfekti, tas ir viss, kas mums bija nepieciešams. 👌 Noklikšķini uz 'Pabeigt', lai pabeigtu."
        ],
        fallbackMessage: "Domāju..."
    },
    
    lt: { // Lithuanian
        phrases: [
            'Palaukite, galvoju.',
            'Hmm, duokite man sekundę.',
            'Įdomu, leiskite pagalvoti apie tai.',
            'Hmm... Pažiūrėkime.',
            'Palaukite, kol galvoju.',
            'Leiskite man šiek tiek pagalvoti.',
            'Palaukite, galvoju.',
            'Ah, leiskite man apie tai pagalvoti.',
            'Leiskite man susitvarkyti mintis.',
            'Gerai, apdoroju jūsų mintį.',
            'Palaukite, organizuoju savo mintis.',
            'Galvoju apie jūsų žodžius.',
            'Leiskite man tai apgalvoti.',
            'Jūsų žodžiai verčia mane galvoti.'
        ],
        nextButtonPhrases: [
            "Puikus atsakymas. 👍 Kai būsite pasiruošę, spustelėkite 'Kitas'.",
            "Ačiū už jūsų indėlį. ✅ Spustelėkite 'Kitas', kad tęstumėte.",
            "Įdomu. 🧐 Kai būsite pasiruošę, spustelėkite 'Kitas', kad tyrinėtumėte kitą klausimą.",
            "Gerai padirbėta! ✅ Spustelėkite 'Kitas', kai būsite pasiruošę tęsti.",
            "Tai buvo labai įžvalgu! 💡 Spustelėkite 'Kitas', kad pereitumėte prie kito klausimo.",
            "Ačiū, kad pasidalijote! 😊 Spustelėkite 'Kitas', kad tęstumėte.",
            "Vertinu jūsų atsakymą. 👏 Spustelėkite 'Kitas', kai būsite pasiruošę kitam klausimui.",
            "Ačiū! 👌 Kai būsite pasiruošę, spustelėkite 'Kitas'."
        ],
        submitAnswerPhrases: [
            "Puiku, ačiū už jūsų indėlį. ✅ Spustelėkite 'Pateikti atsakymą', kad užbaigtumėte.",
            "Ačiū už išsamų atsakymą. 😊 Spustelėkite 'Pateikti atsakymą', kad užbaigtumėte formą.",
            "Puiku, viską aptarėme. 🎯 Spustelėkite 'Pateikti atsakymą', kad užbaigtumėte.",
            "Ačiū už jūsų bendradarbiavimą. 👍 Spustelėkite 'Pateikti atsakymą', kad užbaigtumėte procesą.",
            "Jūs buvote labai naudingi. 🌟 Spustelėkite 'Pateikti atsakymą', kai būsite pasiruošę.",
            "Puiku, tai viskas, ko mums reikėjo. 👌 Spustelėkite 'Pateikti atsakymą', kad užbaigtumėte."
        ],
        finishButtonPhrases: [
            "Puiku, viską aptarėme. 🎯 Spustelėkite 'Baigti', kad užbaigtumėte formą.",
            "Ačiū už išsamius atsakymus. 😊 Spustelėkite 'Baigti', kad pateiktumėte.",
            "Gerai padirbėta, atsakant į visus klausimus. ✅ Spustelėkite 'Baigti', kad užbaigtumėte formą.",
            "Ačiū už jūsų bendradarbiavimą. 👍 Spustelėkite 'Baigti', kad užbaigtumėte procesą.",
            "Jūs buvote labai naudingi. 🌟 Spustelėkite 'Baigti', kai būsite pasiruošę.",
            "Puiku, tai viskas, ko mums reikėjo. 👌 Spustelėkite 'Baigti', kad užbaigtumėte."
        ],
        fallbackMessage: "Galvoju..."
    },
    
    et: { // Estonian
        phrases: [
            'Oota hetk, ma mõtlen.',
            'Hmm, anna mulle hetk.',
            'Huvitav, las ma mõtlen selle üle.',
            'Hmm... Vaatame.',
            'Oota hetk, ma mõtlen.',
            'Lase mul natuke mõelda.',
            'Oota, ma mõtlen.',
            'Ah, lase mul selle üle mõelda.',
            'Lase mul oma mõtteid korrastada.',
            'Okei, ma töötlen sinu mõtet.',
            'Oota hetk, ma korrastan oma mõtteid.',
            'Mõtlen sinu sõnade peale.',
            'Lase mul see hetk seedida.',
            'Sinu sõnad panevad mind mõtlema.'
        ],
        nextButtonPhrases: [
            "Suurepärane vastus. 👍 Kui oled valmis, klõpsa 'Järgmine'.",
            "Aitäh sinu panuse eest. ✅ Klõpsa 'Järgmine', et jätkata.",
            "Huvitav. 🧐 Kui oled valmis, klõpsa 'Järgmine', et uurida teist küsimust.",
            "Hästi tehtud! ✅ Klõpsa 'Järgmine', kui oled valmis jätkama.",
            "See oli väga läbimõeldud! 💡 Klõpsa 'Järgmine', et liikuda järgmise küsimuse juurde.",
            "Aitäh jagamast! 😊 Klõpsa 'Järgmine', et jätkata.",
            "Hindan sinu vastust. 👏 Klõpsa 'Järgmine', kui oled valmis teiseks küsimuseks.",
            "Aitäh! 👌 Kui oled valmis, klõpsa 'Järgmine'."
        ],
        submitAnswerPhrases: [
            "Suurepärane, aitäh sinu panuse eest. ✅ Klõpsa 'Esita vastus', et lõpetada.",
            "Aitäh sinu üksikasjaliku vastuse eest. 😊 Klõpsa 'Esita vastus', et lõpetada vorm.",
            "Täiuslik, oleme kõik katnud. 🎯 Klõpsa 'Esita vastus', et lõpetada.",
            "Aitäh koostöö eest. 👍 Klõpsa 'Esita vastus', et lõpetada protsess.",
            "Sa olid väga abivalmis. 🌟 Klõpsa 'Esita vastus', kui oled valmis.",
            "Täiuslik, see on kõik, mida me vajasime. 👌 Klõpsa 'Esita vastus', et lõpetada."
        ],
        finishButtonPhrases: [
            "Täiuslik, oleme kõik katnud. 🎯 Klõpsa 'Lõpeta', et lõpetada vorm.",
            "Aitäh sinu üksikasjalike vastuste eest. 😊 Klõpsa 'Lõpeta', et esitada.",
            "Hästi tehtud, vastasid kõikidele küsimustele. ✅ Klõpsa 'Lõpeta', et lõpetada vorm.",
            "Aitäh koostöö eest. 👍 Klõpsa 'Lõpeta', et lõpetada protsess.",
            "Sa olid väga abivalmis. 🌟 Klõpsa 'Lõpeta', kui oled valmis.",
            "Täiuslik, see on kõik, mida me vajasime. 👌 Klõpsa 'Lõpeta', et lõpetada."
        ],
        fallbackMessage: "Mõtlen..."
    },
    
    af: { // Afrikaans
        phrases: [
            'Net ’n oomblik, ek dink.',
            'Hmm, gee my ’n sekonde.',
            'Interessant, laat my daaroor dink.',
            'Hmm... Laat ons sien.',
            'Net ’n oomblik terwyl ek dink.',
            'Laat my net ’n bietjie dink.',
            'Wag ’n bietjie, ek dink.',
            'Ah, laat my daaroor dink.',
            'Laat my my gedagtes orden.',
            'Goed, ek verwerk jou gedagte.',
            'Wag, ek organiseer my gedagtes.',
            'Ek dink oor jou woorde.',
            'Laat my dit ’n oomblik verteer.',
            'Jou woorde laat my dink.'
        ],
        nextButtonPhrases: [
            "Goeie antwoord. 👍 Wanneer jy gereed is, klik op 'Volgende'.",
            "Dankie vir jou bydrae. ✅ Klik op 'Volgende' om voort te gaan.",
            "Interessant. 🧐 Wanneer jy gereed is, klik op 'Volgende' om nog ’n vraag te verken.",
            "Goeie werk! ✅ Klik op 'Volgende' wanneer jy gereed is om voort te gaan.",
            "Dit was insiggewend! 💡 Klik op 'Volgende' vir die volgende vraag.",
            "Dankie dat jy gedeel het! 😊 Klik op 'Volgende' om voort te gaan.",
            "Ek waardeer jou antwoord. 👏 Klik op 'Volgende' wanneer jy gereed is vir nog ’n vraag.",
            "Dankie! 👌 Wanneer jy gereed is, klik op 'Volgende'."
        ],
        submitAnswerPhrases: [
            "Groot, dankie vir jou bydrae. ✅ Klik op 'Dien antwoord in' om af te handel.",
            "Dankie vir jou gedetailleerde antwoord. 😊 Klik op 'Dien antwoord in' om die vorm te voltooi.",
            "Perfek, ons het alles behandel. 🎯 Klik op 'Dien antwoord in' om af te handel.",
            "Dankie vir jou samewerking. 👍 Klik op 'Dien antwoord in' om die proses af te handel.",
            "Jy was baie behulpsaam. 🌟 Klik op 'Dien antwoord in' wanneer jy gereed is.",
            "Perfek, dit is alles wat ons nodig gehad het. 👌 Klik op 'Dien antwoord in' om af te handel."
        ],
        finishButtonPhrases: [
            "Perfek, ons het alles behandel. 🎯 Klik op 'Voltooi' om die vorm te voltooi.",
            "Dankie vir jou gedetailleerde antwoorde. 😊 Klik op 'Voltooi' om in te dien.",
            "Goeie werk met al die vrae beantwoord. ✅ Klik op 'Voltooi' om die vorm te voltooi.",
            "Dankie vir jou samewerking. 👍 Klik op 'Voltooi' om die proses af te handel.",
            "Jy was baie behulpsaam. 🌟 Klik op 'Voltooi' wanneer jy gereed is.",
            "Perfek, dit is alles wat ons nodig gehad het. 👌 Klik op 'Voltooi' om af te handel."
        ],
        fallbackMessage: "Dink..."
    },
    
    gl: { // Galician
        phrases: [
            'Un momento, estou pensando.',
            'Hmm, dáme un segundo.',
            'Interesante, déixame pensar niso.',
            'Hmm... Imos ver.',
            'Un momento mentres penso.',
            'Déixame pensar un pouco.',
            'Espera un pouco, estou pensando.',
            'Ah, déixame pensar niso.',
            'Déixame organizar os meus pensamentos.',
            'Vale, estou procesando a túa idea.',
            'Espera, estou organizando os meus pensamentos.',
            'Estou reflexionando sobre as túas palabras.',
            'Déixame dixerir isto un momento.',
            'As túas palabras fannos pensar.'
        ],
        nextButtonPhrases: [
            "Boa resposta. 👍 Cando esteas preparado, fai clic en 'Seguinte'.",
            "Grazas pola túa achega. ✅ Fai clic en 'Seguinte' para continuar.",
            "Interesante. 🧐 Cando esteas preparado, fai clic en 'Seguinte' para explorar outra pregunta.",
            "Ben feito! ✅ Fai clic en 'Seguinte' cando esteas preparado para continuar.",
            "Foi unha observación perspicaz! 💡 Fai clic en 'Seguinte' para a seguinte pregunta.",
            "Grazas por compartir! 😊 Fai clic en 'Seguinte' para continuar.",
            "Aprecio a túa resposta. 👏 Fai clic en 'Seguinte' cando esteas preparado para outra pregunta.",
            "Grazas! 👌 Cando esteas preparado, fai clic en 'Seguinte'."
        ],
        submitAnswerPhrases: [
            "Excelente, grazas pola túa achega. ✅ Fai clic en 'Enviar resposta' para finalizar.",
            "Grazas pola túa resposta detallada. 😊 Fai clic en 'Enviar resposta' para completar o formulario.",
            "Perfecto, cubrimos todo. 🎯 Fai clic en 'Enviar resposta' para finalizar.",
            "Grazas pola túa colaboración. 👍 Fai clic en 'Enviar resposta' para completar o proceso.",
            "Fuches moi útil. 🌟 Fai clic en 'Enviar resposta' cando esteas preparado.",
            "Perfecto, isto é todo o que necesitabamos. 👌 Fai clic en 'Enviar resposta' para finalizar."
        ],
        finishButtonPhrases: [
            "Perfecto, cubrimos todo. 🎯 Fai clic en 'Rematar' para completar o formulario.",
            "Grazas polas túas respostas detalladas. 😊 Fai clic en 'Rematar' para enviar.",
            "Ben feito ao responder a todas as preguntas. ✅ Fai clic en 'Rematar' para completar o formulario.",
            "Grazas pola túa colaboración. 👍 Fai clic en 'Rematar' para completar o proceso.",
            "Fuches moi útil. 🌟 Fai clic en 'Rematar' cando esteas preparado.",
            "Perfecto, isto é todo o que necesitabamos. 👌 Fai clic en 'Rematar' para completar."
        ],
        fallbackMessage: "Pensando..."
    },
    
    cy: { // Welsh
        phrases: [
            'Eiliad, dw i’n meddwl.',
            'Hmm, rhoi eiliad i mi.',
            'Diddorol, gad i mi feddwl am hyn.',
            'Hmm... Gad i ni weld.',
            'Eiliad, dw i’n meddwl am hyn.',
            'Gad i mi feddwl ychydig.',
            'Arhoswch, dw i’n meddwl.',
            'Ah, gad i mi feddwl am hyn.',
            'Gad i mi drefnu fy meddyliau.',
            'Iawn, dw i’n prosesu dy syniad.',
            'Arhoswch, dw i’n trefnu fy meddyliau.',
            'Dw i’n meddwl am dy eiriau.',
            'Rho ychydig o amser i mi dderbyn hyn.',
            'Dy eiriau’n gwneud i mi feddwl.'
        ],
        nextButtonPhrases: [
            "Ateb da. 👍 Pan fyddwch chi’n barod, cliciwch ar 'Nesaf'.",
            "Diolch am eich cyfraniad. ✅ Cliciwch ar 'Nesaf' i barhau.",
            "Diddorol. 🧐 Pan fyddwch chi’n barod, cliciwch ar 'Nesaf' i archwilio cwestiwn arall.",
            "Da iawn! ✅ Cliciwch ar 'Nesaf' pan fyddwch chi’n barod i barhau.",
            "Roedd hyn yn ddadleuol! 💡 Cliciwch ar 'Nesaf' ar gyfer y cwestiwn nesaf.",
            "Diolch am rannu! 😊 Cliciwch ar 'Nesaf' i barhau.",
            "Dw i’n gwerthfawrogi eich ateb. 👏 Cliciwch ar 'Nesaf' pan fyddwch chi’n barod ar gyfer cwestiwn arall.",
            "Diolch! 👌 Pan fyddwch chi’n barod, cliciwch ar 'Nesaf'."
        ],
        submitAnswerPhrases: [
            "Ardderchog, diolch am eich cyfraniad. ✅ Cliciwch ar 'Cyflwyno ateb' i orffen.",
            "Diolch am eich ateb manwl. 😊 Cliciwch ar 'Cyflwyno ateb' i gwblhau’r ffurflen.",
            "Perffaith, rydym wedi trafod popeth. 🎯 Cliciwch ar 'Cyflwyno ateb' i orffen.",
            "Diolch am eich cydweithrediad. 👍 Cliciwch ar 'Cyflwyno ateb' i gwblhau’r broses.",
            "Rydych wedi bod yn help mawr. 🌟 Cliciwch ar 'Cyflwyno ateb' pan fyddwch chi’n barod.",
            "Perffaith, dyna’r cyfan oedd ei angen arnom. 👌 Cliciwch ar 'Cyflwyno ateb' i orffen."
        ],
        finishButtonPhrases: [
            "Perffaith, rydym wedi trafod popeth. 🎯 Cliciwch ar 'Gorffen' i gwblhau’r ffurflen.",
            "Diolch am eich atebion manwl. 😊 Cliciwch ar 'Gorffen' i gyflwyno.",
            "Da iawn am ateb yr holl gwestiynau. ✅ Cliciwch ar 'Gorffen' i gwblhau’r ffurflen.",
            "Diolch am eich cydweithrediad. 👍 Cliciwch ar 'Gorffen' i gwblhau’r broses.",
            "Rydych wedi bod yn help mawr. 🌟 Cliciwch ar 'Gorffen' pan fyddwch chi’n barod.",
            "Perffaith, dyna’r cyfan oedd ei angen arnom. 👌 Cliciwch ar 'Gorffen' i gwblhau."
        ],
        fallbackMessage: "Meddwl..."
    },
    
    is: { // Icelandic
        phrases: [
            'Eitt augnablik, ég er að hugsa.',
            'Hmm, gefðu mér sekúndu.',
            'Áhugavert, leyfðu mér að hugsa um þetta.',
            'Hmm... Við skulum sjá.',
            'Bíddu augnablik meðan ég hugsa.',
            'Leyfðu mér að hugsa um þetta.',
            'Bíddu aðeins, ég er að hugsa.',
            'Ah, leyfðu mér að hugsa um þetta.',
            'Leyfðu mér að raða hugsunum mínum.',
            'Allt í lagi, ég er að vinna úr hugmyndinni þinni.',
            'Bíddu aðeins, ég er að raða hugsunum mínum.',
            'Ég er að íhuga orð þín.',
            'Leyfðu mér að melta þetta augnablik.',
            'Orð þín láta mig hugsa.'
        ],
        nextButtonPhrases: [
            "Frábær svar. 👍 Þegar þú ert tilbúinn, smelltu á 'Næsta'.",
            "Þakka þér fyrir framlag þitt. ✅ Smelltu á 'Næsta' til að halda áfram.",
            "Áhugavert. 🧐 Þegar þú ert tilbúinn, smelltu á 'Næsta' til að skoða aðra spurningu.",
            "Vel gert! ✅ Smelltu á 'Næsta' þegar þú ert tilbúinn að halda áfram.",
            "Þetta var mjög innsæi! 💡 Smelltu á 'Næsta' fyrir næstu spurningu.",
            "Þakka þér fyrir að deila! 😊 Smelltu á 'Næsta' til að halda áfram.",
            "Ég þakka svar þitt. 👏 Smelltu á 'Næsta' þegar þú ert tilbúinn fyrir aðra spurningu.",
            "Þakka þér! 👌 Þegar þú ert tilbúinn, smelltu á 'Næsta'."
        ],
        submitAnswerPhrases: [
            "Frábært, þakka þér fyrir framlag þitt. ✅ Smelltu á 'Senda svar' til að klára.",
            "Þakka þér fyrir ítarlegt svar þitt. 😊 Smelltu á 'Senda svar' til að ljúka eyðublaðinu.",
            "Fullkomið, við höfum fjallað um allt. 🎯 Smelltu á 'Senda svar' til að klára.",
            "Þakka þér fyrir samstarf þitt. 👍 Smelltu á 'Senda svar' til að ljúka ferlinu.",
            "Þú varst mjög hjálpsamur. 🌟 Smelltu á 'Senda svar' þegar þú ert tilbúinn.",
            "Fullkomið, þetta er allt sem við þurftum. 👌 Smelltu á 'Senda svar' til að klára."
        ],
        finishButtonPhrases: [
            "Fullkomið, við höfum fjallað um allt. 🎯 Smelltu á 'Ljúka' til að ljúka eyðublaðinu.",
            "Þakka þér fyrir ítarlegar svör þín. 😊 Smelltu á 'Ljúka' til að senda.",
            "Vel gert við að svara öllum spurningum. ✅ Smelltu á 'Ljúka' til að ljúka eyðublaðinu.",
            "Þakka þér fyrir samstarf þitt. 👍 Smelltu á 'Ljúka' til að ljúka ferlinu.",
            "Þú varst mjög hjálpsamur. 🌟 Smelltu á 'Ljúka' þegar þú ert tilbúinn.",
            "Fullkomið, þetta er allt sem við þurftum. 👌 Smelltu á 'Ljúka' til að klára."
        ],
        fallbackMessage: "Hugsa..."
    },
    
    fi: { // Finnish
        phrases: [
            'Odota hetki, ajattelen.',
            'Hmm, anna minulle hetki.',
            'Mielenkiintoista, anna minun miettiä tätä.',
            'Hmm... Katsotaanpa.',
            'Odota hetki, ajattelen.',
            'Anna minun miettiä hetki.',
            'Odota hetki, ajattelen.',
            'Ah, anna minun miettiä tätä.',
            'Anna minun järjestää ajatukseni.',
            'Okei, käsittelen ajatustasi.',
            'Odota hetki, järjestän ajatukseni.',
            'Mietin sanojasi.',
            'Anna minun sulattaa tämä hetken.',
            'Sanasi saavat minut ajattelemaan.'
        ],
        nextButtonPhrases: [
            "Hyvä vastaus. 👍 Kun olet valmis, napsauta 'Seuraava'.",
            "Kiitos panoksestasi. ✅ Napsauta 'Seuraava' jatkaaksesi.",
            "Mielenkiintoista. 🧐 Kun olet valmis, napsauta 'Seuraava' tutkiaksesi toista kysymystä.",
            "Hyvää työtä! ✅ Napsauta 'Seuraava', kun olet valmis jatkamaan.",
            "Tämä oli hyvin oivaltavaa! 💡 Napsauta 'Seuraava' seuraavaa kysymystä varten.",
            "Kiitos jakamisesta! 😊 Napsauta 'Seuraava' jatkaaksesi.",
            "Arvostan vastaustasi. 👏 Napsauta 'Seuraava', kun olet valmis toiseen kysymykseen.",
            "Kiitos! 👌 Kun olet valmis, napsauta 'Seuraava'."
        ],
        submitAnswerPhrases: [
            "Mahtavaa, kiitos panoksestasi. ✅ Napsauta 'Lähetä vastaus' lopettaaksesi.",
            "Kiitos yksityiskohtaisesta vastauksestasi. 😊 Napsauta 'Lähetä vastaus' täyttääksesi lomakkeen.",
            "Täydellistä, olemme kattaneet kaiken. 🎯 Napsauta 'Lähetä vastaus' lopettaaksesi.",
            "Kiitos yhteistyöstäsi. 👍 Napsauta 'Lähetä vastaus' lopettaaksesi prosessin.",
            "Olet ollut erittäin avulias. 🌟 Napsauta 'Lähetä vastaus', kun olet valmis.",
            "Täydellistä, tämä on kaikki mitä tarvitsimme. 👌 Napsauta 'Lähetä vastaus' lopettaaksesi."
        ],
        finishButtonPhrases: [
            "Täydellistä, olemme kattaneet kaiken. 🎯 Napsauta 'Valmis' täyttääksesi lomakkeen.",
            "Kiitos yksityiskohtaisista vastauksistasi. 😊 Napsauta 'Valmis' lähettääksesi.",
            "Hyvää työtä kaikkien kysymysten vastaamisessa. ✅ Napsauta 'Valmis' täyttääksesi lomakkeen.",
            "Kiitos yhteistyöstäsi. 👍 Napsauta 'Valmis' lopettaaksesi prosessin.",
            "Olet ollut erittäin avulias. 🌟 Napsauta 'Valmis', kun olet valmis.",
            "Täydellistä, tämä on kaikki mitä tarvitsimme. 👌 Napsauta 'Valmis' lopettaaksesi."
        ],
        fallbackMessage: "Ajattelen..."
    },
    
    mi: { // Maori
        phrases: [
            'Tatari, kei te whakaaro ahau.',
            'Hmm, homai he hekona.',
            'He rawe, tukua mai ahau ki te whakaaro mō tēnei.',
            'Hmm... Kia kite.',
            'Tatari momeniti i ahau e whakaaro ana.',
            'Tukua ahau ki te whakaaro mō tēnei.',
            'Tatari, kei te whakaaro ahau.',
            'Āe, tukua ahau ki te whakaaro mō tēnei.',
            'Tukua ahau ki te whakarite i ōku whakaaro.',
            'Ka pai, kei te tukatuka ahau i tō whakaaro.',
            'Tatari, kei te whakarite ahau i ōku whakaaro.',
            'Kei te whakaaro ahau mō ōu kupu.',
            'Tukua ahau kia keri ki roto mō tēnei wā.',
            'Āu kupu i whakaara i tōku whakaaro.'
        ],
        nextButtonPhrases: [
            "He rawe tō whakautu. 👍 Kia rite koe, pāwhiria 'Panuku'.",
            "Ngā mihi mō tō urupare. ✅ Pāwhiria 'Panuku' ki te haere tonu.",
            "He rawe. 🧐 Kia rite koe, pāwhiria 'Panuku' ki te tirotiro i tētahi atu pātai.",
            "Kei te pai tō mahi! ✅ Pāwhiria 'Panuku' kia haere tonu koe.",
            "He rawe tō ariā! 💡 Pāwhiria 'Panuku' mō te pātai e whai ake nei.",
            "Ngā mihi mō tō toha! 😊 Pāwhiria 'Panuku' kia haere tonu koe.",
            "Kei te mihi au ki tō whakautu. 👏 Pāwhiria 'Panuku' kia rite koe mō tētahi atu pātai.",
            "Ngā mihi! 👌 Kia rite koe, pāwhiria 'Panuku'."
        ],
        submitAnswerPhrases: [
            "He rawe, ngā mihi mō tō urupare. ✅ Pāwhiria 'Tukuna te Whakautu' ki te whakaoti.",
            "Ngā mihi mō tō whakautu mātotoru. 😊 Pāwhiria 'Tukuna te Whakautu' ki te whakaoti i te puka.",
            "Tino pai, kua kapi katoa tātou. 🎯 Pāwhiria 'Tukuna te Whakautu' ki te whakaoti.",
            "Ngā mihi mō tō mahi tahi. 👍 Pāwhiria 'Tukuna te Whakautu' ki te whakaoti i te tukanga.",
            "He tino āwhina koe. 🌟 Pāwhiria 'Tukuna te Whakautu' kia rite koe.",
            "Tino pai, koinei ngā mea katoa e hiahiatia ana e tātou. 👌 Pāwhiria 'Tukuna te Whakautu' ki te whakaoti."
        ],
        finishButtonPhrases: [
            "Tino pai, kua kapi katoa tātou. 🎯 Pāwhiria 'Whakaoti' ki te whakaoti i te puka.",
            "Ngā mihi mō ō whakautu mātotoru. 😊 Pāwhiria 'Whakaoti' ki te tuku.",
            "Kei te pai tō mahi me ngā whakautu katoa. ✅ Pāwhiria 'Whakaoti' ki te whakaoti i te puka.",
            "Ngā mihi mō tō mahi tahi. 👍 Pāwhiria 'Whakaoti' ki te whakaoti i te tukanga.",
            "He tino āwhina koe. 🌟 Pāwhiria 'Whakaoti' kia rite koe.",
            "Tino pai, koinei ngā mea katoa e hiahiatia ana e tātou. 👌 Pāwhiria 'Whakaoti' ki te whakaoti."
        ],
        fallbackMessage: "Whakaaro ana..."
    },
    
    kn: { // Kannada
        phrases: [
            'ಒಂದು ಕ್ಷಣ, ನಾನು ಯೋಚಿಸುತ್ತಿದ್ದೇನೆ.',
            'ಹ್ಮ್ಮ್, ನನಗೆ ಒಂದು ಕ್ಷಣ ಕೊಡಿ.',
            'ಆಸಕ್ತಿದಾಯಕ, ಇದನ್ನು ಯೋಚಿಸೋಣ.',
            'ಹ್ಮ್ಮ್... ನೋಡೋಣ.',
            'ನಾನು ಯೋಚಿಸುತ್ತಿದ್ದೇನೆ, ಒಂದು ಕ್ಷಣ.',
            'ನನಗೆ ಸ್ವಲ್ಪ ಯೋಚಿಸಲು ಅವಕಾಶ ಕೊಡಿ.',
            'ಒಂದು ಕ್ಷಣ, ನಾನು ಯೋಚಿಸುತ್ತಿದ್ದೇನೆ.',
            'ಆಹ್, ಇದನ್ನು ಯೋಚಿಸೋಣ.',
            'ನನಗೆ ನನ್ನ ಆಲೋಚನೆಗಳನ್ನು ಕ್ರಮಗೊಳಿಸಲು ಬಿಡಿ.',
            'ಸರಿ, ನಾನು ನಿಮ್ಮ ಅಂಶವನ್ನು ಪ್ರಕ್ರಿಯೆಗೊಳಿಸುತ್ತಿದ್ದೇನೆ.',
            'ಒಂದು ಕ್ಷಣ, ನಾನು ನನ್ನ ಆಲೋಚನೆಗಳನ್ನು ಕ್ರಮಗೊಳಿಸುತ್ತಿದ್ದೇನೆ.',
            'ನಿಮ್ಮ ಮಾತುಗಳನ್ನು ಯೋಚಿಸುತ್ತಿದ್ದೇನೆ.',
            'ಇದನ್ನು ಸ್ವಲ್ಪ ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ನನಗೆ ಅವಕಾಶ ಕೊಡಿ.',
            'ನಿಮ್ಮ ಮಾತುಗಳು ನನಗೆ ಯೋಚನೆಯನ್ನು ಉಂಟುಮಾಡಿವೆ.'
        ],
        nextButtonPhrases: [
            "ಉತ್ತಮ ಉತ್ತರ. 👍 ನೀವು ಸಿದ್ಧರಾದಾಗ, 'ಮುಂದೆ' ಕ್ಲಿಕ್ ಮಾಡಿ.",
            "ನಿಮ್ಮ ಕಾಣಿಕೆಗಾಗಿ ಧನ್ಯವಾದಗಳು. ✅ ಮುಂದುವರಿಸಲು 'ಮುಂದೆ' ಕ್ಲಿಕ್ ಮಾಡಿ.",
            "ಆಸಕ್ತಿದಾಯಕ. 🧐 ನೀವು ಸಿದ್ಧರಾದಾಗ, 'ಮುಂದೆ' ಕ್ಲಿಕ್ ಮಾಡಿ ಮತ್ತೊಂದು ಪ್ರಶ್ನೆಯನ್ನು ಅನ್ವೇಷಿಸಲು.",
            "ಅದ್ಭುತ! ✅ ನೀವು ಮುಂದುವರಿಸಲು ಸಿದ್ಧರಾದಾಗ, 'ಮುಂದೆ' ಕ್ಲಿಕ್ ಮಾಡಿ.",
            "ಅದು ತುಂಬಾ ಜ್ಞಾನಾರ್ಜನವಾಗಿತ್ತು! 💡 ಮುಂದೆ ಯೋಚಿಸಲು 'ಮುಂದೆ' ಕ್ಲಿಕ್ ಮಾಡಿ.",
            "ನಿಮ್ಮ ಹಂಚಿಕೆಗೆ ಧನ್ಯವಾದಗಳು! 😊 ಮುಂದುವರಿಸಲು 'ಮುಂದೆ' ಕ್ಲಿಕ್ ಮಾಡಿ.",
            "ನಿಮ್ಮ ಉತ್ತರವನ್ನು ನಾನು ಮೆಚ್ಚುತ್ತಿದ್ದೇನೆ. 👏 ಮತ್ತೊಂದು ಪ್ರಶ್ನೆಗೆ ನೀವು ಸಿದ್ಧರಾದಾಗ 'ಮುಂದೆ' ಕ್ಲಿಕ್ ಮಾಡಿ.",
            "ಧನ್ಯವಾದಗಳು! 👌 ನೀವು ಸಿದ್ಧವಾದಾಗ, 'ಮುಂದೆ' ಕ್ಲಿಕ್ ಮಾಡಿ."
        ],
        submitAnswerPhrases: [
            "ಅದ್ಭುತ, ನಿಮ್ಮ ಕಾಣಿಕೆಗಾಗಿ ಧನ್ಯವಾದಗಳು. ✅ ಉತ್ತರವನ್ನು ಅಂತಿಮಗೊಳಿಸಲು 'ಉತ್ತರವನ್ನು ಸಲ್ಲಿಸಿ' ಕ್ಲಿಕ್ ಮಾಡಿ.",
            "ನಿಮ್ಮ ವಿವರವಾದ ಉತ್ತರಕ್ಕೆ ಧನ್ಯವಾದಗಳು. 😊 ಫಾರ್ಮ್ ಅನ್ನು ಪೂರ್ಣಗೊಳಿಸಲು 'ಉತ್ತರವನ್ನು ಸಲ್ಲಿಸಿ' ಕ್ಲಿಕ್ ಮಾಡಿ.",
            "ಸಂಪೂರ್ಣವಾಗಿ, ನಾವು ಎಲ್ಲವನ್ನೂ ಆವರಿಸಿದ್ದೇವೆ. 🎯 ನಿಮ್ಮ ಅಂಶವನ್ನು ಅಂತಿಮಗೊಳಿಸಲು 'ಉತ್ತರವನ್ನು ಸಲ್ಲಿಸಿ' ಕ್ಲಿಕ್ ಮಾಡಿ.",
            "ನಿಮ್ಮ ಸಹಕಾರಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು. 👍 ಪ್ರಕ್ರಿಯೆಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಲು 'ಉತ್ತರವನ್ನು ಸಲ್ಲಿಸಿ' ಕ್ಲಿಕ್ ಮಾಡಿ.",
            "ನೀವು ತುಂಬಾ ಸಹಾಯಕರಾಗಿದ್ದೀರಿ. 🌟 ನೀವು ಸಿದ್ಧರಾದಾಗ 'ಉತ್ತರವನ್ನು ಸಲ್ಲಿಸಿ' ಕ್ಲಿಕ್ ಮಾಡಿ.",
            "ಪೂರ್ಣವಾಗಿ, ಇದು ನಮಗೆ ಬೇಕಾದ ಎಲ್ಲವನ್ನೂ. 👌 ನಿಮ್ಮ ಅಂಶವನ್ನು ಅಂತಿಮಗೊಳಿಸಲು 'ಉತ್ತರವನ್ನು ಸಲ್ಲಿಸಿ' ಕ್ಲಿಕ್ ಮಾಡಿ."
        ],
        finishButtonPhrases: [
            "ಪೂರ್ಣವಾಗಿ, ನಾವು ಎಲ್ಲವನ್ನೂ ಆವರಿಸಿದ್ದೇವೆ. 🎯 ಫಾರ್ಮ್ ಅನ್ನು ಪೂರ್ಣಗೊಳಿಸಲು 'ಮುಗಿಸು' ಕ್ಲಿಕ್ ಮಾಡಿ.",
            "ನಿಮ್ಮ ವಿವರವಾದ ಉತ್ತರಗಳಿಗೆ ಧನ್ಯವಾದಗಳು. 😊 ಸಲ್ಲಿಸಲು 'ಮುಗಿಸು' ಕ್ಲಿಕ್ ಮಾಡಿ.",
            "ಎಲ್ಲಾ ಪ್ರಶ್ನೆಗಳಿಗೆ ಉತ್ತರಿಸಲು ಉತ್ತಮವಾಗಿ ಮಾಡಲಾಗಿದೆ. ✅ ಫಾರ್ಮ್ ಅನ್ನು ಪೂರ್ಣಗೊಳಿಸಲು 'ಮುಗಿಸು' ಕ್ಲಿಕ್ ಮಾಡಿ.",
            "ನಿಮ್ಮ ಸಹಕಾರಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು. 👍 ಪ್ರಕ್ರಿಯೆಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಲು 'ಮುಗಿಸು' ಕ್ಲಿಕ್ ಮಾಡಿ.",
            "ನೀವು ತುಂಬಾ ಸಹಾಯಕರಾಗಿದ್ದೀರಿ. 🌟 ನೀವು ಸಿದ್ಧವಾದಾಗ 'ಮುಗಿಸು' ಕ್ಲಿಕ್ ಮಾಡಿ.",
            "ಪೂರ್ಣವಾಗಿ, ಇದು ನಮಗೆ ಬೇಕಾದ ಎಲ್ಲವನ್ನೂ. 👌 ನಿಮ್ಮ ಅಂಶವನ್ನು ಅಂತಿಮಗೊಳಿಸಲು 'ಮುಗಿಸು' ಕ್ಲಿಕ್ ಮಾಡಿ."
        ],
        fallbackMessage: "ಯೋಚಿಸುತ್ತಿದ್ದೇನೆ..."
    },
    
    ne: { // Nepali
        phrases: [
            'एक क्षण पर्खनुहोस्, म सोच्दै छु।',
            'हम्म, मलाई एक सेकेन्ड दिनुहोस्।',
            'रमाइलो, यसलाई म सोच्न सक्छु।',
            'हम्म... हेरौं।',
            'म सोच्दै छु, एक क्षण पर्खनुहोस्।',
            'मलाई थोरै सोच्न दिनुहोस्।',
            'एक क्षण पर्खनुहोस्, म सोच्दै छु।',
            'आह, यसलाई म सोच्न सक्छु।',
            'मलाई मेरा विचारहरूलाई व्यवस्थित गर्न दिनुहोस्।',
            'सही छ, म तपाईंको विचारलाई प्रशोधन गर्दैछु।',
            'एक क्षण पर्खनुहोस्, म मेरा विचारहरूलाई व्यवस्थित गर्दैछु।',
            'म तपाईंका शब्दहरू बारे सोच्दैछु।',
            'यसलाई थोरै पचाउन मलाई अनुमति दिनुहोस्।',
            'तपाईंका शब्दहरूले मलाई सोच्न बाध्य गराएको छ।'
        ],
        nextButtonPhrases: [
            "उत्तम उत्तर। 👍 जब तपाईं तयार हुनुहुन्छ, 'अर्को' क्लिक गर्नुहोस्।",
            "तपाईंको योगदानको लागि धन्यवाद। ✅ जारी राख्न 'अर्को' क्लिक गर्नुहोस्।",
            "रमाइलो। 🧐 जब तपाईं तयार हुनुहुन्छ, 'अर्को' क्लिक गर्नुहोस् अर्को प्रश्न अन्वेषण गर्न।",
            "राम्रो काम! ✅ जब तपाईं तयार हुनुहुन्छ, 'अर्को' क्लिक गर्नुहोस् जारी राख्न।",
            "यो धेरै गहिरो थियो! 💡 अर्को प्रश्नको लागि 'अर्को' क्लिक गर्नुहोस्।",
            "तपाईंको साझेदारीको लागि धन्यवाद! 😊 जारी राख्न 'अर्को' क्लिक गर्नुहोस्।",
            "म तपाईंको उत्तरलाई सराहना गर्दछु। 👏 अर्को प्रश्नको लागि तयार हुँदा 'अर्को' क्लिक गर्नुहोस्।",
            "धन्यवाद! 👌 जब तपाईं तयार हुनुहुन्छ, 'अर्को' क्लिक गर्नुहोस्।"
        ],
        submitAnswerPhrases: [
            "उत्तम, तपाईंको योगदानको लागि धन्यवाद। ✅ उत्तर पूरा गर्न 'उत्तर पेश गर्नुहोस्' क्लिक गर्नुहोस्।",
            "तपाईंको विस्तृत उत्तरको लागि धन्यवाद। 😊 फारम पूरा गर्न 'उत्तर पेश गर्नुहोस्' क्लिक गर्नुहोस्।",
            "सही छ, हामीले सबै कुरा कभर गरेका छौं। 🎯 तपाईंको योगदान पूरा गर्न 'उत्तर पेश गर्नुहोस्' क्लिक गर्नुहोस्।",
            "तपाईंको सहकार्यको लागि धन्यवाद। 👍 प्रक्रिया पूरा गर्न 'उत्तर पेश गर्नुहोस्' क्लिक गर्नुहोस्।",
            "तपाईं धेरै सहायक हुनुहुन्छ। 🌟 जब तपाईं तयार हुनुहुन्छ, 'उत्तर पेश गर्नुहोस्' क्लिक गर्नुहोस्।",
            "सही छ, यही थियो सबै कुरा जुन हामीलाई आवश्यक थियो। 👌 तपाईंको योगदान पूरा गर्न 'उत्तर पेश गर्नुहोस्' क्लिक गर्नुहोस्।"
        ],
        finishButtonPhrases: [
            "सही छ, हामीले सबै कुरा कभर गरेका छौं। 🎯 फारम पूरा गर्न 'समाप्त गर्नुहोस्' क्लिक गर्नुहोस्।",
            "तपाईंका विस्तृत उत्तरहरूको लागि धन्यवाद। 😊 प्रस्तुत गर्न 'समाप्त गर्नुहोस्' क्लिक गर्नुहोस्।",
            "सबै प्रश्नहरूको उत्तर दिनमा राम्रो काम। ✅ फारम पूरा गर्न 'समाप्त गर्नुहोस्' क्लिक गर्नुहोस्।",
            "तपाईंको सहकार्यको लागि धन्यवाद। 👍 प्रक्रिया पूरा गर्न 'समाप्त गर्नुहोस्' क्लिक गर्नुहोस्।",
            "तपाईं धेरै सहायक हुनुहुन्छ। 🌟 जब तपाईं तयार हुनुहुन्छ, 'समाप्त गर्नुहोस्' क्लिक गर्नुहोस्।",
            "सही छ, यही थियो सबै कुरा जुन हामीलाई आवश्यक थियो। 👌 तपाईंको योगदान पूरा गर्न 'समाप्त गर्नुहोस्' क्लिक गर्नुहोस्।"
        ],
        fallbackMessage: "सोच्दै छु..."
    },
    
    kk: { // Kazakh
        phrases: [
            'Бір сәт күтіңіз, ойланып отырмын.',
            'Хмм, маған бір секунд беріңіз.',
            'Қызық, осыны ойлап көрейін.',
            'Хмм... Қарайық.',
            'Мен ойланып отырмын, бір сәт күтіңіз.',
            'Маған аздап ойлануға рұқсат етіңіз.',
            'Бір сәт күтіңіз, ойланып отырмын.',
            'Аһ, осыны ойлап көрейін.',
            'Маған ойларымды реттеуге рұқсат етіңіз.',
            'Жақсы, мен сіздің ойыңызды өңдеп жатырмын.',
            'Бір сәт күтіңіз, мен ойларымды реттеудемін.',
            'Мен сіздің сөздеріңізді ойлап отырмын.',
            'Мұны сіңіріп алу үшін маған уақыт беріңіз.',
            'Сіздің ойларыңыз мені ойлануға мәжбүр етті.'
        ],
        nextButtonPhrases: [
            "Тамаша жауап. 👍 Дайын болғанда, 'Келесі' батырмасын басыңыз.",
            "Қосқан үлесіңіз үшін рахмет. ✅ Жалғастыру үшін 'Келесі' батырмасын басыңыз.",
            "Қызық. 🧐 Дайын болғанда, 'Келесі' батырмасын басып, басқа сұрақты зерттеңіз.",
            "Жақсы жұмыс! ✅ Дайын болғанда, жалғастыру үшін 'Келесі' батырмасын басыңыз.",
            "Бұл өте терең болды! 💡 Келесі сұраққа көшу үшін 'Келесі' батырмасын басыңыз.",
            "Бөліскеніңіз үшін рахмет! 😊 Жалғастыру үшін 'Келесі' батырмасын басыңыз.",
            "Жауабыңызды бағалаймын. 👏 Келесі сұраққа дайын болғанда 'Келесі' батырмасын басыңыз.",
            "Рахмет! 👌 Дайын болғанда, 'Келесі' батырмасын басыңыз."
        ],
        submitAnswerPhrases: [
            "Тамаша, қосқан үлесіңіз үшін рахмет. ✅ Жауабыңызды аяқтау үшін 'Жауапты жіберу' батырмасын басыңыз.",
            "Толық жауабыңыз үшін рахмет. 😊 Форманы аяқтау үшін 'Жауапты жіберу' батырмасын басыңыз.",
            "Тамаша, бәрін қамтыдық. 🎯 Сіздің жауабыңызды аяқтау үшін 'Жауапты жіберу' батырмасын басыңыз.",
            "Ынтымақтастығыңыз үшін рахмет. 👍 Процесті аяқтау үшін 'Жауапты жіберу' батырмасын басыңыз.",
            "Сіз өте пайдалы болдыңыз. 🌟 Дайын болғанда 'Жауапты жіберу' батырмасын басыңыз.",
            "Тамаша, бұл бізге керек нәрсенің бәрі болды. 👌 Жауабыңызды аяқтау үшін 'Жауапты жіберу' батырмасын басыңыз."
        ],
        finishButtonPhrases: [
            "Тамаша, бәрін қамтыдық. 🎯 Форманы аяқтау үшін 'Аяқтау' батырмасын басыңыз.",
            "Толық жауабыңыз үшін рахмет. 😊 Жіберу үшін 'Аяқтау' батырмасын басыңыз.",
            "Барлық сұрақтарға жауап бергеніңіз үшін жақсы жұмыс. ✅ Форманы аяқтау үшін 'Аяқтау' батырмасын басыңыз.",
            "Ынтымақтастығыңыз үшін рахмет. 👍 Процесті аяқтау үшін 'Аяқтау' батырмасын басыңыз.",
            "Сіз өте пайдалы болдыңыз. 🌟 Дайын болғанда 'Аяқтау' батырмасын басыңыз.",
            "Тамаша, бұл бізге керек нәрсенің бәрі болды. 👌 'Аяқтау' батырмасын басыңыз."
        ],
        fallbackMessage: "Ойланып отырмын..."
    },
    
    mr: { // Marathi
        phrases: [
            'थोडा वेळ द्या, मी विचार करतोय.',
            'हम्म, मला एक सेकंद द्या.',
            'रमणीय, हे मी विचार करतोय.',
            'हम्म... पाहूया.',
            'मी विचार करतोय, थोडा वेळ द्या.',
            'मला थोडा विचार करू द्या.',
            'थोडा वेळ द्या, मी विचार करतोय.',
            'आह, हे मी विचार करतोय.',
            'मला माझे विचार व्यवस्थित करू द्या.',
            'ठीक आहे, मी तुमच्या विचाराचा विचार करतोय.',
            'थोडा वेळ द्या, मी माझे विचार व्यवस्थित करतोय.',
            'मी तुमच्या शब्दांचा विचार करतोय.',
            'मला हे थोडा वेळ पचवू द्या.',
            'तुमचे शब्द मला विचार करायला भाग पाडतात.'
        ],
        nextButtonPhrases: [
            "उत्तम उत्तर. 👍 तयार झाल्यावर 'पुढील' वर क्लिक करा.",
            "तुमच्या योगदानासाठी धन्यवाद. ✅ पुढे जाण्यासाठी 'पुढील' वर क्लिक करा.",
            "रमणीय. 🧐 तयार झाल्यावर, आणखी एक प्रश्न शोधण्यासाठी 'पुढील' वर क्लिक करा.",
            "छान काम केले! ✅ तयार झाल्यावर पुढे जाण्यासाठी 'पुढील' वर क्लिक करा.",
            "हे खूप गहन होते! 💡 पुढील प्रश्नासाठी 'पुढील' वर क्लिक करा.",
            "सहभागासाठी धन्यवाद! 😊 पुढे जाण्यासाठी 'पुढील' वर क्लिक करा.",
            "तुमच्या उत्तराचे मी कौतुक करतो. 👏 दुसरा प्रश्न विचारण्यासाठी तयार झाल्यावर 'पुढील' वर क्लिक करा.",
            "धन्यवाद! 👌 तयार झाल्यावर 'पुढील' वर क्लिक करा."
        ],
        submitAnswerPhrases: [
            "उत्तम, तुमच्या योगदानासाठी धन्यवाद. ✅ उत्तर अंतिम करण्यासाठी 'उत्तर सादर करा' वर क्लिक करा.",
            "तुमच्या तपशीलवार उत्तराबद्दल धन्यवाद. 😊 फॉर्म पूर्ण करण्यासाठी 'उत्तर सादर करा' वर क्लिक करा.",
            "संपूर्णपणे, आम्ही सर्व काही झाकले आहे. 🎯 तुमचे उत्तर अंतिम करण्यासाठी 'उत्तर सादर करा' वर क्लिक करा.",
            "तुमच्या सहकार्याबद्दल धन्यवाद. 👍 प्रक्रिया पूर्ण करण्यासाठी 'उत्तर सादर करा' वर क्लिक करा.",
            "तुम्ही खूप उपयुक्त ठरला आहात. 🌟 तयार झाल्यावर 'उत्तर सादर करा' वर क्लिक करा.",
            "संपूर्णपणे, हेच आपल्याला हवे होते. 👌 तुमचे उत्तर अंतिम करण्यासाठी 'उत्तर सादर करा' वर क्लिक करा."
        ],
        finishButtonPhrases: [
            "संपूर्णपणे, आम्ही सर्व काही झाकले आहे. 🎯 फॉर्म पूर्ण करण्यासाठी 'समाप्त करा' वर क्लिक करा.",
            "तुमच्या तपशीलवार उत्तरांसाठी धन्यवाद. 😊 सबमिट करण्यासाठी 'समाप्त करा' वर क्लिक करा.",
            "सर्व प्रश्नांची उत्तरे देण्यासाठी चांगले काम केले. ✅ फॉर्म पूर्ण करण्यासाठी 'समाप्त करा' वर क्लिक करा.",
            "तुमच्या सहकार्याबद्दल धन्यवाद. 👍 प्रक्रिया पूर्ण करण्यासाठी 'समाप्त करा' वर क्लिक करा.",
            "तुम्ही खूप उपयुक्त ठरला आहात. 🌟 तयार झाल्यावर 'समाप्त करा' वर क्लिक करा.",
            "संपूर्णपणे, हेच आपल्याला हवे होते. 👌 तुमचे उत्तर अंतिम करण्यासाठी 'समाप्त करा' वर क्लिक करा."
        ],
        fallbackMessage: "विचार करतोय..."
    },
    
    az: { // Azerbaijani
        phrases: [
            'Bir az gözləyin, düşünürəm.',
            'Hmm, mənə bir saniyə verin.',
            'Maraqlı, bu barədə düşünməyimə icazə verin.',
            'Hmm... Gəlin baxaq.',
            'Mən düşünürəm, bir az gözləyin.',
            'Mənə bir az düşünməyə icazə verin.',
            'Bir az gözləyin, düşünürəm.',
            'Ah, bu barədə düşünməyimə icazə verin.',
            'Mənə düşüncələrimi toplamağa icazə verin.',
            'Yaxşı, sizin düşüncənizi işləyirəm.',
            'Bir az gözləyin, mən düşüncələrimi toplamağa çalışıram.',
            'Sözləriniz haqqında düşünürəm.',
            'Bunu bir az həzm etməyimə icazə verin.',
            'Sözləriniz məni düşünməyə vadar edir.'
        ],
        nextButtonPhrases: [
            "Əla cavab. 👍 Hazır olduqda, 'Növbəti' düyməsini basın.",
            "Töhfəniz üçün təşəkkür edirik. ✅ Davam etmək üçün 'Növbəti' düyməsini basın.",
            "Maraqlı. 🧐 Hazır olduqda, başqa bir sualı araşdırmaq üçün 'Növbəti' düyməsini basın.",
            "Əla iş! ✅ Davam etmək üçün hazır olduqda 'Növbəti' düyməsini basın.",
            "Bu çox dərindir! 💡 Növbəti sual üçün 'Növbəti' düyməsini basın.",
            "Paylaşdığınız üçün təşəkkür edirik! 😊 Davam etmək üçün 'Növbəti' düyməsini basın.",
            "Cavabınızı yüksək qiymətləndirirəm. 👏 Başqa bir sual üçün hazır olduqda 'Növbəti' düyməsini basın.",
            "Təşəkkür edirik! 👌 Hazır olduqda, 'Növbəti' düyməsini basın."
        ],
        submitAnswerPhrases: [
            "Mükəmməl, töhfəniz üçün təşəkkür edirik. ✅ Cavabınızı başa çatdırmaq üçün 'Cavabı təqdim et' düyməsini basın.",
            "Ətraflı cavabınız üçün təşəkkür edirik. 😊 Formanı başa çatdırmaq üçün 'Cavabı təqdim et' düyməsini basın.",
            "Mükəmməl, hər şeyi əhatə etdik. 🎯 Cavabınızı başa çatdırmaq üçün 'Cavabı təqdim et' düyməsini basın.",
            "Əməkdaşlığınız üçün təşəkkür edirik. 👍 Prosesi başa çatdırmaq üçün 'Cavabı təqdim et' düyməsini basın.",
            "Çox kömək etdiniz. 🌟 Hazır olduqda 'Cavabı təqdim et' düyməsini basın.",
            "Mükəmməl, bu bizim ehtiyacımız olan hər şeydir. 👌 Cavabınızı başa çatdırmaq üçün 'Cavabı təqdim et' düyməsini basın."
        ],
        finishButtonPhrases: [
            "Mükəmməl, hər şeyi əhatə etdik. 🎯 Formanı başa çatdırmaq üçün 'Bitir' düyməsini basın.",
            "Ətraflı cavablarınız üçün təşəkkür edirik. 😊 Göndərmək üçün 'Bitir' düyməsini basın.",
            "Bütün suallara cavab verdiyiniz üçün yaxşı iş. ✅ Formanı başa çatdırmaq üçün 'Bitir' düyməsini basın.",
            "Əməkdaşlığınız üçün təşəkkür edirik. 👍 Prosesi başa çatdırmaq üçün 'Bitir' düyməsini basın.",
            "Çox kömək etdiniz. 🌟 Hazır olduqda 'Bitir' düyməsini basın.",
            "Mükəmməl, bu bizim ehtiyacımız olan hər şeydir. 👌 Cavabınızı başa çatdırmaq üçün 'Bitir' düyməsini basın."
        ],
        fallbackMessage: "Düşünürəm..."
    },
    
    ms: { // Malay
        phrases: [
            'Sebentar, saya sedang berfikir.',
            'Hmm, beri saya sekejap.',
            'Menarik, biar saya fikirkan tentang ini.',
            'Hmm... Mari kita lihat.',
            'Sebentar sementara saya berfikir.',
            'Biar saya fikirkan sejenak.',
            'Sebentar, saya sedang berfikir.',
            'Ah, biar saya fikirkan tentang ini.',
            'Biar saya susun fikiran saya.',
            'Baiklah, saya sedang memproses pemikiran anda.',
            'Sebentar, saya sedang menyusun fikiran saya.',
            'Saya sedang merenung kata-kata anda.',
            'Beri saya sejenak untuk mencerna ini.',
            'Kata-kata anda membuat saya berfikir.'
        ],
        nextButtonPhrases: [
            "Jawapan yang baik. 👍 Apabila anda bersedia, klik 'Seterusnya'.",
            "Terima kasih atas sumbangan anda. ✅ Sila klik 'Seterusnya' untuk meneruskan.",
            "Menarik. 🧐 Apabila anda bersedia, klik 'Seterusnya' untuk meneroka soalan lain.",
            "Kerja yang baik! ✅ Sila klik 'Seterusnya' apabila anda bersedia untuk meneruskan.",
            "Itu sangat berwawasan! 💡 Sila klik 'Seterusnya' untuk soalan seterusnya.",
            "Terima kasih kerana berkongsi! 😊 Klik 'Seterusnya' untuk meneruskan.",
            "Saya menghargai jawapan anda. 👏 Klik 'Seterusnya' apabila anda bersedia untuk soalan lain.",
            "Terima kasih! 👌 Apabila anda bersedia, klik 'Seterusnya'."
        ],
        submitAnswerPhrases: [
            "Baiklah, terima kasih atas sumbangan anda. ✅ Klik 'Hantar Jawapan' untuk menyelesaikan.",
            "Terima kasih atas jawapan terperinci anda. 😊 Klik 'Hantar Jawapan' untuk melengkapkan borang.",
            "Sempurna, kita telah meliputi semuanya. 🎯 Klik 'Hantar Jawapan' untuk menyelesaikan.",
            "Terima kasih atas kerjasama anda. 👍 Klik 'Hantar Jawapan' untuk melengkapkan proses.",
            "Anda sangat membantu. 🌟 Klik 'Hantar Jawapan' apabila anda bersedia.",
            "Sempurna, ini semua yang kami perlukan. 👌 Klik 'Hantar Jawapan' untuk menyelesaikan."
        ],
        finishButtonPhrases: [
            "Sempurna, kita telah meliputi semuanya. 🎯 Klik 'Selesai' untuk melengkapkan borang.",
            "Terima kasih atas jawapan terperinci anda. 😊 Klik 'Selesai' untuk menyerahkan.",
            "Kerja yang baik menjawab semua soalan. ✅ Klik 'Selesai' untuk melengkapkan borang.",
            "Terima kasih atas kerjasama anda. 👍 Klik 'Selesai' untuk melengkapkan proses.",
            "Anda sangat membantu. 🌟 Klik 'Selesai' apabila anda bersedia.",
            "Sempurna, ini semua yang kami perlukan. 👌 Klik 'Selesai' untuk menyelesaikan."
        ],
        fallbackMessage: "Berfikir..."
    },
    
    th: { // Thai
        phrases: [
            'รอสักครู่ กำลังคิดอยู่',
            'อืมม์ ขอเวลาสักครู่',
            'น่าสนใจ ขอเวลาคิดสักหน่อย',
            'อืมม์... ลองดูกัน',
            'รอสักครู่ในขณะที่กำลังคิดอยู่',
            'ขอเวลาคิดสักหน่อย',
            'รอสักครู่ กำลังคิดอยู่',
            'อา ขอเวลาคิดเกี่ยวกับสิ่งนี้',
            'ขอเวลาจัดเรียงความคิดของฉัน',
            'โอเค กำลังประมวลผลความคิดของคุณ',
            'รอสักครู่ กำลังจัดระเบียบความคิดของฉัน',
            'กำลังพิจารณาคำพูดของคุณ',
            'ขอเวลาย่อยสิ่งนี้สักครู่',
            'คำพูดของคุณทำให้ฉันคิดมาก'
        ],
        nextButtonPhrases: [
            "คำตอบยอดเยี่ยม 👍 เมื่อคุณพร้อมแล้ว คลิก 'ถัดไป'",
            "ขอบคุณสำหรับคำตอบของคุณ ✅ กรุณาคลิก 'ถัดไป' เพื่อดำเนินการต่อ",
            "น่าสนใจ 🧐 เมื่อคุณพร้อมแล้ว คลิก 'ถัดไป' เพื่อสำรวจคำถามต่อไป",
            "ทำได้ดีมาก! ✅ คลิก 'ถัดไป' เมื่อคุณพร้อมที่จะดำเนินการต่อ",
            "นี่คือข้อคิดที่ลึกซึ้ง! 💡 กรุณาคลิก 'ถัดไป' สำหรับคำถามถัดไป",
            "ขอบคุณที่แชร์ 😊 คลิก 'ถัดไป' เพื่อดำเนินการต่อ",
            "ฉันชื่นชมคำตอบของคุณ 👏 คลิก 'ถัดไป' เมื่อคุณพร้อมสำหรับคำถามต่อไป",
            "ขอบคุณ! 👌 เมื่อคุณพร้อมแล้ว คลิก 'ถัดไป'"
        ],
        submitAnswerPhrases: [
            "ยอดเยี่ยม ขอบคุณสำหรับคำตอบของคุณ ✅ คลิก 'ส่งคำตอบ' เพื่อทำการส่งเสร็จสิ้น",
            "ขอบคุณสำหรับคำตอบที่ละเอียดของคุณ 😊 คลิก 'ส่งคำตอบ' เพื่อกรอกแบบฟอร์มให้เสร็จสิ้น",
            "เยี่ยมมาก เราครอบคลุมทุกอย่างแล้ว 🎯 กรุณาคลิก 'ส่งคำตอบ' เพื่อทำการส่งเสร็จสิ้น",
            "ขอบคุณสำหรับความร่วมมือของคุณ 👍 คลิก 'ส่งคำตอบ' เพื่อทำกระบวนการให้เสร็จสิ้น",
            "คุณช่วยได้มาก 🌟 คลิก 'ส่งคำตอบ' เมื่อคุณพร้อม",
            "ยอดเยี่ยม นั่นคือทั้งหมดที่เราต้องการ 👌 กรุณาคลิก 'ส่งคำตอบ' เพื่อทำการส่งเสร็จสิ้น"
        ],
        finishButtonPhrases: [
            "เยี่ยมมาก เราครอบคลุมทุกอย่างแล้ว 🎯 กรุณาคลิก 'เสร็จสิ้น' เพื่อทำแบบฟอร์มให้เสร็จสิ้น",
            "ขอบคุณสำหรับคำตอบที่ละเอียดของคุณ 😊 คลิก 'เสร็จสิ้น' เพื่อส่ง",
            "ทำได้ดีมากในการตอบทุกคำถาม ✅ กรุณาคลิก 'เสร็จสิ้น' เพื่อทำแบบฟอร์มให้เสร็จสิ้น",
            "ขอบคุณสำหรับความร่วมมือของคุณ 👍 คลิก 'เสร็จสิ้น' เพื่อทำกระบวนการให้เสร็จสิ้น",
            "คุณช่วยได้มาก 🌟 คลิก 'เสร็จสิ้น' เมื่อคุณพร้อม",
            "ยอดเยี่ยม นั่นคือทั้งหมดที่เราต้องการ 👌 กรุณาคลิก 'เสร็จสิ้น' เพื่อทำการส่งเสร็จสิ้น"
        ],
        fallbackMessage: "กำลังคิด..."
    },
    
}