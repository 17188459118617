<template>
    <v-expand-transition v-if="$vuetify.breakpoint.xs">
        <slot></slot>
    </v-expand-transition>

    <v-expand-x-transition v-else>
        <slot></slot>
    </v-expand-x-transition>
</template>

<script>
export default {
    name: 'ExpandTransition'
}
</script>