<template>
    <v-text-field
        ref="input"
        @click:clear="handleClear"
        v-model="answer"
        @change="handleNumberChange"
        clearable
        class="ma-0 pa-0 text-caption"
        :prefix="prefixProperty"
        :suffix="suffixProperty"
        :placeholder="fieldPlaceholder"
        style="min-width: 200px;"
        hide-details="auto"
        type="number"
        dense
        outlined
        light
        :rules="[rules.required, rules.isNumber, rules.isLowerThenMin, rules.isGreaterThenMax, rules.isInteger]"
    ></v-text-field>
</template>

<script>
export default {
    name: "MatrixNumericInput",
    props: {
        row: {
            required: true
        },
        col: {
            required: true
        },
        value: {
            required: false,
            default: null
        },
        selectionValue: {
            required: false,
            default: null
        }
    },
    data() {
        return {
            rules: {
                required: value => {
                    if (!this.col.optional && !value && value !== 0) {
                        return this.$t("form.matrixRequired");
                    }
                    return true;
                },
                isNumber: value => {
                    if (value && isNaN(value)) {
                        return this.$t("form.matrixNumericMustBeNumber");
                    }
                    return true;
                },
                isLowerThenMin: value => {
                    if (
                        typeof value === "number" &&
                        typeof this.minProperty === "number" &&
                        value < this.minProperty
                    ) {
                        return this.$t('form.matrixNumericMustBeGreaterThenMin', {min: this.minProperty})
                    }
                    return true;
                },
                isGreaterThenMax: value => {
                    if (
                        typeof value === "number" &&
                        typeof this.maxProperty === "number" &&
                        value > this.maxProperty
                    ) {
                        return this.$t('form.matrixNumericMustBeLessThenMax', {max: this.maxProperty})
                    }
                    return true;
                },
                isInteger: value => {
                    if (
                        typeof value === "number" &&
                        !this.allowDecimalNumbersProperty &&
                        !Number.isInteger(value)
                    ) {
                        return this.$t("form.matrixNumericMustBeInteger");
                    }
                    return true;
                }
            }
        };
    },
    computed: {
        answer: {
            get: function () {
                return this.value
            },
            set: function (val) {
                try {
                    let numericValue = val ? Number(val) : null;
                    if (numericValue!==null && !this.allowDecimalNumbersProperty) {
                        numericValue = Number(numericValue.toFixed(0));
                    }
                    const isValid = this.validateValue(numericValue);

                    this.$emit("input", val ? {value: numericValue, valid: isValid || this.$t('form.matrixNumericInvalidNumber') } : null);
                } catch (error) {
                    console.error(error);
                }
            },
        },
        minProperty() {
            if (typeof this.col.properties?.min_number === "number") {
                return this.col.properties.min_number;
            }
            return null;
        },
        maxProperty() {
            if (typeof this.col.properties?.max_number === "number") {
                return this.col.properties.max_number;
            }
            return null;
        },
        allowDecimalNumbersProperty() {
            return this.col.properties?.allow_decimal_numbers || false;
        },
        fieldPlaceholder() {
            return this.col.properties?.field_placeholder || null;
        },
        prefixProperty() {
            return this.col.properties?.prefix || null;
        },
        suffixProperty() {
            return this.col.properties?.suffix || null;
        }
    },
    methods: {
        handleClear() {
            this.$refs?.input?.blur();
        },
        handleNumberChange(val){
            try {
                let numericValue = val ? Number(val) : null;
                    if (numericValue!==null && !this.allowDecimalNumbersProperty) {
                        numericValue = Number(numericValue.toFixed(0));
                    }
                    const isValid = this.validateValue(numericValue);

                    this.$emit("change", val ? {value: numericValue, valid: isValid || 'Invalid Number.' } : null);
            } catch (error) {
                console.error(error);
            }
        },
        validateValue(value) {
            const validations = [
                this.rules.required,
                this.rules.isNumber,
                this.rules.isLowerThenMin,
                this.rules.isGreaterThenMax,
                this.rules.isInteger,
            ];
            for (const validate of validations) {
                const result = validate(value);
                if (result !== true) {
                    return result; // This will be the error message string
                }
            }
            return true; // All validations passed
        },
    },
    mounted() {
        // validate if previously passed value was invalid
        if(this.selectionValue && this.selectionValue.valid !== true){
            this.$refs.input.validate(true)
        }
    }
};
</script>
