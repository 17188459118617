<template>
    <div class="d-flex flex-grow-1 justify-center justify-sm-start" :style="cssVars">
        <v-btn-toggle active-class="active-class" :color="styles.button.background"  v-model="answer">
            <v-btn value="yes" large>{{ yesLabel }}</v-btn>
            <v-btn  value="no" large act>{{ noLabel }}</v-btn>
        </v-btn-toggle>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: "Yesno",
    props: {
        value: {
            required: true,
        },
        activeStep: {
            required: true,
        },
        dark: {
            required: true,
        },
    },
    components: {},

    computed: {
        ...mapGetters({
            styles: 'form/styles'
        }),
        cssVars() {
            return {
                '--button-bg-active-color': this.styles.button.background,
                '--button-text-active-color': this.styles.button.color
            }
        },
        answer: {
            get: function () {
                return this.value ? this.value.value : "";
            },
            set: function (val) {
                this.$emit("input", val?{ type: "boolean", value: val }:null);
                this.$emit("change");
            },
        },
        yesLabel(){
            return this.activeStep?.properties && this.activeStep.properties.yes_label || this.$t('form.booleanYesLabel');
        },
        noLabel(){
            return this.activeStep?.properties && this.activeStep.properties.no_label || this.$t('form.booleanNoLabel');
        },
    },
};
</script>

<style lang="scss" scoped>
 .active-class{
     background-color: var(--button-bg-active-color);
     color: var(--button-text-active-color);
 }
</style>

