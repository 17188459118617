<template>
    <div class="d-flex">
        <v-avatar size="44" class="mt-4" v-if="showAvatar" :color="styles.dark?'#ffffff20':'#00000020'">
            <v-icon>mdi-account</v-icon>
        </v-avatar>
        <div class="d-flex flex-column">
            <div class="d-flex align-center justify-start"
                :style="{ color: styles.backgroundTextColor }">
                <div v-if="!hideRecordingButton && !hideEdits" class="d-flex flex-column align-center">
                    <div style="min-height: 24">{{ progressValue? timer: '&nbsp;'}}</div>
                    <div class="relative-container">
                        <div class="visualizer">
                            <visualizer v-if="stream && recording" :stream="stream"></visualizer>
                        </div>
    
                        <v-btn :color="styles.button.background" fab dark elevation="0" :width="recording ? '50' : '80'"
                            :height="recording ? '50' : '80'" @click="handleRecordPress" :ripple="false" class="button">
                            <v-scale-transition hide-on-leave>
                                <v-progress-circular indeterminate v-if="loading" :color="styles.button.color"
                                    size="30"></v-progress-circular>
                            </v-scale-transition>
                            <v-scale-transition hide-on-leave>
                                <v-icon v-if="!loading && recording" :color="styles.button.color"
                                    size="30">mdi-stop</v-icon>
                            </v-scale-transition>
                            <v-scale-transition hide-on-leave>
                                <v-icon v-if="!loading && !recording" :color="styles.button.color"
                                    size="31">{{ permissions=== false ? 'mdi-microphone-off' : 'mdi-microphone' }}</v-icon>
                            </v-scale-transition>
                        </v-btn>
                        <div class="progress">
                            <v-progress-circular rotate="270" :value="progressValue" size="100"
                                :color="styles.backgroundTextColor"></v-progress-circular>
                        </div>
                    </div>
                    <v-expand-transition>
                        <div v-show="!recording" class="text-caption mt-2 helper-text">
                            <v-icon :color="styles.button.background"
                                size="14">mdi-lightning-bolt</v-icon>
                            {{ recordLabel }}
                        </div>
                    </v-expand-transition>
                </div>
                <v-fade-transition leave-absolute>
                    <div class="px-4 pt-4" v-if="!recording && (value || uploading)">
                        <audio-player-controls @duration="handleDuration" :loading="uploading" :link="value" @cleanAudio="removeAudio" :hideRemoveRecordingButton="uploading || hideRemoveRecordingButton" />
                    </div>
                </v-fade-transition>
            </div>
            <v-snackbar
                v-model="showError"
                top
                :dark="styles.dark"
                :timeout="-1"
            >
                {{ $t('form.audioRecorderFailedUpload') }}

                <template v-slot:action="{ attrs }">
                    <v-btn v-bind="attrs" :loading="uploading" :disabled="uploading" @click="sendFile" color="error" class="my-n3" elevation="0" icon>
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>

            <silence-detector v-if="showSilenceDetector" :stream="stream"></silence-detector>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AudioPlayerControls from './AudioPlayerControls.vue'
import Visualizer from './Visualizer.vue'
import SilenceDetector from './SilenceDetector.vue'
import { uploadAudio } from '../../../../api/survey'
import { timeNumerictoString } from '../../../../utils/numberOperations';
import * as amplitude from '@amplitude/analytics-browser';

const DEFAULT_TIME_LIMIT = 60 * 2.5;
const TIME_SLICE = 100;

export default {
    components: { AudioPlayerControls, Visualizer, SilenceDetector },
    name: 'AudioRecorder',
    props: {
        value: {
            required: true
        },
        audioProperties: {
            required: true,
            type: Object
        },
        activeStep: {
            required: true,
            type: Object
        },
        hideRecordingButton: {
            default: false,
            type: Boolean
        },
        hideRemoveRecordingButton: {
            default: false,
            type: Boolean
        },
        showAvatar: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            mediaRecorder: null,
            recordedChunks: [],
            timeLeft: (this.audioProperties.maxRecordTime || DEFAULT_TIME_LIMIT) * 1000,
            intervalId: null,
            stream: null,
            uploadError: false,
            useOldUrl: false,
            duration: 0, // used for syncing the timer with the old audio
            // used for syncing timer if window changes
            startTime: null,
        }
    },
    computed: {
        ...mapGetters({
            styles: 'form/styles',
            permissions: 'audio/permissions',
            recording: 'audio/recording',
            loading: 'audio/loading',
            survey: 'form/survey',
            uploading: 'audio/uploadingRecording',
        }),
        customLabels(){
            if(this.activeStep?.properties?.custom_labels){
                return this.activeStep.properties.custom_labels
            }

            return {}
        },
        recordLabel(){
            // eslint-disable-next-line
            if(!!this.recordedChunks.length){
                return this.customLabels.record_more || this.$t('form.audioRecorderButtonRecordMore');
            }
            return this.customLabels.hit_record || this.$t('form.audioRecorderButtonRecord');
        },
        noCurrentRecording() {
            return !this.mediaRecorder && this.recordedChunks.length === 0 && !this.useOldUrl;
        },
        timeLimit() {
            return this.audioProperties.maxRecordTime * 1000;
        },
        progressValue() {
            if (this.noCurrentRecording) return 0;
            return (this.timeLimit - this.timeLeft) / this.timeLimit * 100
        },
        timer() {
            const timeNumeric = this.noCurrentRecording ? this.audioProperties.maxRecordTime * 1000 : this.timeLeft;
            const minutes = Math.floor(timeNumeric / (60 * 1000))
            const seconds = Math.floor(timeNumeric % (60 * 1000) / 1000)
            const formattedSeconds = String(seconds).length < 2 ? `0${seconds}` : `${seconds}`
            return `${minutes}:${formattedSeconds}`
        },
        previewValue() {
            if (!this.recording && !this.uploading && this.value) {
                return this.value
            }
            return null
        },
        showError() {
            return !this.recording && !this.uploading && this.uploadError
        },
        hideEdits(){
            return this.uploading && this.activeStep?.properties?.enable_probing;
        },
        showSilenceDetector(){
            if(this.survey?.settings?.silence_detector === false){
                return false;
            }
            return !!(this.stream && this.recording)
        }
    },
    methods: {
        ...mapMutations({
            setPermissionsError: 'audio/setPermissionsError',
            setPermission: 'audio/setPermission',
            setPermissionDrawer: 'audio/setPermissionDrawer',
            setUploadingRecording: 'audio/setUploadingRecording',
            showSnackBar: 'showSnackBar',
        }),
        ...mapActions({
            toggleRecording: 'audio/toggleRecording',
            toggleLoading: 'audio/toggleLoading'
        }),
        pauseAllOtherMedias() {
            document.querySelectorAll('audio').forEach(audioElement => {
                audioElement.pause();
            });
            document.querySelectorAll('iframe').forEach(videoElement => {
                videoElement.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
            });
        },
        record(stream) {
            this.mediaRecorder = new MediaRecorder(stream);
            this.mediaRecorder.addEventListener('dataavailable', function (e) {
                if (e.data.size > 0) {
                    this.recordedChunks.push(e.data);
                }
            }.bind(this));

            this.mediaRecorder.addEventListener('start', function () {
                this.toggleLoading(false)
                if (this.recording) {
                    // prevent recording duplicate stream
                    return;
                }
                this.toggleRecording(true)
                this.startTimer()
            }.bind(this))

            this.mediaRecorder.addEventListener('stop', async function () {
                this.toggleRecording(false)
                await this.sendFile()
                amplitude.track('finish_recording', {
                    usedAddMore: !!this.recordedChunks.length,
                    audioChunksNum: this.recordedChunks.length,
                    recordedTimeNum: (this.timeLimit - this.timeLeft) / 1000,
                    formId: this.survey.id,
                    formVersion: this.survey.version,
                    formLanguage: this.survey.language
                });
            }.bind(this));

            this.mediaRecorder.start();
        },
        checkMinResponseRequirement() {
            const timeRecorded = Math.ceil(this.audioProperties.maxRecordTime - this.timeLeft / 1000);
            if (timeRecorded < this.audioProperties.minRecordTime) {
                this.showSnackBar({
                    text: `Almost there! A response of ${timeNumerictoString(this.audioProperties.minRecordTime)} is required. Click to record more.`,
                    color: 'warning',
                    timeout: 3000
                });
            }
        },
        async handleRecordPress() {
            try {
                if (this.recording) {
                    this.mediaRecorder.state === 'recording' && this.mediaRecorder.stop();
                    this.$emit('toggleTextDisable', false);
                    this.stopTimer();
                    return this.checkMinResponseRequirement();
                }
                if (this.activeStep) {
                    amplitude.track('record_press', {text_option: this.activeStep.enable_text_input});
                }
                if (!this.permissionsError) {
                    this.pauseAllOtherMedias();
                    // wait a moment to prevent the audio data right before pause to bleed into the recorder
                    await new Promise(resolve => setTimeout(resolve, 200));

                    if (this.noCurrentRecording) {
                        // update timer if user changes maxRecordTime and decided to record again
                        this.timeLeft = this.audioProperties.maxRecordTime * 1000;
                    }
                    this.$emit('toggleTextDisable', true)
                    this.toggleLoading(true)
                    const stream = window.availableStream || await navigator.mediaDevices.getUserMedia({
                        audio: {
                            noiseSuppression: typeof this.survey?.settings?.noise_suppression === 'boolean' ? this.survey?.settings?.noise_suppression : true,
                            echoCancellation: typeof this.survey?.settings?.echo_cancellation === 'boolean' ? this.survey?.settings?.echo_cancellation : true,
                            autoGainControl: typeof this.survey?.settings?.auto_gain_control === 'boolean' ? this.survey?.settings?.auto_gain_control : true,
                        },
                        video: false
                    });
                    window.availableStream = stream;
                    this.setPermission(true)
                    this.setPermissionsError(false)
                    this.record(stream)
                    this.stream = stream
                    // give enough time for the text response section to dissapear, avoiding tabbing into it
                    // while recording audio.
                    setTimeout(() => {
                        this.$emit('toggleTextDisable', false)
                    }, 1000);
                } else {
                    this.setPermissionDrawer(true)
                }
            } catch (error) {
                console.error(error);
                this.setPermission(false)
                this.setPermissionsError(true)
                this.setPermissionDrawer(true)
                this.toggleLoading(false)
                // TODO: show that recording is not available 
            }
        },
        startTimer(delta = TIME_SLICE) {
            this.startTime = new Date().getTime() - (this.timeLimit - this.timeLeft); // Store the start time
            this.intervalId = setInterval(() => {
                const currentTime = new Date().getTime();
                const elapsedTime = currentTime - this.startTime;
                const remainingTime = this.timeLimit - elapsedTime;
                if (remainingTime <= 0) {
                    this.recording && this.mediaRecorder.state === 'recording' && this.mediaRecorder.stop();
                    this.stopTimer();
                    this.timeLeft = 0;
                } else {
                    this.timeLeft = remainingTime;
                }
            }, delta);
        },
        stopTimer() {
            this.intervalId && clearInterval(this.intervalId);
            this.intervalId = null;
            this.startTime = null; // Reset the start time
        },
        removeAudio() {
            this.recordedChunks = []
            this.timeLeft = this.timeLimit;
            this.mediaRecorder = null
            this.$emit("input", null)
        },
        async sendFile() {
            try {
                let formParams = new FormData();
                this.recordedChunks.forEach(audioChunk => {
                    formParams.append('audioBlobs[]', audioChunk)
                });
                this.useOldUrl && formParams.append('audioUrl', this.value)
                formParams.append('mimeType', this.mediaRecorder.mimeType)
                formParams.append('survey_hash', this.survey.hash)
                this.setUploadingRecording(true)
                this.uploadError = false
                let res = await uploadAudio(formParams)
                this.setUploadingRecording(false)
                const { data } = res;
                if (data && data.url) {
                    this.useOldUrl = false;
                    const timeRecorded = Math.ceil(this.audioProperties.maxRecordTime - this.timeLeft / 1000);
                    data.valid = timeRecorded < this.audioProperties.minRecordTime
                    ? `A ${timeNumerictoString(this.audioProperties.minRecordTime)} response is required for this question. Click to record more.`
                    : true;
                    this.$emit("input", data);
                    this.$emit('valid', data.valid);
                }
            } catch (error) {
                this.setUploadingRecording(false)
                this.uploadError = true
                console.error('audio submit error', error);
            }
        },
        togglesetIntervalDelta() {
            if (!this.intervalId) return;

            this.stopTimer();
            if (document.hidden) {
                this.startTimer(1000);
            } else {
                this.startTimer(); // 100 default
            }
        },
        handleDuration(duration) {
            if(this.useOldUrl && !this.duration){
                this.duration = duration;
                this.timeLeft = this.timeLeft - duration * 1000 ;
            }
        }
    },
    watch: {
        mounted() {
            document.onvisibilitychange = this.togglesetIntervalDelta;
        },
    },
    created(){
        if(this.value && typeof this.value === 'string' && this.recordedChunks.length === 0){
            this.useOldUrl = true;
        }
    }
}
</script>

<style lang="scss" scoped>
.recording-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.relative-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    min-height: 100px;
    touch-action: manipulation;
}

.progress {
    position: absolute;
    pointer-events: none;
}

.visualizer {
    position: absolute;
}

.button:focus::before {
    opacity: 0 !important;
}

.helper-text {
    opacity: 0.7;
    text-align: center;
}

.alert {
    max-width: 350px;
}

.upload-progress {
    width: 300px;
}
</style>