<template>
    <div class="d-flex flex-grow-1 root">
        <survey-header></survey-header>
        <v-sheet :dark="styles.dark" class="main" :style="mainStyles">
            <v-fade-transition>
                <v-carousel 
                    v-if="!submissionStatus"
                    hide-delimiters
                    height="100%"
                    vertical
                    :continuous="false"
                    :show-arrows="false"
                    :value="activeStepNumber"
                    :dark="!styles.dark"
                    :touch="{
                        left: null,
                        right: null,
                    }"
                    :reverse="$vuetify.rtl"
                >
                    <!-- DO NOT MODIFY KEY. This is because keys impact the dynamic render of the loops. -->
                    <step-layout v-for="(step, i) in steps" :key="i" :step="step" />
                    <!-- HACK: we adding fake item to avoid forward animation from last item to first item -->
                    <v-carousel-item :key="steps.length"></v-carousel-item>
                </v-carousel>
            </v-fade-transition>
            <v-fade-transition>
                <survey-submission v-if="submissionStatus"></survey-submission>
            </v-fade-transition>
        </v-sheet>
        <survey-footer v-if="!hideFooter"></survey-footer>
        <audio-permissions />
    </div>
</template>

<script>

import {mapGetters} from 'vuex'
import StepLayout from './StepLayout.vue';
import TopLeftImageLogo from './components/TopLeftImageLogo.vue';
import SurveyHeader from './components/SurveyHeader.vue';
import SurveyFooter from './components/SurveyFooter.vue'
import SurveySubmission from './components/SurveySubmission.vue';
import AudioPermissions from './components/AudioPermissions.vue';

export default {
  
    name: 'SurveyLayout',
    props: {
        hideFooter: {
            default: false,
            type: Boolean
        }
    },
    components: { TopLeftImageLogo, StepLayout, SurveyHeader, SurveyFooter, SurveySubmission, AudioPermissions },
    data: () => ({
      slideNumber: 0,
    }),
    computed: {
        ...mapGetters({
            steps: 'form/steps',
            survey: 'form/survey',
            styles: 'form/styles',
            activeStepNumber: 'form/activeStepNumber',
            activeStep: 'form/activeStep',
            submissionStatus: 'form/submissionStatus',
        }),
        mainStyles(){
            return `color: ${this.styles.backgroundTextColor} ; background-color: ${this.styles.background}; padding-bottom: ${this.hideFooter? '0px': '62px'}`
        },
    }
}
</script>

<style lang="scss">
    :root {
        --overflow: hidden;
    }
</style>

<style lang="scss" scoped>
    .root{
        height: 100%
    }
    .main{
        padding-top: 62px;
        width: 100%;
        height: 100%;
    }
    .slide{
        overflow: scroll;
    }
    
</style>
