<template>
    <v-navigation-drawer :height="height"  :style="`max-height: ${maxheight}`" width="400" absolute bottom temporary v-model="openDrawer">
        <v-fade-transition hide-on-leave>
            <v-container v-if="!permissionsError" class="d-flex flex-column pa-6">
                <img class="microphone-logo mb-6" :src="require(`../../../assets/svg/microphone-illustration.svg`)" />
                <div class="text-h6">{{ $t('form.audioPermissionHeader') }}</div>
                <div class="mt-2 text-body-1">{{ $t('form.audioPermissionMessage') }}</div>
                <div class="mt-16 d-flex flex-column">
                    <v-btn :loading="loading" :disabled="loading" large elevation="0" color="primary" @click="handleAnswer(true)">{{ $t('form.audioPermissionAcceptButton') }}</v-btn>
                    <v-btn class="mt-2" @click="handleAnswer(false)" text >{{ $t('form.audioPermissionDenyButton') }}</v-btn>
                </div>
            </v-container>
        </v-fade-transition>
        <v-fade-transition hide-on-leave>
            <v-container v-if="permissionsError" class="d-flex flex-column pa-6">
                <img class="microphone-logo mb-6" :src="require(`../../../assets/svg/microphone-not-available.svg`)" />
                <div class="text-h6">{{ $t('form.audioPermissionErrorHeader') }}</div>
                <div class="mt-2 text-body-1">{{ $t('form.audioPermissionErrorMessage') }}</div>
                <div class="mt-16 d-flex flex-column">
                    <v-btn class="mt-2" @click="openDrawer=false" elevation="0"  >{{ $t('form.audioPermissionErrorButton') }}</v-btn>
                </div>
            </v-container>
        </v-fade-transition>
    </v-navigation-drawer>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    data(){
        return {
            loading: false
        }
    },
    computed: {
        ...mapGetters({
            showAudioPermissionDrawer: 'audio/permissionDrawer',
            permissionsError: 'audio/permissionsError'
        }),
        

        openDrawer: {
            get(){
                return this.showAudioPermissionDrawer
            },
            set(val){
                this.setPermissionDrawer(val)
            }
        },
        height(){
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '80vh'
                case 'sm': return '60vh'
                case 'md': return '60vh'
                case 'lg': return '100vh'
                case 'xl': return '100vh'
            }
            return '100vh'
        },
        maxheight(){
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '80%'
                case 'sm': return '60%'
                case 'md': return '60%'
                case 'lg': return '100%'
                case 'xl': return '100%'
            }
            return '100%'
        }
    },
     methods: {
        ...mapMutations({
            setPermissionDrawer:  'audio/setPermissionDrawer'
        }),
        ...mapActions({
            updatePermissions: 'audio/updatePermissions'
        }),
        async handleAnswer(answer){
            this.loading = true
            await this.updatePermissions(answer)
            this.loading = false
        }
    },
}
</script>

<style scoped>
    .microphone-logo{
        align-self: center;
        max-width: 200px;
    }
</style>