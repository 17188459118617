<template>
    <div class="flower" :style="`transform: ${transform}; background-color: ${styles.dark?' #FFFFFF40': '#00000040'}`"></div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Visualizer',
    props: {
        stream: {
            required: true 
        }
    },
    data(){
        return {
            avg: 255,
        }
    },
    computed: {
        ...mapGetters({
            styles: 'form/styles',
        }),
        transform(){
            const scale = this.avg / 255 *2
            return `scale(${scale + 1})`
        }
    },
    async mounted(){
        if(this.stream){
            const context = window.availableAudioContext || new (window.AudioContext || window.webkitAudioContext)();
            window.availableAudioContext = context;

            var analyser = window.availableAudioAnalyzer || context.createAnalyser();
            window.availableAudioAnalyzer = analyser;

            analyser.fftSize = 1024;
            var bufferLength = analyser.frequencyBinCount;

            var frequencies = 15;

            var mediaStreamSource = window.availableAudioMediaStreamSource || context.createMediaStreamSource(this.stream);
            window.availableAudioMediaStreamSource = mediaStreamSource;

            mediaStreamSource.connect(analyser);

            var dataArray = new Uint8Array(bufferLength);

            const parent = this

            // eslint-disable-next-line
            function renderFrame() {
                requestAnimationFrame(renderFrame); // Takes callback function to invoke before rendering


                analyser.getByteFrequencyData(dataArray); // Copies the frequency data into dataArray
                // Results in a normalized array of values between 0 and 255
                // Before this step, dataArray's values are all zeros (but with length of 8192)

                let sum = 0;

                for(let i = 0; i < bufferLength; i++) {
                    if(i < frequencies) sum += dataArray[i];
                }

                //Change CSS according to threshold
                parent.avg = sum / frequencies;
            }
            renderFrame()
        }
    },
}
</script>

<style lang="scss" scoped>
    .flower{
       width: 35px;
       height: 35px;
       background-color: rgba($color: #000000, $alpha: 0.2);
       border-radius: 100%;
    }
</style>