<template>
    <v-card light class="text-caption pa-2 mb-2" outlined color="white">
        <transition name="fade" mode="out-in">
            <div :key="validationMessage">
                <v-icon class="mr-1" :color="validationMessage === true ? 'success' : ''" small>{{
                    icon
                }}</v-icon>
                {{ validationMessageText }}
            </div>
        </transition>
    </v-card>
</template>

<script>
export default {
    name: "MatrixValidationMessage",
    props: {
        validationMessage: {
            default: null
        }
    },
    computed: {
        icon() {
            return this.validationMessage === true
                ? "mdi-check-circle"
                : "mdi-alert-circle";
        },
        iconColor() {
            return this.validationMessage === true ? "success" : "";
        },
        validationMessageText() {
            return this.validationMessage === true
                ? this.$t("form.matrixValidationMessage")
                : this.validationMessage;
        }
    }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
}
</style>
