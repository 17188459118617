<template>
    <div v-if="availableLanguages.length > 1">
        <v-btn v-if="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.xsOnly" @click="dialog = true" outlined :class="['language-selector-button-text px-1', {'mr-3': showCounting}]">
            <v-icon class="mr-1" small left>mdi-web</v-icon>
            {{ languageSelectorButtonText }}
        </v-btn>
        <v-btn v-else @click="dialog = true" outlined :class="[{'mr-3': showCounting}]">
            <v-icon left>mdi-web</v-icon>
            {{ languageSelectorButtonText }}
        </v-btn>
        <v-dialog width="90%" max-width="400" v-model="dialog">
            <v-card min-height="50vh" class="py-2">
                <!-- Search input -->
                <v-text-field
                    v-model="search"
                    :placeholder="$t('form.searchLanguage')"
                    prepend-inner-icon="mdi-magnify"
                    hide-details
                    clearable
                    class="mx-4 mt-4"
                    outlined
                    dense
                />
                
                <!-- Filtered Autosuggest menu -->
                <v-list>
                    <v-list-item @click="changeLanguage(languageCode)" v-for="languageCode in filteredLanguages" :key="languageCode">
                        <v-list-item-title >
                            {{ getLanguageTitle(languageCode) }}
                            <v-icon  v-if="currentLanguageCode === languageCode" right>mdi-check</v-icon>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { languagesMap, languagesNativeMap } from '@/configs/languages';

export default {
    name: 'LanguageSelector',
    data() {
        return {
            dialog: false,
            search: '',
        };
    },
    props: {
        showCounting: {
            required: true,
        },
    },
    methods: {
        changeLanguage(language) {
            if (language === this.currentLanguageCode) {
                this.dialog = false;
                return;
            }
            // take existing url and replace lang query param
            const url = new URL(window.location.href);
            url.searchParams.set('lang', language);
            if (language === this.survey.base_language) {
                url.searchParams.delete('lang');
            }
            window.location = url.href;
        },
        getLanguageTitle(languageCode) {
            if (languagesNativeMap[languageCode] && languagesMap[languageCode]) {
                return `${languagesNativeMap[languageCode]} (${languagesMap[languageCode]})`;
            }
            return languageCode;
        }
    },
    computed: {
        ...mapGetters({
            survey: 'form/survey',
        }),
        currentLanguageCode() {
            return this.$i18n.locale;
        },
        availableLanguages() {
            if (this.survey?.settings?.translation_languages) {
                let languages = [];
                // get first letters of language codes
                let baseLanguage = this.survey.language.substring(0, 2);
                if (this.survey.base_language) {
                    baseLanguage = this.survey.base_language;
                }
                languages.push(baseLanguage);
                languages.push(...this.survey.settings.translation_languages);

                // Remove duplicates using Set
                return [...new Set(languages)];
            }

            return [];
        },
        languageSelectorButtonText(){
            if(this.$vuetify.breakpoint.xsOnly){
                return this.currentLanguageCode
            }else{
                return `${languagesNativeMap[this.currentLanguageCode]} (${this.currentLanguageCode})`
            }
        },
        // Filtered languages based on search term
        filteredLanguages() {
            const searchLower = this.search.toLowerCase();
            if (!searchLower) {
                return this.availableLanguages;
            }
            return this.availableLanguages.filter(languageCode =>
                this.getLanguageTitle(languageCode).toLowerCase().includes(searchLower)
            );
        }
    }
};
</script>

<style lang="scss" scoped>
.language-selector-button-text {
    text-transform: capitalize;
}
</style>