<template>
    <div  ref="observingNode" :class="['d-flex', 'flex-column', { 'align-center': isWelcomeStep }]">
        <div v-if="showPrivacyPolicyText" class="privacy-text text-caption mt-10" v-html="privacyText"
            :style="{ color: styles.backgroundTextColor }"></div>
        <div v-if="!hidden" :class="[{ 'mt-10': !showPrivacyPolicyText }, { 'mt-4': showPrivacyPolicyText }, 'd-flex flex-column']">
            <v-btn :id="isLastQuestion || currentAnswerValueWillTriggerSubmitAction? 'finish-button' : null " :loading="loading" min-width="200" :disabled="disabled" :width="$vuetify.breakpoint.xs && !singleQuestionWidget ? '100%' : '200'"
                elevation="0" @click="navigateToNextSlide" large :color="styles.button.background"
                :style="`color: ${styles.button.color}`" :class="{ 'flash': isAnimated }">
                <v-icon v-if="buttonContent.icon" :color="styles.button.color" left>{{ buttonContent.icon }}</v-icon>
                {{ buttonContent.text }}
            </v-btn>
        </div>
        <v-scale-transition>
            <v-app-bar :color="styles.background"
                :style="`border-top: thin solid ${styles.dark ? '#ffffff30' : '#00000030'}`" v-if="showOverlayNextButton && !hidden"
                absolute elevation="0" :bottom="true">
                <v-container>
                    <v-spacer class="d-none d-sm-flex"></v-spacer>
                    <v-btn :loading="loading" min-width="200" :disabled="disabled"
                        :width="$vuetify.breakpoint.xs ? '100%' : '200'" elevation="0" @click="navigateToNextSlide" large
                        :color="styles.button.background" :style="`color: ${styles.button.color};`"
                        :class="{ 'flash': isAnimated }">
                        <v-icon v-if="buttonContent.icon" :color="styles.button.color" left>{{
                            buttonContent.icon
                        }}</v-icon>
                        {{ buttonContent.text }}
                    </v-btn>
                </v-container>
            </v-app-bar>
        </v-scale-transition>

    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { isTruthy } from '../../../configs/operators';
import { YESNO, MULTIPLE_CHOICE, DATE_PICKER, DROPDOWN, RATING, NET_PROMOTER_SCORE } from '../../../configs/questionTypes';
import Handlebars from "handlebars";

const SLIDE_CHANGE_ANIMATION_DURATION = 300

export default {
    props: {
        step: {
            type: Object
        },
    },
    data() {
        return {
            isIntersecting: true,
            animating: false,
            hasResponseAlreadySet: false,
            currentAnswerValueWillTriggerSubmitAction: false,
        }
    },
    // avoid showing floating button during slide switch
    watch: {
        answers() {
            this.checkCurrentAnswerValueWillTriggerSubmitAction();
        },
        activeStep() {
            this.checkCurrentAnswerValueWillTriggerSubmitAction();
        },
        activeStepNumber() {
            this.animating = true;
            setTimeout(() => {
                this.animating = false;
            }, SLIDE_CHANGE_ANIMATION_DURATION);
        },
        disabled(newValue) {
            if (newValue) return;
            if ([YESNO, MULTIPLE_CHOICE, DATE_PICKER, DROPDOWN, RATING, NET_PROMOTER_SCORE].includes(this.activeStep.type)) {
                if (this.hasResponseAlreadySet || this.isLastQuestion || this.currentAnswerValueWillTriggerSubmitAction || this.survey.settings.auto_switch === false) {
                    return;
                }
                setTimeout(this.showNextSlide, 1000);
            }
        }
    },
    computed: {
        ...mapGetters({
            survey: 'form/survey',
            styles: 'form/styles',
            activeStep: 'form/activeStep',
            activeStepNumber: 'form/activeStepNumber',
            previousStepNumber: 'form/previousStepNumber',
            questionSteps: 'form/questionSteps',
            answers: 'form/answers',
            recording: 'audio/recording',
            localization: 'form/localization',
            uploadingRecording: 'audio/uploadingRecording',
            audioLoading: 'audio/loading',
            validatingAnswer: 'form/validatingAnswer',
            dialogues: 'form/dialogues',
            passingVariables: 'form/passingVariables',
            loopLists: 'form/loopLists',
            singleQuestionWidget: 'form/singleQuestionWidget',
        }),

        loading() {
            return this.step && this.validatingAnswer === this.step.id
        },

        showOverlayNextButton() {
            if(this.singleQuestionWidget){
                return false
            }
            return !this.animating && (this.isIntersecting === false);
        },

        isLastQuestion() {
            if(!!this.questionSteps.length && !!this.activeStep && (this.activeStep.id === this.questionSteps[this.questionSteps.length - 1].id)){
                // check for loop items
                if(this.loopLists && this.loopLists[this.activeStep.questionId]){
                    // check if its the last loop item
                    return this.activeStep.loopIndex === this.loopLists[this.activeStep.questionId].length - 1

                }

                return true;
            }
            return false
        },

        showPrivacyPolicyText() {
            return this.activeStep && this.activeStep.component === 'WelcomeStep' && this.survey.privacy_text && this.survey.privacy_text !== '<p></p>'
        },
        privacyText(){
            if(this.survey && this.survey.privacy_text){
                try {
                    var compiled = Handlebars.compile(this.survey.privacy_text);
                    return compiled(this.passingVariables);
                } catch (error) {
                    console.log(error);
                    return this.survey.privacy_text
                }
            }
            return ''
        },

        hidden(){
            // if disabled by query param
            if(this.$route.query && this.$route.query.v_hide_next_button === 'true'){
                return true
            }
            if(this.activeStep && !this.activeStep.is_optional && this.activeStep.properties && this.activeStep.properties.enable_probing){
                if(!this.dialogues || !this.dialogues[this.activeStep.id] || this.dialogues[this.activeStep.id].length === 0 || !this.dialogues[this.activeStep.id][this.dialogues[this.activeStep.id].length - 1].sufficient){
                    return true;
                }
            }
            return false;
        },

        disabled() {
            if (this.loading) {
                return true
            }
            if (this.recording || this.uploadingRecording || this.audioLoading) {
                return true
            }
            // If not the last question

            if (this.activeStep.component === 'QuestionStep') {
                if (!this.activeStep.is_optional) {
                    if (!this.answers[this.activeStep.id] || this.answers[this.activeStep.id].valid !== true) {
                        return true
                    }
                }
            }

            return false
        },
        hasNavigatedBack() {
            if (!this.previousStepNumber) return false;
            if (this.previousStepNumber > this.activeStepNumber) return true;
            return false;
        },
        isAnimated() {
            if (this.$route.name === 'dashboard.voiceform.show.question') return false;
            if (this.disabled) return false;
            if (this.isWelcomeStep) return false;
            if (this.isLastQuestion || this.currentAnswerValueWillTriggerSubmitAction) return false;
            if (this.hasNavigatedBack) return false;
            if (this.hasResponseAlreadySet) return false;
            if (this.activeStep.is_optional) return false;

            if ([YESNO, MULTIPLE_CHOICE, DATE_PICKER, DROPDOWN, RATING, NET_PROMOTER_SCORE].includes(this.activeStep.type)) {
                return this.survey?.settings.auto_switch !== false
            }
            return false;
        },
        isWelcomeStep() {
            return !!this.activeStep && this.activeStep.component === 'WelcomeStep'
        },
        isDescriptionSlide() {
            return !!this.activeStep && this.activeStep.type === 'description-slide'
        },
        buttonContent() {
            if (this.singleQuestionWidget) {
                return {
                    icon: false,
                    text: this.getButtonText('submit_answer_button_translation', 'submit_answer_button', 'form.submitAnswerButton'),
                }
            }

            if (this.isWelcomeStep) {
                return {
                    icon: false,
                    text: this.getButtonText('start_button_label_translation', 'start_button_label', 'form.startSurveyButton'),
                }
            }

            if (this.isDescriptionSlide && !this.isLastQuestion) {
                return {
                    icon: false,
                    text: this.$t('form.continueButton')
                }
            }

            if (this.isLastQuestion || this.currentAnswerValueWillTriggerSubmitAction) {
                return {
                    icon: false,
                    text: this.getButtonText('finish_translation', 'finish', 'form.finishButton'),
                }
            }

            return {
                icon: 'mdi-check-circle',
                text: this.getButtonText('next_translation', 'next', 'form.nextButton')
            }
        }
    },
    methods: {
        ...mapActions({
            showNextSlide: 'form/showNextSlide',
            submitAnswers: 'form/submitAnswers',
        }),
        getButtonText(translationKey, localizationKey, fallbackKey){
            const isEnglish = this.$i18n.locale === 'en';
            return this.localization[translationKey] || (isEnglish && this.localization[localizationKey] ? this.localization[localizationKey] : this.$t(fallbackKey));
        },
        navigateToNextSlide() {
            if (this.isAnimated) {
                return;
            }

            this.showNextSlide();
        },
        handleEnterKeydown(e) {
            window.removeEventListener('keyup', this.handleEnterKeydown)
            if (e.key === "Enter"
                && !this.disabled
                && document.activeElement.tagName !== "BUTTON"
                && document.activeElement.tagName !== "TEXTAREA"
                && !document.activeElement.isContentEditable) {
                if (this.isAnimated) {
                    return;
                }
                if (!this.isLastQuestion) {
                    return this.showNextSlide();
                }
                this.submitAnswers();
            }
            window.addEventListener('keyup', this.handleEnterKeydown);
        },
        onIntersect(entries) {
            this.isIntersecting = entries[0].intersectionRatio >= 0.8;
        },
        setHasResponseAlreadySet() {
            if (!this.answers || !this.step) {
                this.hasResponseAlreadySet = false;
                return;
            }
            const currentAnswer = Object.entries(this.answers).find(([key]) => {
                return key === this.step.id;
            });
            if (!currentAnswer) {
                this.hasResponseAlreadySet = false;
                return;
            }
            if (currentAnswer[1] && currentAnswer[1].valid) {
                this.hasResponseAlreadySet = true;
                return;
            }
            this.hasResponseAlreadySet = false;
        },
        checkCurrentAnswerValueWillTriggerSubmitAction() {
            if (this.activeStep && this.activeStep.actions && this.activeStep.actions.length) {
                for (let index = 0; index < this.activeStep.actions.length; index++) {
                    const action = this.activeStep.actions[index];
                    if (['submit', 'drop-off'].includes(action.type)) {
                        const conditionMap = action.conditions.map(condition => {
                            const answerObj = this.answers[condition.variable.question_ref]
                            const answer = answerObj && answerObj.answer
                            const result = isTruthy(condition, answer && (!answer.value && answer.value !== 0 ? answer.url : answer.value))
                            return {
                                ...condition,
                                result
                            }
                        });
                        const processedAndOpResults = conditionMap.reduce((reducedArray, currentCondition) => {
                            if (reducedArray.length) {
                                const previousCondition = reducedArray[reducedArray.length - 1]
                                const previousLogOp = previousCondition.log_op || 'and';
                                if (previousLogOp === 'and') {
                                    reducedArray[reducedArray.length - 1].result = previousCondition.result && currentCondition.result
                                } else {
                                    reducedArray.push(currentCondition)
                                }
                            } else {
                                reducedArray.push(currentCondition)
                            }
                            return reducedArray
                        }, []);
                        const finalResult = processedAndOpResults.reduce((previousValue, currentCondition) => {
                            return previousValue || currentCondition.result
                        }, false);
                        if (finalResult) {
                            this.currentAnswerValueWillTriggerSubmitAction = true;
                            return;
                        }
                    }

                }
            }
            this.currentAnswerValueWillTriggerSubmitAction = false;
        },

        handleMessage(event) {
            if (event.data === 'voiceform.next') {
                this.navigateToNextSlide();
            }
        }
    },
    mounted() {
        setTimeout(() => {
            if (this.$refs.observingNode) {
                const observer = new IntersectionObserver(this.onIntersect, { threshold: [0, 0.8, 1.0] });
                observer.observe(this.$refs.observingNode);
            }
        }, SLIDE_CHANGE_ANIMATION_DURATION);

        window.addEventListener('keyup', this.handleEnterKeydown);

        this.setHasResponseAlreadySet();
        this.checkCurrentAnswerValueWillTriggerSubmitAction();

        window.addEventListener('message', this.handleMessage);
    },
    destroyed() {
        window.removeEventListener('keyup', this.handleEnterKeydown);
        window.removeEventListener('message', this.handleMessage);
    }
}
</script>
<style lang="scss" scoped>
.privacy-text {
    text-align: center;
}

@keyframes flash {

    0%,
    50%,
    100% {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}

.flash {
    animation: flash 0.82s cubic-bezier(.36, .07, .19, .97) both;
}
</style>
