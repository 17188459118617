<template>
    <v-expand-transition>
        <v-alert
            v-if="showSilenceError"
            class="my-sm-auto alert align-self-center align-self-sm-start"
            style="margin-top: 16px !important"
            color="red"
            close-text="Close Alert"
            :dark="styles.dark"
            :text="!styles.dark"
        >
            <v-row align="center">
                <v-col class="grow">
                    {{ $t('form.silenceDetectorError') }}
                </v-col>
                <v-col class="shrink">
                    <v-btn @click="showSilenceError=false" :color="styles.dark? 'white' : 'error'" class="my-n4" elevation="0" icon><v-icon>mdi-close-circle</v-icon></v-btn>
                </v-col>
            </v-row>
        </v-alert>
    </v-expand-transition>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'SilenceDetector',
    props: {
        stream: {
            required: true,
        }
    },
    data(){
        return {
            soundDetected: false,
            showSilenceError: false,
        }
    },
    computed: {
         ...mapGetters({
            styles: 'form/styles',
        }),
    },
    async mounted(){
        if(this.stream){
            this.showSilenceError = false;
            setTimeout(() => {
                if(this.soundDetected) {
                    this.soundDetected = !this.soundDetected;
                } else {
                    this.showSilenceError = true;
                }
            }, 3000)
            const context = new (window.AudioContext || window.webkitAudioContext)();
            var analyser = context.createAnalyser();

            analyser.fftSize = 1024;
            var frequencies = 15;
            var bufferLength = analyser.frequencyBinCount;

            var mediaStreamSource = context.createMediaStreamSource(this.stream);
            mediaStreamSource.connect(analyser);

            var dataArray = new Uint8Array(bufferLength);

            // eslint-disable-next-line
            function renderFrame() {
                
                if(this.soundDetected) {
                    return;
                }
                requestAnimationFrame(renderFrame); // Takes callback function to invoke before rendering


                analyser.getByteFrequencyData(dataArray); // Copies the frequency data into dataArray
                // Results in a normalized array of values between 0 and 255
                // Before this step, dataArray's values are all zeros (but with length of 8192)

                let sum = 0;

                for(let i = 0; i < bufferLength; i++) {
                    if(i < frequencies) sum += dataArray[i];
                }

                if(sum / frequencies > 0){
                    this.soundDetected = true;
                }
            }

            // eslint-disable-next-line
            renderFrame = renderFrame.bind(this)

            renderFrame()
        }
    },

    watch: {
        soundDetected(val){
            if(val && this.showSilenceError){
                this.showSilenceError = false;
            }
        }
    }
}
</script>