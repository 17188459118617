<template>
    <!-- TODO remove such layout -->
    <form v-on:submit.prevent class="page">
        <div class="content">
            <v-fade-transition hide-on-leave >
                <div class="d-flex flex-grow-1 align-center justify-center" v-if="loading && !error">
                    <v-card elevation="0" class="pa-6" color="grey lighten-4 rounded-xl">
                        <v-progress-circular
                            :size="64"
                            indeterminate
                            color="primary"
                        ></v-progress-circular>
                    </v-card>
                </div>
                <empty-form v-else-if="empty"></empty-form>
                <expired-form v-else-if="expired"></expired-form>
                <error-not-published-form v-else-if="notPublishedError" :loading="loading" @retry="handleRetry"></error-not-published-form>
                <error-form v-else-if="error" :loading="loading" @retry="handleRetry"></error-form>
                <restore-survey-dialog v-else-if="showFormResumeDialog" :survey="survey" />
                <survey-component v-else-if="survey && !loading" :survey="survey" />
            </v-fade-transition>
        </div>
        <input id="leadid_token" name="leadid_token" type="hidden" value="" />
    </form>
</template>

<script>
import SurveyComponent from './SurveyComponent.vue'
import axios from 'axios';
import { mapActions, mapGetters} from 'vuex'
import ExpiredForm from './components/ExpiredForm.vue';
import EmptyForm from './components/EmptyForm.vue';
import ErrorForm from './components/ErrorForm.vue';
import ErrorNotPublishedForm from './components/ErrorNotPublishedForm.vue';
import {loadInboundsScripts} from '../../utils/scriptLoader'
import * as amplitude from '@amplitude/analytics-browser';
import RestoreSurveyDialog from './RestoreSurveyDialog.vue';
import {getSurveyFromLocalStorage} from '../../utils/formLocalStorage';
import {loadLanguageAsync} from '@/i18n'

    
export default {
    components: { SurveyComponent, ExpiredForm, ErrorForm, EmptyForm, RestoreSurveyDialog, ErrorNotPublishedForm },
    name: "Survey",
    data: () => ({
        loading: false,
        expired: false,
        empty: false,
        error: false,
        survey: null,
        notPublishedError: false
    }),
    computed:{
        ...mapGetters({
            resumed: 'form/resumed'
        }),
        showFormResumeDialog() {
            // coming from partial response
            if(this.$route && this.$route.query && this.$route.query.v_pr){
                return false;
            }

            if(this.resumed){
                return false;
            }
            
            // if there is an existing partial response with answers
            if(this.survey?.fsDoc?.answers && Object.keys(this.survey.fsDoc.answers).length > 0){
                return true;
            }


            // if there is something in local storage
            const localStorageSurvey = getSurveyFromLocalStorage(this.survey.id);
            if (!localStorageSurvey) {
                return false;
            }
            if (localStorageSurvey.answers && Object.keys(localStorageSurvey.answers).length > 0) {
                return true;
            }
            return false;
        }
    },
    methods: {
        ...mapActions({
            initForm: 'form/initForm',
            updateRespondentIp: 'form/updateRespondentIp',
            updateDocumentWithData: 'form/updateDocumentWithData',
        }),
        isFormResumable(){

            // coming from partial response resume link
            if(this.$route && this.$route.query && this.$route.query.v_pr){
                return true;
            }

            // if there is an existing partial response with answers
            if(this.survey?.fsDoc?.answers && Object.keys(this.survey.fsDoc.answers).length > 0){
                return true;
            }

            // check local storage
            const localStorageSurvey = getSurveyFromLocalStorage(this.survey.id);

            // if there is a partial response in local storage and it has answers
            if(localStorageSurvey && localStorageSurvey.answers && Object.keys(localStorageSurvey.answers).length > 0){
                return true;
            }

            return false;
        },
        async loadSurvey(){
            try {
                this.loading = true
                const params = {};
                if(window.pubUUID){
                    params.pub_uuid = window.pubUUID;
                }
                if(this.$route.query && this.$route.query.v_pr){
                    params.accessToken = this.$route.query.v_pr;
                }
                if(this.$route.query && this.$route.query.lang){
                    params.lang = this.$route.query.lang;
                }
                const { data: surveyData } = await axios.get(`/api/surveys-public/${this.$route.params.hash}`, {
                    params: params
                });

                if(surveyData && surveyData.language){
                    const languageCode = surveyData.language.split('-')[0]
                    // enabling rtl
                    if(['ar', 'ur', 'fa', 'he'].includes(languageCode)){
                        // set html dir to rtl
                        document.documentElement.dir = 'rtl'
                        this.$vuetify.rtl = true
                    }
                    if(languageCode !== 'en'){
                        await loadLanguageAsync(languageCode)
                    }
                }

                const endDate = surveyData.ends_at ? new Date(surveyData.ends_at) : null;
                if (endDate && endDate.getTime() <= new Date().getTime()) { 
                    this.expired = true
                }

                if(surveyData && surveyData.questions){
                    const question = surveyData.questions.find(question => question.type !== 'description-slide')
                    if(!question){
                        this.empty = true
                    }
                }
                this.survey = surveyData
                if(surveyData && surveyData.inbounds){
                    loadInboundsScripts()
                    this.updateRespondentIp()
                }
                if(this.notPublishedError){
                    this.notPublishedError = false
                }
                surveyData.resumable = this.isFormResumable();
                this.initForm(surveyData)
                this.loading = false
                this.error = false
            } catch (error) {
                console.error(error)

                // check if the survey is not published
                if(error.response && error.response.status === 422 && error.response.data && error.response.data.reason === 'not_published'){
                    this.notPublishedError = true
                }
                this.loading = false
                this.error = true
            }
        },
        handleRetry(){
            this.loadSurvey()
        },
    },
    watch: {
        '$store.state.form.dialogues': {
            handler(){
                this.updateDocumentWithData({dialogues: this.$store.state.form.dialogues})
            },
            deep: true
        },
        '$store.state.form.history': {
            handler(){
                this.updateDocumentWithData({history: this.$store.state.form.history})
            },
            deep: true
        }
    },
    created(){
        amplitude.init(process.env.VUE_APP_AMPLITUDE, undefined, { defaultTracking: { sessions: true, pageViews: true, formInteractions: true, fileDownloads: true }});
        this.loadSurvey();
    }
}
</script>

<style scoped>
.page {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    border: 0;
    width: 100%;
    height: 100%;
    display: flex;
}
.content {
    display: flex;
    align-items: stretch;
    width: 100%;
}
</style>