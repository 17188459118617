<template >
    <survey-layout :hideFooter="isPreview || hideBottomNavBar"></survey-layout>
</template>

<script>
import { initSurvey } from "@/mixins/initSurvey";
import SurveyLayout from './SurveyLayout.vue';

import {mapActions, mapGetters, mapMutations} from 'vuex'

export default {
    props: {
        survey: {
            required: true,
        },
        activeStepForce: {
            required: false,
        },
        isPreview: {
            required: false,
            default: false
        }
    },
    components: {
        SurveyLayout,
    },
    mixins: [initSurvey],
    name: "Survey",
    methods: {
        ...mapActions({
            forceStep: 'form/forceStep',
            callSurveyViewStat: 'form/callSurveyViewStat',
            calculateOnScreenTime: 'form/calculateOnScreenTime',
        }),
        ...mapMutations({
            setPreview: 'form/setPreview',
            setTotalOnScreenTime: 'form/setTotalOnScreenTime',
            setFocusedAtTime: 'form/setFocusedAtTime',
            
        }),
        onWindowFocus() {
            this.setFocusedAtTime(new Date().getTime());
        },
        onWindowBlur() {
            if(!this.recording){
                this.calculateOnScreenTime();
            }
        },
        onWindowBeforeUnload() {
            if(!this.recording){
                this.calculateOnScreenTime();
            }
        },
    },
    computed: {
        ...mapGetters({
            recording: 'audio/recording',
        }),
        hideBottomNavBar(){
            return !!(this.survey && this.survey.settings && this.survey.settings.is_nav_arrows_hidden && this.survey.settings.hide_branding)
        }
    },

    watch: {
        'survey.settings': {
            handler(){
                this.forceStep(this.activeStepForce)
            },
        },

        activeStepForce: {
            deep: true,
            handler(activeStep){
                this.forceStep(activeStep)
            },
        },
        isPreview: {
            immediate: true,
            handler(newValue){
                this.setPreview(!!newValue)
            }
        }        
    },
    created() {
        this.$root.localization = this.survey.settings.localization;
        this.callSurveyViewStat()

         // tracking page focus time
        window.addEventListener('focus', this.onWindowFocus);
        window.addEventListener('blur', this.onWindowBlur);
        window.addEventListener('beforeunload', this.onWindowBeforeUnload);
    },
    beforeDestroy(){
        window.removeEventListener('focus', this.onWindowFocus);
        window.removeEventListener('blur', this.onWindowBlur);
        window.removeEventListener('beforeunload', this.onWindowBeforeUnload);
    },
};
</script>

<style lang="scss" scoped>
img {
    width: 100%;
    height: 100%;
}

.survey {
    position: relative;
    z-index: 0;
    font-family: Poppins;
    font-size: 16px;
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;

    &-header {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        justify-items: start;
        width: 100%;
    }

    &-img {
        height: 2em;
        width: 2em;
        border-radius: 8px;
        overflow: hidden;
    }
}
</style>
