<template>
    <div v-if="!hideBranding">
        <v-fade-transition hide-on-leave>
            <a v-if="!large && !xSmall" :style="{color: styles.backgroundTextColor, opacity: opacity}" href="https://www.voiceform.com/book-demo?utm_source=powered-by" target="_blank" class="text-caption text-sm-body-1 order-sm-1 powered-by ma-n4 ma-sm-4 pa-4 ml-n4 ml-sm-4 small-link">{{ $t('form.brandingPoweredBy') }} <b>Voiceform</b></a>
        </v-fade-transition>
        <v-fade-transition hide-on-leave>
            <a v-if="!large && xSmall" :style="{color: styles.backgroundTextColor, opacity: opacity, fontSize: '10px'}" href="https://www.voiceform.com/book-demo?utm_source=powered-by" target="_blank" class="powered-by small-link">{{ $t('form.brandingPoweredBy') }} <b>Voiceform</b></a>
        </v-fade-transition>
        <v-fade-transition  hide-on-leave>
            <div v-if="large && !xSmall" class="promo-container text-caption text-sm-body-1" >
                <span v-html="$t('form.brandingPromoWhySurvey', { product: 'Voiceform' })"></span>
                <v-btn elevation="0" class="ma-2" small :color="styles.button.background" :style="{color: styles.button.color, opacity: opacity}" href="https://www.voiceform.com/book-demo?utm_source=powered-by" target="_blank">
                    {{ $t('form.brandingPromoGetStarted') }}
                </v-btn>
            </div>
        </v-fade-transition>
    </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
    name: 'PoweredBy',
    props: {
        large: Boolean,
        xSmall: Boolean,
        opacity: {
            default: 0.8,
            type: Number
        }
    },
    computed: {
        ...mapGetters({
            styles: 'form/styles',
            survey: 'form/survey'
        }),

        hideBranding(){
            return this.survey && this.survey.settings && this.survey.settings.hide_branding
        }
    }
}
</script>

<style lang="scss" scoped>
    .small-link{
        opacity: 0.8;
    }
    .promo-container{
        text-align: center;
        align-items: center;
        flex-direction: column;
    }
</style>