<template>
    <img
        v-if="survey.top_left_file_url"
        :src="survey.top_left_file_url"
        alt="Logo"
        class="logo-image"
    />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'TopLeftImageLogo',
    computed: {
        ...mapGetters({
            survey: 'form/survey'
        }),
        imageSource: () => this.survey && this.survey.top_left_file_url
    }
}
</script>

<style lang="scss" scoped>
.logo-image {
    height: auto;
    max-height: 40px;
    width: auto;
    max-width: 100%;
    display: block;
    margin: auto;
    object-fit: contain;
}
</style>