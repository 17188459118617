<template>
    <v-text-field
        :label="$t('form.nameLabel')"
        v-model="answer"
        :dark="dark"
        :color="survey.style.color"
        @change="$emit('change')"
        outlined
        :rules="[rules.name]"
        validate-on-blur
        autocomplete="name"
        clearable
    ></v-text-field>
</template>

<script>
import { mapGetters } from 'vuex';
const pattern =/^(?=.*\s)[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
export default {
    name: "Name",
    props: {
        value: {
            required: true,
        },
        dark: {
            required: true,
        },
    },
    data() {
        return {
            rules: {
                name: this.validateName,
            },
        };
    },
    computed: {
        ...mapGetters({
            survey: 'form/survey'
        }),
        answer: {
            get: function () {
                return this.value ? this.value.value : "";
            },
            set: function (val) {
                if(val && val.trim()){
                    this.$emit("input", { answer: { type: "name", value: val }, valid: this.validateName(val)});
                }else{
                    this.$emit("input", null);
                }
            },
        },
    },
    methods: {
        validateName(value){
            if(this.survey && this.survey.inbounds){
                return !value || pattern.test(value) || this.$t('form.nameInvalid');
            }
            return true
        }
    },
    created() {
        const name = this.$route.query && this.$route.query.v_name || localStorage && localStorage.getItem('name');

        // do not prefill if we have stored document
        if(this.survey && !this.survey.fsDoc){
            !!name && this.$emit("input",{answer: { type: "name", value: name}, valid: this.validateName(name)} );
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
