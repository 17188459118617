<template>
    <div :style="`color: ${styles.backgroundTextColor}`" class="root px-4">
        <v-scroll-y-reverse-transition hide-on-leave>
            <div v-if="isLoading" class="content" style="flex: 0.8;">
                <v-progress-circular
                    :size="82"
                    indeterminate
                    :color="styles.backgroundTextColor"
                ></v-progress-circular>

                <div class="text-sm-body-1 mt-10 loading-message">{{ $t('form.submissionPending') }}</div>
            </div>
        </v-scroll-y-reverse-transition>
        
        <v-scroll-y-reverse-transition hide-on-leave>
            <div v-if="isComplete" class="content mx-4">
                <div class="text-h4 thank-you-message" v-if="survey.goodbye_message">
                    {{goodbyeMessage}}
                </div>
                <div
                    class="finish-subtitle text-center mb-16 pt-5"
                    v-html="subtitle"
                >
                </div>
                <v-icon :size="56" :color="styles.backgroundTextColor">
                    mdi-check
                </v-icon>
                <div v-if="instructionsMessage" class="text-sm-body-1 mt-10 mb-2 mx-4 loading-message">{{ instructionsMessage }}</div>
                
            </div>
        </v-scroll-y-reverse-transition>
        <div v-if="!hideBranding" class="powered-by-container">
            <powered-by :large="isComplete"></powered-by>
        </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
import PoweredBy from './PoweredBy.vue'
import Handlebars from "handlebars";
export default {
  components: { PoweredBy },
    name: 'SurveyLoadingState',
    data() {
        return {toggle: true}
    },
    computed: {
        ...mapGetters({
            styles: 'form/styles',
            survey: 'form/survey',
            submissionStatus: 'form/submissionStatus',
            passingVariables: 'form/passingVariables',
        }),
        isLoading(){
            return this.submissionStatus === 'loading'
        },
        isComplete(){
            return this.submissionStatus === 'complete'
        },
        hideBranding(){
            return this.survey && this.survey.settings && this.survey.settings.hide_branding
        },
        instructionsMessage(){
            if(this.survey.settings?.goodbye_instructions_message === null){
                return ''
            }
            return this.survey.settings?.goodbye_instructions_message === undefined? this.$t('form.submissionCanCloseWindow') : this.survey.settings?.goodbye_instructions_message
        },
        goodbyeMessage(){
            if(this.survey && this.survey.goodbye_message){
                try {
                    var compiled = Handlebars.compile(this.survey.goodbye_message);
                    return compiled(this.passingVariables);
                } catch (error) {
                    console.log(error);
                    return this.survey.goodbye_message
                }
            }
            return ''
        },
        subtitle(){
            if(this.survey && this.survey.goodbye_subtitle){
                try {
                    var compiled = Handlebars.compile(this.survey.goodbye_subtitle);
                    return compiled(this.passingVariables);
                } catch (error) {
                    console.log(error);
                    return this.survey.goodbye_subtitle
                }
            }
            return ''
        }

    }
}
</script>

<style lang="scss" scoped>
    .root{
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        word-break: break-word;
    }
    .content{
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1
    }
    .loading-message{
        max-width: 400px;
        text-align: center;
    }
    .powered-by-container{
        display: flex;
        flex-grow: 0;
        justify-content: center;
    }
    .thank-you-message{
        max-width: 400px;
        text-align: center;
    }
    .finish-subtitle {
        font-size: 20px;
        max-width: 400px;
        line-height: 31px;
    }
</style>
