<template>
    <transition name="message">
        <div class="mt-5 d-flex">
            <v-avatar class="mr-4" :color="styles.dark ? '#ffffffe6' : '#000000e6'" size="44">
                <v-icon :color="styles.background">mdi-robot</v-icon>
            </v-avatar>
            <div>
                <typing-text-effect v-if="typingEffect" :loading="message.loading" @typingStarted="finishedTyping = false"
                    :message="messageText" @typingFinished="finishedTyping = true"></typing-text-effect>
                <div v-else>
                    <p>{{ messageText }}</p>
                </div>
                <v-fade-transition>
                    <voice-response
                        v-if="(finishedTyping || !typingEffect) && !message.loading && message.followup && !message.sufficient"
                        :activeStep="activeStep" v-model="answer" :contrastTextColor="styles.backgroundTextColor"
                        :dark="styles.dark" followup></voice-response>
                </v-fade-transition>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapGetters } from 'vuex';

import TypingTextEffect from '../TypingTextEffect.vue';
import VoiceResponse from './VoiceResponse.vue';
import messages from './locals/FollowUpMessages';


export default {
    name: 'FollowUpMessage',
    props: ['message', 'nextMessage', 'activeStep', 'messageLook', 'typingEffect'],
    components: { TypingTextEffect, VoiceResponse },
    i18n: {
        messages
    },
    computed: {
        ...mapGetters({
            styles: 'form/styles',
            singleQuestionWidget: 'form/singleQuestionWidget',
        }),
        messageText() {
            if (this.message.loading) {
                return this.$t('phrases')[Math.floor(Math.random() * this.$t('phrases').length)];
            }
            if (this.message.sufficient) {
                if (this.singleQuestionWidget) {
                    return this.$t('submitAnswerPhrases')[Math.floor(Math.random() * this.$t('submitAnswerPhrases').length)];
                }

                let finishButton = document.getElementById('finish-button');
                if (finishButton) {
                    return this.$t('finishButtonPhrases')[Math.floor(Math.random() * this.$t('finishButtonPhrases').length)];
                }

                return this.$t('nextButtonPhrases')[Math.floor(Math.random() * this.$t('nextButtonPhrases').length)];
            }
            if (this.message.followup) {
                return this.message.followup;
            }

            return this.$t('fallbackMessage');
        },
        answer: {
            get() { return this.answerValue },
            set(answerValue) {
                if (!answerValue) {
                    this.answerValue = null;
                    return;
                }
                this.answerValue = answerValue.answer || answerValue
            },
        },
    },
    data() {
        return {
            answerValue: this.nextMessage?.answer || null,
            disableTextResponse: false,
            finishedTyping: false
        };
    },
    methods: {
        toggleDisableTextResponse: function () {
            this.disableTextResponse = !this.disableTextResponse;
        }
    }
}
</script>
