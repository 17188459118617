<template>
    <v-container class="d-flex flex-column align-center flex-grow-1">
        <div class="d-flex flex-column align-center flex-grow-1 mt-12">
            <div class="text-h5 mb-10">{{ $t('form.emptyFormHeader') }}</div>
            <v-icon :size="65" color="primary">mdi-file-question-outline</v-icon>
            <div class="mt-10 text-body-1 center-text" style="max-width: 500px;">{{ $t('form.emptyFormMessage') }}</div>
        </div>
        <div class="d-flex flex-grow-0 mb-12 justify-center center-text">
            <powered-by large />
        </div>
    </v-container>
</template>
<script>
import PoweredBy from './PoweredBy.vue'
export default {
  components: { PoweredBy },
    
}
</script>

<style lang="scss" scoped>
    .container{
        height: 100%
    }
    .center-text{
        text-align: center;
    }
</style>