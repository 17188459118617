<template>
    <Dashboard v-if="localeLoaded" :uppy="uppy" :props="{
        proudlyDisplayPoweredByUppy: false,
        width: '100%',
        doneButtonHandler: null,
        singleFileFullScreen: false,
        showRemoveButtonAfterComplete: true,
        theme: dark? 'dark' : 'light',
        locale: dashboardLocale,
        disableLocalFiles
    }" :plugins="plugins" />
</template>

<script>
import { Dashboard } from '@uppy/vue';
import Uppy from '@uppy/core';
import Webcam from '@uppy/webcam';
import AwsS3 from '@uppy/aws-s3';
import ScreenCapture from '@uppy/screen-capture';
import English from '@uppy/locales/lib/en_US';


// Don't forget the CSS: core and UI components + plugins you are using
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/webcam/dist/style.css';
import '@uppy/screen-capture/dist/style.min.css';

import {isEqual} from 'lodash'


import { mapGetters } from 'vuex';
export default {
    name: "FileUpload",
    props: {
        value: {
            required: true,
        },
        activeStep: {
            required: true,
        },
        survey: {
            required: true,
        },
        dark: {
            required: true,
        },
    },
    data() {
        return {
            localeLoaded: false,
            uppy: null,  // Initially null,
            dashboardLocale: null,
        };
    },
    components: {
        Dashboard
    },
    methods: {
        async loadUppyLocale() {
            const localeCode = this.getUppyLocale(this.$i18n.locale);
            if (localeCode !== 'en_US') {
                const locale = await import(`@uppy/locales/lib/${localeCode}`);
                return locale.default;
            }
            return English;
        },
        getUppyLocale(lang) {
            const uppyLocales = {
                en: 'en_US',
                es: 'es_MX', fr: 'fr_FR', zh: 'zh_CN', ar: 'ar_SA',
                vi: 'vi_VN', pt: 'pt_BR', de: 'de_DE', ko: 'ko_KR', ru: 'ru_RU', hi: 'hi_IN',
                it: 'it_IT', fa: 'fa_IR', pl: 'pl_PL', el: 'el_GR', ja: 'ja_JP', uk: 'uk_UA',
                he: 'he_IL', tr: 'tr_TR', nl: 'nl_NL', hr: 'hr_HR', ro: 'ro_RO', sr: 'sr_RS_Latin',
                cs: 'cs_CZ', sv: 'sv_SE', no: 'nb_NO', da: 'da_DK', hu: 'hu_HU', sk: 'sk_SK',
                sl: 'sl_SI', fi: 'fi_FI', is: 'is_IS', th: 'th_TH',
            };
            return uppyLocales[lang] || 'en_US';  // Default to English if not supported
        },
        getDashboardLocale(locale = {strings: {}}){
            if(this.filesTypes === 'videos'){
                let importFiles = this.$t('form.fileUploadVideoImportFilesDefault')

                if(this.disableLocalFiles){
                    importFiles = this.$t('form.fileUploadVideoImportFilesLocalFilesDisabled')

                    if(this.activeStep?.properties.camera === false){
                        importFiles = this.$t('form.fileUploadVideoImportFilesNoCamera')
                    }

                    if(this.activeStep?.properties.screencast === false){
                        importFiles = this.$t('form.fileUploadVideoImportFilesNoScreenCast')
                    }

                    if(this.activeStep?.properties.camera === false && this.activeStep?.properties.screencast === false){
                        importFiles = this.$t('form.fileUploadVideoImportFilesNoCameraAndScreenCast')
                    }
                }
                return {
                    strings: {
                        ...locale.strings,
                        importFiles,
                        dropPasteImportFiles: 'Record or upload a video here:',
                        myDevice: 'Upload video',
                    }
                }
            }
            return locale
        },
        async initUppy(){
            const locale = await this.loadUppyLocale();

            this.dashboardLocale = this.getDashboardLocale(locale)

            this.localeLoaded = true;

            const webcamConfig = this.filesTypes === 'videos' ? {
                mirror: true,
                facingMode: 'user',
                modes: ['video-audio'],
                showRecordingLength: true,
                locale: {
                    strings: {
                        ...locale.strings,
                        pluginNameCamera: this.$t('form.fileUploadPluginNameCameraVideo'),
                    }
                },
            }: {
                locale: {
                    strings: {
                        ...locale.strings,
                        pluginNameCamera: this.$t('form.fileUploadPluginNameCamera'),
                    }
                },
            };

            const restrictions = {
                maxTotalFileSize: 209715200,
                allowedFileTypes: this.activeStep.properties.file_types_patterns || undefined,
            }

            if(this.activeStep.properties.max_number_of_files && this.activeStep.properties.max_number_of_files > 0){
                restrictions.maxNumberOfFiles = this.activeStep.properties.max_number_of_files
            }

            if(this.activeStep.properties.min_number_of_files && this.activeStep.properties.min_number_of_files > 0){
                restrictions.minNumberOfFiles = this.activeStep.properties.min_number_of_files
            }

            this.uppy = new Uppy({
                // debug: false,
                // logger: {
                //     debug: console.log,
                //     info: console.log,
                //     warn: console.log,
                //     error: console.log
                // },
                meta: {
                    formId: this.survey.id
                },
                restrictions,
                locale,
            })
            .use(Webcam, webcamConfig)
            .use(ScreenCapture, {
                title: this.$t('form.fileUploadPluginScreenCast'),
            })
            .use(AwsS3, {
                    // shouldUseMultipart: (file) => file.size > 100 * 2 ** 20,
                    companionUrl: process.env.VUE_APP_COMPANION_URL || 'https://file-uploader.voiceform.com',
                    allowedMetaFields: ['formId'],
            })
            .on('state-update', (prev, state) => {
                if(isEqual(prev.files, state.files)){
                    return;
                }
                
                let uploadedAllFiles = true;
                const files = Object.values(state.files).reduce((previousValue, currentValue)=> {
                    if(currentValue && currentValue.response && currentValue.response.body && currentValue.response.body.key){
                        previousValue.push({
                            url: `https://cdn.voiceform.com/${currentValue.response.body.key}`,
                            name: currentValue.meta.name,
                            type: currentValue.meta.type,
                            size: currentValue.size,
                        })
                    }else{
                        uploadedAllFiles = false;
                    }
                    return previousValue;
                }, []);

                if(files && files.length > 0){
                    return this.$emit("input", {
                            answer: { type: "files", value: files },
                            valid: uploadedAllFiles || 'Please upload all files or remove the ones you don\'t want to upload.',
                            fileUploaderStore: JSON.stringify(state.files)
                    });
                }else{
                    this.$emit("input", null);
                }
                // TODO: we need to store file upload state to restore in case of page reload

            });
        }
    },
    computed: {
        ...mapGetters({
            answers: 'form/answers'
        }),
        disableLocalFiles(){
            return  this.activeStep?.properties?.local_files === false
        },
        filesTypes(){
            return this.activeStep?.properties?.file_types || null 
        },
        plugins() {
            let plugins = []
            if(this.activeStep.properties.camera!==false){
                plugins.push('Webcam')
            }
            if(this.activeStep.properties.screencast !== false){
                plugins.push('ScreenCapture')
            }
            return plugins
        },
        answer: {
            get: function () {
                return this.value ? this.value.value : "";
            },
            set: function (val) {
                if(val && val.length > 0){
                    this.$emit("input", {
                        answer: { type: "files", value: val },
                        valid: true
                    });
                }else{
                    this.$emit("input", null);
                }
            },
        },
    },
    async mounted() {
        try {
            await this.initUppy()
            if (!this.answers || !this.activeStep) return;

            const currentStepAnswers = this.answers[this.activeStep.id];
            if (!currentStepAnswers || !currentStepAnswers.fileUploaderStore) return;

            const fileStore = typeof currentStepAnswers.fileUploaderStore === 'string' ? JSON.parse(currentStepAnswers.fileUploaderStore) : currentStepAnswers.fileUploaderStore;

            const newObject = Object.entries(fileStore).reduce((acc, [key, file]) => {
                acc[key] = { ...file, preview: file?.meta?.key? `https://cdn.voiceform.com/${file.meta.key}`:null };
                return acc;
            }, {});

            this.uppy.setState({ files: newObject });
        } catch (error) {
            console.error("Error in file upload restore", error);
        }

    }
};
</script>

<style lang="scss" scoped>
</style>
