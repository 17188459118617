<template>
    <div class="d-flex">
        <v-avatar size="44" class="mr-4" :color="styles.dark?'#ffffff20':'#00000020'">
            <v-icon>mdi-account</v-icon>
        </v-avatar>
        <v-fade-transition leave-absolute>
            <div v-if="answer">
                <audio-player-controls  hideRemoveRecordingButton :link="answer" />
            </div>
            <v-card v-else elevation="0" :color="styles.dark?'#ffffff20':'#00000020'" class="pa-3">
                {{ textInput }}
            </v-card>
        </v-fade-transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AudioPlayerControls from '../audio-recorder/AudioPlayerControls.vue';


export default {
  components: { AudioPlayerControls },
    name: 'UserMessage',
    props: ['message', 'activeStep', 'messageLook', 'value'],
    computed: {
        ...mapGetters({
            styles: 'form/styles',
        }),
        answer(){
            if (this.value && this.value.type === 'audio') {
                return this.value.url || this.value;
            }
            return null
        },
        textInput(){
            return this.value && this.value.type === 'text' ? this.value.value : "";
        },
    }
};
</script>