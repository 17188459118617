import { render, staticRenderFns } from "./AudioRecorder.vue?vue&type=template&id=18f6185e&scoped=true&"
import script from "./AudioRecorder.vue?vue&type=script&lang=js&"
export * from "./AudioRecorder.vue?vue&type=script&lang=js&"
import style0 from "./AudioRecorder.vue?vue&type=style&index=0&id=18f6185e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18f6185e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VScaleTransition } from 'vuetify/lib/components/transitions';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VAvatar,VBtn,VExpandTransition,VFadeTransition,VIcon,VProgressCircular,VScaleTransition,VSnackbar})
