<template>
    <div class="finish">
        <div
            :style="{
                color: survey.style ? survey.style.color : survey.font_color,
            }"
            class="finish-text"
        >
            {{ survey.goodbye_message }}
        </div>
    </div>
</template>

<script>
export default {
    name: "FinishStep",
    props: ["survey"],
};
</script>

<style lang="scss" scoped>
.finish {
    &-text {
        padding-top: 42px;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
    }
}
</style>
