
export const initSurvey = {
    methods: {

        initSteps(survey) {

            let steps = []

            steps.push({ component: 'WelcomeStep' });

            if (survey.include_consent) {
                steps.push({ component: 'ConsentStep' });
            }

            const keyTitles = {
                first_name: 'First name',
                last_name: 'Last name',
                email: 'Email',
                phone_number: 'Phone Number',
                age: 'Age',
                gender: 'Gender',
                household_income: 'Household Income',
                location: 'Location',
            };
            let informationToCollectKeys = Object.keys(keyTitles);


            if (survey.settings['information_to_collect_position'] !== 'end') {
                for (let i = 0; i < informationToCollectKeys.length; i++) {
                    const key = informationToCollectKeys[i];
                    if (survey.information_to_collect && survey.information_to_collect[key]) {
                        steps.push({
                            key: key,
                            text: keyTitles[key],
                            required: survey.information_to_collect[key] == 'required',
                            component: 'InformationStep'
                        });
                    }
                }
            }

            let questions = this.survey.questions.sort((a, b) => a.order - b.order)


            for (let i = 0; i < questions.length; i++) {

                if (!questions[i].type) {
                    questions[i].type = 'voice-response'
                }
                steps.push({
                    order: questions[i].order,
                    id: questions[i].id,
                    text: questions[i].text,
                    audio_url: questions[i].audio_url,
                    style: questions[i].style,
                    image: questions[i].image_url,
                    is_optional: questions[i].is_optional,
                    type: questions[i].type == 'plain' ? 'voice-response' : questions[i].type,
                    multipleChoiceItems: questions[i].multiple_choice_items,
                    media_attachments: questions[i].media_attachments || [],
                    enable_text_input:  !!questions[i].enable_text_input,
                    description: questions[i].description || '',
                    video_source: questions[i].video_source || '',
                    component: 'QuestionStep'
                });

            }

            if (survey.settings['information_to_collect_position'] === 'end') {
                for (let i = 0; i < informationToCollectKeys.length; i++) {
                    const key = informationToCollectKeys[i];
                    if (survey.information_to_collect[key]) {
                        steps.push({
                            key: key,
                            text: keyTitles[key],
                            is_optional: !survey.information_to_collect[key] === 'required',
                            component: 'InformationStep'
                        });

                    }
                }
            }

            steps.push({ component: 'FinishStep' });

            return steps
        }

    }
}
