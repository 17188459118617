<template>
    <v-text-field
        :rules="[rules.required, rules.zipFormat]"
        validate-on-blur
        outlined
        :dark="dark"
        :color="survey.style.color"
        v-model="answer"
        @change="$emit('change')"
        hide-details="auto"
        :loading="loading"
        clearable
        placeholder="ZIP Code"
    />
</template>

<script>
import { mapGetters } from 'vuex';
const USZipCodePattern = new RegExp("^\\d{5}(-\\d{4})?$");
export default {
    name: "ZipCode",
    props: {
        value: {
            required: true,
        },
        activeStep: {
            required: true,
        },
        survey: {
            required: true,
        },
        dark: {
            required: true,
        },
    },
    data() {
        return {
            rules: {
                required: (value) =>
                    !!this.activeStep.is_optional || !!value || "Required.",
                zipFormat: (value) => !value || USZipCodePattern.test(value) || 'Invalid US zip code format.'
            },
        };
    },
    computed: {
        ...mapGetters({
            validatingAnswer: 'form/validatingAnswer'
        }),
        loading(){
            return this.activeStep?.id && this.activeStep.id === this.validatingAnswer
        },
        answer: {
            get: function () {
                return this.value ? this.value.value : "";
            },
            set: function (val) {
                if(val && val.trim()){
                    this.$emit("input", {
                        answer: { type: "zip-code", value: val },
                        valid: USZipCodePattern.test(val) || 'Invalid US zip code format.'
                    });
                }else{
                    this.$emit("input", null);
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
