<template>
    <v-textarea
        :rules="[rules.required]"
        validate-on-blur
        outlined
        auto-grow
        row="1"
        :dark="dark"
        dense
        :color="survey.style.color"
        v-model="answer"
        @change="$emit('change')"
    />
</template>

<script>
export default {
    name: "CustomField",
    props: {
        value: {
            required: true,
        },
        activeStep: {
            required: true,
        },
        survey: {
            required: true,
        },
        dark: {
            required: true,
        },
    },
    components: {},
    data() {
        return {
            rules: {
                required: (value) =>
                    !!this.activeStep.is_optional || !!value || "Required.",
            },
        };
    },
    computed: {
        answer: {
            get: function () {
                return this.value ? this.value.value : "";
            },
            set: function (val) {
                if(val && val.trim()){
                    this.$emit("input", { type: "text", value: val });
                }else{
                    this.$emit("input", null);
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
