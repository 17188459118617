export default [
    {"name": "English (en)", "code": "en"},
    {"name": "Spanish (es)", "code": "es"},
    {"name": "French (fr)", "code": "fr"},
    {"name": "Chinese (zh)", "code": "zh"},
    {"name": "Tagalog (tl)", "code": "tl"},
    {"name": "Arabic (ar)", "code": "ar"},
    {"name": "Vietnamese (vi)", "code": "vi"},
    {"name": "Portuguese (pt)", "code": "pt"},
    {"name": "German (de)", "code": "de"},
    {"name": "Korean (ko)", "code": "ko"},
    {"name": "Russian (ru)", "code": "ru"},
    {"name": "Hindi (hi)", "code": "hi"},
    {"name": "Italian (it)", "code": "it"},
    {"name": "Urdu (ur)", "code": "ur"},
    {"name": "Persian (fa)", "code": "fa"},
    {"name": "Polish (pl)", "code": "pl"},
    {"name": "Greek (el)", "code": "el"},
    {"name": "Japanese (ja)", "code": "ja"},
    {"name": "Punjabi (pa)", "code": "pa"},
    {"name": "Ukrainian (uk)", "code": "uk"},
    {"name": "Gujarati (gu)", "code": "gu"},
    {"name": "Hebrew (he)", "code": "he"},
    {"name": "Bengali (bn)", "code": "bn"},
    {"name": "Tamil (ta)", "code": "ta"},
    {"name": "Turkish (tr)", "code": "tr"},
    {"name": "Swahili (sw)", "code": "sw"},
    {"name": "Dutch (nl)", "code": "nl"},
    {"name": "Bosnian (bs)", "code": "bs"},
    {"name": "Croatian (hr)", "code": "hr"},
    {"name": "Armenian (hy)", "code": "hy"},
    {"name": "Romanian (ro)", "code": "ro"},
    {"name": "Serbian (sr)", "code": "sr"},
    {"name": "Czech (cs)", "code": "cs"},
    {"name": "Swedish (sv)", "code": "sv"},
    {"name": "Norwegian (no)", "code": "no"},
    {"name": "Danish (da)", "code": "da"},
    {"name": "Hungarian (hu)", "code": "hu"},
    {"name": "Slovak (sk)", "code": "sk"},
    {"name": "Slovenian (sl)", "code": "sl"},
    {"name": "Macedonian (mk)", "code": "mk"},
    {"name": "Latvian (lv)", "code": "lv"},
    {"name": "Lithuanian (lt)", "code": "lt"},
    {"name": "Estonian (et)", "code": "et"},
    {"name": "Afrikaans (af)", "code": "af"},
    {"name": "Galician (gl)", "code": "gl"},
    {"name": "Welsh (cy)", "code": "cy"},
    {"name": "Icelandic (is)", "code": "is"},
    {"name": "Finnish (fi)", "code": "fi"},
    {"name": "Maori (mi)", "code": "mi"},
    {"name": "Kannada (kn)", "code": "kn"},
    {"name": "Nepali (ne)", "code": "ne"},
    {"name": "Kazakh (kk)", "code": "kk"},
    {"name": "Marathi (mr)", "code": "mr"},
    {"name": "Azerbaijani (az)", "code": "az"},
    {"name": "Malay (ms)", "code": "ms"},
    {"name": "Thai (th)", "code": "th"}
]

// languages map code to name
export const languagesMap = {
    "en": "English",
    "es": "Spanish",
    "fr": "French",
    "zh": "Chinese",
    "tl": "Tagalog",
    "ar": "Arabic",
    "vi": "Vietnamese",
    "pt": "Portuguese",
    "de": "German",
    "ko": "Korean",
    "ru": "Russian",
    "hi": "Hindi",
    "it": "Italian",
    "ur": "Urdu",
    "fa": "Persian",
    "pl": "Polish",
    "el": "Greek",
    "ja": "Japanese",
    "pa": "Punjabi",
    "uk": "Ukrainian",
    "gu": "Gujarati",
    "he": "Hebrew",
    "bn": "Bengali",
    "ta": "Tamil",
    "tr": "Turkish",
    "sw": "Swahili",
    "nl": "Dutch",
    "bs": "Bosnian",
    "hr": "Croatian",
    "hy": "Armenian",
    "ro": "Romanian",
    "sr": "Serbian",
    "cs": "Czech",
    "sv": "Swedish",
    "no": "Norwegian",
    "da": "Danish",
    "hu": "Hungarian",
    "sk": "Slovak",
    "sl": "Slovenian",
    "mk": "Macedonian",
    "lv": "Latvian",
    "lt": "Lithuanian",
    "et": "Estonian",
    "af": "Afrikaans",
    "gl": "Galician",
    "cy": "Welsh",
    "is": "Icelandic",
    "fi": "Finnish",
    "mi": "Maori",
    "kn": "Kannada",
    "ne": "Nepali",
    "kk": "Kazakh",
    "mr": "Marathi",
    "az": "Azerbaijani",
    "ms": "Malay",
    "th": "Thai"
}

export const languagesNativeMap = {
    "en": "English",
    "es": "Español",
    "fr": "Français",
    "zh": "中文",
    "tl": "Tagalog",
    "ar": "العربية",
    "vi": "Tiếng Việt",
    "pt": "Português",
    "de": "Deutsch",
    "ko": "한국어",
    "ru": "Русский",
    "hi": "हिन्दी",
    "it": "Italiano",
    "ur": "اُردُو",
    "fa": "فارسی",
    "pl": "Polski",
    "el": "Ελληνικά",
    "ja": "日本語",
    "pa": "ਪੰਜਾਬੀ",
    "uk": "Українська",
    "gu": "ગુજરાતી",
    "he": "עברית",
    "bn": "বাংলা",
    "ta": "தமிழ்",
    "tr": "Türkçe",
    "sw": "Kiswahili",
    "nl": "Nederlands",
    "bs": "Bosanski",
    "hr": "Hrvatski",
    "hy": "Հայերեն",
    "ro": "Română",
    "sr": "Српски",
    "cs": "Čeština",
    "sv": "Svenska",
    "no": "Norsk",
    "da": "Dansk",
    "hu": "Magyar",
    "sk": "Slovenčina",
    "sl": "Slovenščina",
    "mk": "Македонски",
    "lv": "Latviešu",
    "lt": "Lietuvių",
    "et": "Eesti",
    "af": "Afrikaans",
    "gl": "Galego",
    "cy": "Cymraeg",
    "is": "Íslenska",
    "fi": "Suomi",
    "mi": "Māori",
    "kn": "ಕನ್ನಡ",
    "ne": "नेपाली",
    "kk": "Қазақ",
    "mr": "मराठी",
    "az": "Azərbaycan",
    "ms": "Bahasa Melayu",
    "th": "ไทย"
};