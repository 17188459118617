<template>
    <div class="player" :style="{width: $vuetify.breakpoint.xs? '200px': '300px'}">
        <v-progress-circular v-if="loading" :size="30" :width="2" :color="styles.button.background" indeterminate></v-progress-circular>
        <v-btn v-else icon :dark="styles.dark" @click="togglePlay" :disabled="!audioLoaded">
            <v-icon>{{ isPlaying?'mdi-pause': 'mdi-play' }}</v-icon>
        </v-btn>
        <v-slider dense :dark="styles.dark" :color="styles.button.background" :track-color="styles.button.background"
            hide-details :step="0.01" class="player-progress" :value="playbackTime" :max="100"
            @change="handleSliderChange" :disabled="!audioLoaded || loading">
        </v-slider>
        <v-btn v-if="!hideRemoveRecordingButton && !loading" icon :dark="styles.dark" @click="$emit('cleanAudio')">
            <v-icon>mdi-delete</v-icon>
        </v-btn>
    </div>
    <!-- TODO: implement waves look -->
    <!-- <canvas class="canvas"></canvas> -->
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: "AudioPlayerControls",
    props: {
        link: {
            type: String
        },
        hideRemoveRecordingButton: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            playbackTime: 0,
            audioDuration: 0,
            audioLoaded: true,
            isPlaying: false,
            audio: null
        };
    },
    watch: {
        link(val) {
            this.audio = new Audio(val);
        },
        activeStepNumber() {
            this.isPlaying && this.togglePlay();
        },
    },
    methods: {

        // Set the range slider max value equal to audio duration
        initSlider() {
            if (this.audio) {
                this.audioDuration = Number(this.audio.duration.toFixed(2));
                this.$emit('duration', this.audioDuration)
            }
        },

        // Playback listener function runs every 100ms while audio is playing
        playbackListener() {
            //Sync local 'playbackTime' var to audio.currentTime and update global state
            this.playbackTime = Math.round(this.audio.currentTime / this.audio.duration * 100);

            //Add listeners for audio pause and audio end events
            this.audio.addEventListener("ended", this.endListener);
            this.audio.addEventListener("pause", this.pauseListener);
        },

        // Function to run when audio is paused by user
        pauseListener() {
            this.isPlaying = false;
            this.listenerActive = false;
            this.cleanupListeners();
        },

        // Function to run when audio play reaches the end of file
        endListener() {
            this.isPlaying = false;
            this.listenerActive = false;
            this.cleanupListeners();
        },

        // Remove listeners after audio play stops
        cleanupListeners() {
            this.audio.removeEventListener("timeupdate", this.playbackListener);
            this.audio.removeEventListener("ended", this.endListener);
            this.audio.removeEventListener("pause", this.pauseListener);
        },

        togglePlay() {
            if (this.audio) {
                if (this.audio.paused) {
                    this.audio.play()
                    this.isPlaying = true
                } else {
                    this.audio.pause()
                    this.isPlaying = false
                }
            }
        },
        handleSliderChange(value) {
            if (this.audio) {
                const time = value * this.audio.duration / 100
                this.audio.currentTime = time
            }

        },

        async drawAudio() {
            window.AudioContext = window.AudioContext || window.webkitAudioContext;
            const audioContext = new AudioContext();
            const response = await fetch(this.link)
            const arrayBuffer = await response.arrayBuffer()
            const decodedData = await audioContext.decodeAudioData(arrayBuffer)
            this.draw(this.normalizeData(this.filterData(decodedData)))
        },

        filterData(audioBuffer) {
            const rawData = audioBuffer.getChannelData(0); // We only need to work with one channel of data
            const samples = 70; // Number of samples we want to have in our final data set
            const blockSize = Math.floor(rawData.length / samples); // the number of samples in each subdivision
            const filteredData = [];
            for (let i = 0; i < samples; i++) {
                let blockStart = blockSize * i; // the location of the first sample in the block
                let sum = 0;
                for (let j = 0; j < blockSize; j++) {
                    sum = sum + Math.abs(rawData[blockStart + j]); // find the sum of all the samples in the block
                }
                filteredData.push(sum / blockSize); // divide the sum by the block size to get the average
            }
            return filteredData;
        },
        normalizeData(filteredData) {
            const multiplier = Math.pow(Math.max(...filteredData), -1);
            return filteredData.map(n => n * multiplier);
        },
        draw(normalizedData) {
            // set up the canvas
            const canvas = document.querySelector("canvas");
            const dpr = window.devicePixelRatio || 1;
            const padding = 20;
            canvas.width = canvas.offsetWidth * dpr;
            canvas.height = (canvas.offsetHeight + padding * 2) * dpr;
            const ctx = canvas.getContext("2d");
            ctx.scale(dpr, dpr);
            ctx.translate(0, canvas.offsetHeight / 2 + padding); // set Y = 0 to be in the middle of the canvas

            // draw the line segments
            const width = canvas.offsetWidth / normalizedData.length;
            for (let i = 0; i < normalizedData.length; i++) {
                const x = width * i;
                let height = normalizedData[i] * canvas.offsetHeight - padding;
                if (height < 0) {
                    height = 0;
                } else if (height > canvas.offsetHeight / 2) {
                    height = height > canvas.offsetHeight / 2;
                }
                this.drawLineSegment(ctx, x, height, width, (i + 1) % 2);
            }
        },

        drawLineSegment(ctx, x, height, width, isEven) {
            ctx.lineWidth = 1; // how thick the line is
            ctx.strokeStyle = "#fff"; // what color our line is
            ctx.beginPath();
            height = isEven ? height : -height;
            ctx.moveTo(x, 0);
            ctx.lineTo(x, height);
            ctx.arc(x + width / 2, height, width / 2, Math.PI, 0, isEven);
            ctx.lineTo(x + width, 0);
            ctx.stroke();
        }

    },
    computed: {
        ...mapGetters({
            styles: 'form/styles',
            activeStepNumber: 'form/activeStepNumber',
        })
    },
    mounted: function () {

        this.audio = new Audio(this.link);

        // nextTick code will run only after the entire view has been rendered
        this.$nextTick(function () {

            // Wait for audio to load, then run initSlider() to get audio duration and set the max value of our slider 
            // "loademetadata" Event https://www.w3schools.com/tags/av_event_loadedmetadata.asp
            this.audio.addEventListener("loadedmetadata", function () {
                this.initSlider();
            }.bind(this));

            // "canplay" HTML Event lets us know audio is ready for play https://www.w3schools.com/tags/av_event_canplay.asp
            this.audio.addEventListener("canplay", function () {
                this.audioLoaded = true
            }.bind(this));

            // Wait for audio to begin play, then start playback listener function
            this.$watch("isPlaying", function () {
                if (this.isPlaying) {
                    this.initSlider();
                    //prevent starting multiple listeners at the same time
                    if (!this.listenerActive) {
                        this.listenerActive = true;
                        //for a more consistent timeupdate, include freqtimeupdate.js and replace both instances of 'timeupdate' with 'freqtimeupdate'
                        this.audio.addEventListener("timeupdate", this.playbackListener);
                    }
                }
            });

            //Update current audio position when user drags progress slider
            this.$watch("playbackTime", function () {
                var diff = Math.abs(this.playbackTime - this.audio.currentTime);

                //Throttle synchronization to prevent infinite loop between playback listener and this watcher
                if (diff > 0.01 && this.$refs.player) {
                    this.$refs.player.currentTime = this.playbackTime;
                }
            });
        });
    },
    beforeDestroy() {
        this.isPlaying && this.togglePlay();
    },
}
</script>
<style lang="scss" scoped>
.player {
    width: 300px;
    display: flex;
    padding: 4px 8px;
    align-items: center;
    background: rgba($color: #000000, $alpha: 0.1);
    border-radius: 4px;

    &-progress {
        margin-left: 8px;
        margin-right: 8px;
        transition: unset;
    }


}

.player::v-deep .v-slider__thumb-container {
    transition: none;
}

.canvas {
    width: 800px;
    height: 130px;
    margin: 2rem auto;
    background-color: royalblue;
}
</style>