<template>
    <div>
        <p>
            {{ typingText }}
            <span v-if="showCursor && !typingFinished" class="cursor">|</span>
            <span v-if="loading && typingFinished" class="dots">
                <span>.</span>
                <span>.</span>
                <span>.</span>
            </span>
        </p>
    </div>
</template>
  
<script>
export default {
    props: ['message', 'loading'],
    data() {
        return {
            typingText: '',
            showCursor: true,
            typingFinished: false,
            typingTimer: null,
            currentIndex: 0,
        };
    },
    watch: {
        message: {
            immediate: true,
            handler(newValue) {
                // Clear existing timer
                if (this.typingTimer) {
                    clearInterval(this.typingTimer);
                    this.typingTimer = null;
                    this.typingText = '';
                    this.currentIndex = 0;
                }

                // Start typing the new message
                this.startTyping(newValue);
            }
        },
    },
    created() {
        this.startBlinkingCursor();
    },
    methods: {
        startTyping: function (message) {
            this.typingFinished = false;
            this.$emit('typingStarted');

            this.typingTimer = setInterval(() => {
                if (this.currentIndex < message.length) {
                    this.typingText += message[this.currentIndex];
                    this.currentIndex++;
                } else {
                    this.typingFinished = true;
                    this.$emit('typingFinished');
                    clearInterval(this.typingTimer);
                }
            }, 50);
        },
        startBlinkingCursor: function () {
            setInterval(() => {
                this.showCursor = !this.showCursor;
            }, 500); // blink every 500ms
        },
    },
};
</script>
  
<style lang="scss" scoped>
.cursor {
    animation: blink 0.5s infinite;
    font-weight: bold;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.dots span {
    display: inline-block;
    animation-name: dot;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

.dots span:nth-child(2) {
    animation-delay: 0.2s;
}

.dots span:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes dot {
    0%,
    20%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(-5px);
    }
}
</style>