<template>
    <div class="information">
        <div
            class="information--text"
            :style="{
                color: survey.style ? survey.style.color : survey.font_color,
            }"
        >
            {{ activeStep.text }}
        </div>
        <div
            class="information--error"
            v-if="error && error.text"
            :style="{ color: contrastTextColor }"
        >
            {{ error.text }}
        </div>
        <div v-if="activeStep.key ==='phone_number'" class="phone-input-container">
            <VuePhoneNumberInput 
                :dark="dark" 
                :preferred-countries="['US', 'CA']" 
                v-model="phoneNumber" 
                show-code-on-list
                @update="onPhoneInputUpdate"
                @phone-number-blur="handleBlur"
                :color="survey.style.color"
                :dark-color="survey.style.background"
                valid-color="#4CAF50"
                error-color="#F44336"
                :error="phoneError"
                clearable
            />
        </div>

        <v-text-field
            v-if="activeStep.key !=='phone_number'"
            v-model="answer[activeStep.key]"
            :dark="dark"
            :type="activeStep.key === 'age' ? 'number' : 'text'"
            min="1"
            :placeholder="activeStep.text"
            :color="survey.style ? survey.style.color : survey.font_color"
            @change="handleInput"
        ></v-text-field>
    </div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
export default {
    name: "InformationStep",
    components: {VuePhoneNumberInput},
    props: {
        informationData: {
            required: true,
        },
        activeStep: {
            required: true,
        },
        survey: {
            required: true,
        },
        dark: {
            required: true,
        },
        contrastTextColor: {
            required: true,
        },
        error: {
            required: true,
        },
    },
    data() {
        return {
            answer: {
                [this.activeStep.key]:
                    this.informationData[this.activeStep.key],
            },
            phoneNumber: this.informationData[this.activeStep.key] || '',
            phoneNumberIsValid: true,
            phoneError: false
        };
    },
    watch: {
        "activeStep.key"(key) {
            this.answer = { [key]: this.informationData[key] };
        },
    },
    methods: {
        handleInput() {
            this.$emit("updateInformation", this.answer);
        },
        onPhoneInputUpdate(event){
            this.phoneError=false;
            this.answer[this.activeStep.key]= event.e164
            this.phoneNumberIsValid = event.isValid;
            this.$emit("updateInformation", this.answer);
            this.$emit("isValid", this.phoneNumber? event.isValid : true, false)
        },
        handleBlur(){
            if(!this.phoneNumberIsValid){
                const valid = this.phoneNumber?this.phoneNumberIsValid:true
                this.$emit("isValid", valid, valid?'':'The phone number is invalid')
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.information {
    display: flex;
    flex-direction: column;
    flex: 1;
    &--text {
        font-weight: bold;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        padding-top: 16px;
        padding-bottom: 32px;
    }

    &--error {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        animation: 1s shake;
    }
}
.phone-input-container {
    margin-top: 16px;
}

@keyframes shake {
    0% {
        transform: skewX(-15deg);
    }
    5% {
        transform: skewX(15deg);
    }
    10% {
        transform: skewX(-15deg);
    }
    15% {
        transform: skewX(15deg);
    }
    20% {
        transform: skewX(0deg);
    }
    100% {
        transform: skewX(0deg);
    }
}
</style>
