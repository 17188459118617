<template>
    <v-carousel-item class="carousel-item">
        <v-sheet
            class="slide"
            height="100%"
            color="transparent"
            :style="`color: ${styles.backgroundTextColor};`"
        >
            <v-container class="main-container" >
                <div class="question-layout">
                    <component
                        :step="step"
                        :activeStep="step"
                        ref="step"
                        :is="step.component"
                        :error="error"
                        :informationData="informationData"
                        :survey="survey"
                        :contrastTextColor="styles.backgroundTextColor"
                        :dark="styles.dark"
                    >
                        <template v-slot:[generateStepType(step.type)]>
                            <component
                                v-if="step.type !== 'description-slide'"
                                :is="generateStepType(step.type)"
                                :activeStep="step"
                                v-model="answer"
                                :contrastTextColor="styles.backgroundTextColor"
                                :dark="styles.dark"
                                :survey="survey"
                            />
                        </template>
                    </component>
                </div>
            </v-container>
        </v-sheet>
    </v-carousel-item>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'

    import WelcomeStep from "./components/steps/WelcomeStep";
    import InformationStep from './components/steps/InformationStep';
    import QuestionStep from "./components/steps/QuestionStep";
    import FinishStep from "./components/steps/FinishStep";
    import MultipleChoice from "./components/questions/MultipleChoice.vue";
    import DatePicker from "./components/questions/DatePicker.vue";
    import Checkbox from "./components/questions/Checkbox.vue";
    import Email from "./components/questions/Email.vue";
    import Rating from "./components/questions/Rating.vue";
    import NetPromoterScore from "./components/questions/NetPromoterScore.vue";
    import Name from "./components/questions/Name.vue";
    import ZipCode from './components/questions/ZipCode.vue';
    import Yesno from "./components/questions/Yesno.vue";
    import Dropdown from "./components/questions/Dropdown.vue";
    import CustomField from "./components/questions/CustomField.vue";
    import FileUpload from "./components/questions/FileUpload.vue";
    import VoiceResponse from "./components/questions/VoiceResponse.vue";
    import PhoneNumber from './components/questions/PhoneNumber.vue'
    import NumericInput from './components/questions/NumericInput.vue'
    import Matrix from './components/questions/Matrix.vue';
    import questionTypes from '../../configs/questionTypes';

    export default {
        props: {
            step: {
                required: true
            }
        },
        data() {
            return {
                informationData: {},
                error: {
                    text: null,
                },
            }
        },
        components: {
            FinishStep,
            QuestionStep,
            InformationStep,
            WelcomeStep,
            MultipleChoice,
            DatePicker,
            Checkbox,
            Email,
            Rating,
            NetPromoterScore,
            Name,
            ZipCode,
            Yesno,
            Dropdown,
            CustomField,
            FileUpload,
            VoiceResponse,
            PhoneNumber,
            NumericInput,
            Matrix,
        },
        computed: {
            ...mapGetters({
                styles: 'form/styles',
                survey: 'form/survey',
                steps: 'form/steps',
                answers: 'form/answers',
                activeStepNumber: 'form/activeStepNumber'
            }),
            answer: {
               get: function () {return this.answers && this.answers[this.step.id] && this.answers[this.step.id].answer},
               set: function (answerValue) {this.updateOrCreateAnswer(answerValue)},
            },
        },
        methods: {
            ...mapActions({
                updateOrCreateAnswer: 'form/updateOrCreateAnswer',
                submitAnswers: 'form/submitAnswers'
            }),

            generateStepType(type){
                if(type === questionTypes.VOICE_QUESTION){
                    return questionTypes.VOICE_RESPONSE
                }
                return type
            }
        }
    }
</script>

<style lang="scss" scoped>
.carousel-item::v-deep .v-carousel__item {
    z-index: unset;
}
.slide{
    display: flex;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
}
.slide::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}
.main-container {
  max-width: 960px;
  display: flex;
}
.question-layout {
    display: flex;
    justify-content: center;
    flex: 1;
    min-height: 40vh;
    margin-top: auto;
    margin-bottom: auto;
}

</style>
