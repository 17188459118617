<template>
    <v-fade-transition>
        <v-app-bar v-if="!submissionStatus" :value="!submissionStatus" :dark="styles.dark" :style="footerStyles" height="62"
            absolute elevation="0" :bottom="true">
            <v-container>
                <v-row align="center">
                    <v-spacer class="d-none d-sm-flex"></v-spacer>
                    <powered-by></powered-by>
                    <v-spacer class="d-flex d-sm-none"></v-spacer>
                    <v-btn v-if="!hideArrows" class="navigation-button" :color="styles.button.background"
                        :disabled="disabledUpButton" icon @click="handleUpButtonClick"><v-icon>mdi-arrow-up</v-icon></v-btn>
                    <v-btn v-if="!hideArrows" :color="styles.button.background" :disabled="disabledDownButton"
                        class="order-sm-0 navigation-button" icon
                        @click="handleDownButtonClick"><v-icon>mdi-arrow-down</v-icon></v-btn>
                </v-row>
            </v-container>
        </v-app-bar>
    </v-fade-transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PoweredBy from './PoweredBy.vue'

export default {
    components: { PoweredBy },
    name: 'SurveyFooter',
    computed: {
        ...mapGetters({
            steps: 'form/steps',
            survey: 'form/survey',
            styles: 'form/styles',
            activeStepNumber: 'form/activeStepNumber',
            activeStep: 'form/activeStep',
            questionSteps: 'form/questionSteps',
            submissionStatus: 'form/submissionStatus',
            answers: 'form/answers',
            recording: 'audio/recording',
            uploadingRecording: 'audio/uploadingRecording',
            audioLoading: 'audio/loading',
        }),
        footerStyles() {
            return `color: ${this.styles.backgroundTextColor} ; background-color: ${this.styles.background}; border-top: ${this.styles.backgroundTextColor}33 solid 1px;`
        },
        notSkippedSteps() {
            return this.steps.filter(step => !step.skippedByLogic)
        },
        isFirstStep() {
            return this.activeStep.id === this.notSkippedSteps[0].id
        },
        isLastStep() {
            return !!this.questionSteps.length && (this.activeStep.id === this.questionSteps[this.questionSteps.length - 1].id)
        },
        disabledUpButton() {
            return this.isFirstStep || this.recording || this.uploadingRecording || this.audioLoading
        },
        disabledDownButton() {
            if (this.isLastStep || this.recording || this.uploadingRecording || this.audioLoading) {
                return true
            }
            if (this.activeStep.actions &&
                this.activeStep.component === 'QuestionStep' &&
                !this.activeStep.is_optional
            ) {
                if (!this.answers[this.activeStep.id] || this.answers[this.activeStep.id].valid !== true) {
                    return true
                }
            }
            return false
        },
        hideArrows() {
            return this.survey && this.survey.settings && this.survey.settings.is_nav_arrows_hidden
        }
    },
    methods: {
        ...mapActions({
            showNextSlide: 'form/showNextSlide',
            showPreviousSlide: 'form/showPreviousSlide'
        }),
        handleUpButtonClick() {
            this.showPreviousSlide()
        },
        handleDownButtonClick() {
            this.showNextSlide()
        },

        focusedElementEditable() {
            return document.activeElement.isContentEditable
                || document.activeElement.tagName === 'TEXTAREA'
                || document.activeElement.tagName === 'INPUT';
        },

        handleKeypress(e) {
            // we need to make sure not to scroll between questions if navigating inside of any editable fields
            if (!this.focusedElementEditable() && e && e.code) {
                switch (e.code) {
                    case 'ArrowUp':
                        return !this.hideArrows && this.activeStepNumber !== 0 && this.showPreviousSlide()
                    case 'ArrowDown':
                        return !this.hideArrows && !this.disabledDownButton && this.showNextSlide()
                    default:
                        break;
                }
            }
        }
    },
    created() {
        window.addEventListener('keydown', this.handleKeypress);
    },
    destroyed() {
        window.removeEventListener('keydown', this.handleKeypress);
    },
}
</script>

<style lang="scss" scoped>
.powered-by {
    cursor: pointer;
    text-decoration: none;
}

.navigation-button {
    touch-action: manipulation;
}</style>